// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown{
    position: relative;
    top: -16px;
    right: 7px;
}
.close{
    position: relative;
    top: -15px;
    right: -19px;
}

.filtericon{
    right: -203px;
    position: relative;
  
  }
`, "",{"version":3,"sources":["webpack://./src/app/all-modules/company/reports-key-sorting/reports-key-sorting.component.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,UAAU;IACV,UAAU;AACd;AACA;IACI,kBAAkB;IAClB,UAAU;IACV,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,kBAAkB;;EAEpB","sourcesContent":[".dropdown{\r\n    position: relative;\r\n    top: -16px;\r\n    right: 7px;\r\n}\r\n.close{\r\n    position: relative;\r\n    top: -15px;\r\n    right: -19px;\r\n}\r\n\r\n.filtericon{\r\n    right: -203px;\r\n    position: relative;\r\n  \r\n  }\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
