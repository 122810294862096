// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
  production: false,
  apiUrl: 'https://hroapi.smartiapps.com/',
  fresh_work_api:'https://azatecon6354894596173193.freshdesk.com/',
  firebaseConfig: {
    apiKey: "AIzaSyDazSZ2Wz40EwSNF2LREMm_ZZjJvG8GWF8",
    authDomain: "azatecon-55aa5.firebaseapp.com",
    projectId: "azatecon-55aa5",
    storageBucket: "azatecon-55aa5.firebasestorage.app",
    messagingSenderId: "1039760649201",
    appId: "1:1039760649201:web:9c63c99327eada0664f250",
    measurementId: "G-MZ3KW7B1S9",
    vapidKey:"BDoKOtWDS4jedr8S21Bd_na3pcIlL9g6F3iVJU28SGo_2ZRsZ7xThEgfyKLrImfmr4LrbNLUT3U1rjUa0blpgoE"
  }

};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
