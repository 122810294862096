//   other common import components

import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {AllModulesRoutingModule} from './all-modules-routing.module';
import {AllModulesComponent} from './all-modules.component';
import {HeaderComponent} from '../header/header.component';
import {SidebarComponent} from '../sidebar/sidebar.component';
import { NotificationComponent } from '../header/notification/notification.component';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {documentservice} from './documents.sevice';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {ToastrModule} from 'ngx-toastr';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {CdkScrollableModule} from '@angular/cdk/scrolling';
import {MatTooltipModule} from '@angular/material/tooltip';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { FullCalendarModule } from '@fullcalendar/angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';


// Api Interaction
import {InMemoryWebApiModule} from 'angular-in-memory-web-api'



//  services import
import {HeaderService} from '../header/header.service';
import {AllModulesService} from './all-modules.service';
import {ApiModulesService} from './api-modules.service';
import {Ng2SearchPipeModule} from 'ng2-search-filter';

// Api All Modules Database

import {AllModulesData} from 'src/assets/all-modules-data/all-modules-data';


//  prime ng import modules
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ListboxModule } from 'primeng/listbox';
import {DropdownModule} from 'primeng/dropdown';
import {MultiSelectModule} from 'primeng/multiselect';
import {MenuModule} from 'primeng/menu';
import {TabViewModule} from 'primeng/tabview';
import {InputNumberModule} from 'primeng/inputnumber';
import {SliderModule} from 'primeng/slider';
import {TooltipModule} from 'primeng/tooltip';
import {CalendarModule} from 'primeng/calendar';
import {ChipsModule} from 'primeng/chips';
import {TableModule} from 'primeng/table';
import {TagModule} from 'primeng/tag';
import {TreeTableModule} from 'primeng/treetable';
import {FieldsetModule} from 'primeng/fieldset';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { CarouselModule } from 'primeng/carousel';
import { BadgeModule } from 'primeng/badge';
import { RippleModule } from 'primeng/ripple';
import { DockModule } from 'primeng/dock';
import { PickListModule } from 'primeng/picklist';
import { InputSwitchModule } from 'primeng/inputswitch';
import { OrderListModule } from 'primeng/orderlist';
import { FileUploadModule } from 'primeng/fileupload';
import { TimelineModule } from 'primeng/timeline';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SkeletonModule } from 'primeng/skeleton';
import { PaginatorModule } from 'primeng/paginator';
import { InputMaskModule } from 'primeng/inputmask';




import { SplitterModule } from 'primeng/splitter';
// admin tabs components
import {AdminTabsComponent} from './admin-tabs/admin-tabs.component';
import {NationalityMainComponent} from './admin-tabs/nationality/nationality-main/nationality-main.component';
import {NationalitytypeMainComponent} from './admin-tabs/nationalitytype/nationalitytype-main/nationalitytype-main.component';
import {IndustriesMainComponent} from './admin-tabs/industries/industries-main/industries-main.components';
import {MaritalStatusMainComponent} from './admin-tabs/marital-status/marital-status-main/marital-status-main.component';
import {CountriesMainComponent} from './admin-tabs/countries/countries-main/countries-main.component';
import {StatesMainComponent} from './admin-tabs/states/states-main/states-main.component';
import {DocumenttypeMainComponent} from './admin-tabs/documenttype/documenttype-main/documenttype-main.component';
import {UserdefinedfieldMainComponent} from './admin-tabs/userdefinedfield/userdefinedfield-main/userdefinedfield-main.component';
import {CitiesMainComponent} from './admin-tabs/cities/cities-main/cities-main.component';
import {GlobalcustomfieldsMainComponent} from './admin-tabs/globalcustomfields/globalcustomfields-main/globalcustomfields-main.component';
import {MenusComponent} from './auth-tabs/menus/menus.component';
import {PaycomponentmasterComponent} from './admin-tabs/paycomponentmaster/paycomponentmaster.component';
import { InvestmentDefinedFieldsComponent } from './admin-tabs/investment-defined-fields/investment-defined-fields.component';
import { NotifyContentComponent } from './admin-tabs/notify-content/notify-content.component';

// Authentication Component
import {AuthTabsComponent} from './auth-tabs/auth-tabs.component';
import {UserMainComponent} from './auth-tabs/users/user-main/user-main.component';
import { MutipleUsersComponent } from './auth-tabs/mutliple-users/mutiple-users/mutiple-users.component';
import {AclRoleComponent} from './auth-tabs/role-menu-permission/acl-role/acl-role.component';

//        Client tabs Components
import {ClientsListComponent} from './clients/clients-list/clients-list.component';
import {ClientsComponent} from './clients/clients.component';

//      company tabs  components
import {CompanyComponent} from './company/company.component';
import {CompanyorganisationComponent} from './company/companyorganisation/companyorganisation.component';
import {CostcenterComponent} from './company/costcenter/costcenter.component';
import {CompanypaycomponentComponent} from './company/companypaycomponent/companypaycomponent.component';
import {JobplanComponent} from './company/jobplan/jobplan.component';
import { PaycomponentformulaComponent } from './company/paycomponentformula/paycomponentformula.component';

import {JobDetailsComponent} from './company/job-details/job-details.component';
import {CompanyFinancialyearComponent} from './company/company-financialyear/company-financialyear.component';
import {CompanyRegistrationcomponent} from './company/companyregistration/company-registrationcomponent';
import {CompanydepartmentComponent} from './company/companydepartment/companydepartment.component';
import {CompanyudvaluesComponent} from './company/companyudvalues/companyudvalues.component';
import {CompanyformulabuilderComponent} from './company/companyformulabuilder/companyformulabuilder.component';
import {LeavemanagementComponent} from './company/leavemanagement/leavemanagement.component';
import {TeamsComponent} from './company/teams/teams.component';
import {CustompolicyComponent} from './company/leavemanagement/custompolicy/custompolicy.component';
import {OvertimeCompanyComponent} from './company/overtime/overtime.component';
import {ShiftsComponent} from './company/shifts/shifts.component';
import { MonthlyShiftComponent } from './company/shifts/monthly-shift/monthly-shift.component';
import { WeeklyShiftComponent } from './company/shifts/weekly-shift/weekly-shift.component';
import {ShiftassignmentComponent} from './company/shiftassignment/shiftassignment.component';
import {CompanyDesignationMainComponent} from './company/companydesignation/companydesignation-main/companydesignation-main.component';
import {EmployeePayInfoComponent} from './company/employee-pay-info/employee-pay-info.component';
import { EmployeePayVarianceComponent } from './company/employee-pay-variance/employee-pay-variance.component';
import { LeaveplantypeComponent } from './company/leavemanagement/leaveplantype/leaveplantype.component';
import { LeavecompanyholidayComponent } from './company/leavemanagement/leavecompanyholiday/leavecompanyholiday.component';
import { LeavesettingsComponent } from './company/leavemanagement/leavesettings/leavesettings.component';
import {EmployeePayslipDownloadComponent} from './ess-tabs/IND-ess/IND-employee-payroll/employee-payslip/employee-payslip-download.component';
import { ShiftAssignComponent } from './company/shifts/shift-assign/shift-assign.component';
import{CompanyDocumentsComponent} from './company/company-documents/company-documents.component';
import { PaygroupsComponent } from './company/paygroups/paygroups.component';
import { PaycomponentimportComponent } from './company/companypaycomponent/paycomponentimport.component';
import { EmployeePayrollEngineComponent } from './company/employee-payroll-engine/employee-payroll-engine.component';
import{LeaveCompanySettingsComponent} from "./company/leavemanagement/leave-company-settings/leave-company-settings.component";
import {CompanyLeavePlanTypeComponent} from "./company/leavemanagement/company-leave-plan-type/company-leave-plan-type.component";
import { CompanyannouncementComponent } from './company/companyannouncement/companyannouncement.component';
import {KeySortingComponent} from "./company/key-sorting/key-sorting.component";
import { TimeWorkingstatusComponent } from './company/time-workingstatus/time-workingstatus.component';
import { ArrearsComponent } from './company/assign-arrears/arrears/arrears.component';
import { CompanyLocationComponent } from './company/company-location/company-location.component';
//import { FileReportsComponent } from './company/file-reports/file-reports.component';
import { ReportFeedbackComponent } from './company/report-feedback/report-feedback.component';
import { ReportFeedfackDetailsComponent } from './company/report-feedfack-details/report-feedfack-details.component';
import { EmployeeYtdComponent } from './company/employee-ytd/employee-ytd.component';
import { PreviousSalaryFileUploadComponent } from './company/previous-salary-file-upload/previous-salary-file-upload.component';
import { ReportsKeySortingComponent } from './company/reports-key-sorting/reports-key-sorting.component';
import { ReportsTypeComponent } from './company/reports-type/reports-type.component';
//      employee tabs  components
import {BankdetailsComponent} from './employees/bankdetails/bankdetails.component';
import {DocumentsComponent} from './employees/documents/documents.component';
import {EmployeefamilyComponent} from './employees/employeefamily/employeefamily.component';
import {EmploymentDetailsComponent} from './employees/employment-details/employment-details.component';
import {EmployeesComponent} from './employees/employees.component';
import {EmployeeListComponent} from './employees/employee-list/employee-list.component';
import {SkillsComponent} from './employees/skills/skills.component';
import {EmployeeExperienceComponent} from './employees/employee-experience/employee-experience.component';
import {SalaryCompensationComponent} from './employees/salary-compensation/salary-compensation.component';
import { EmployeeDeductionsComponent } from './employees/employee-deductions/employee-deductions.component';
import {EducationComponent} from './employees/education/education.component';
import {ExperienceDocumentsComponent} from './employees/experience-documents/experience-documents.component';
import { CurrentExperienceComponent } from './employees/current-experience/current-experience.component';
import { EmployeeSalaryBreakupComponent } from './employees/employee-salary-breakup/employee-salary-breakup.component';


//     import screen components
import {ExcelImportTabsComponent} from './excel-import-tabs/excel-import-tabs.component';
import {ImportdataMainComponent} from './excel-import-tabs/importdata/importdata-main/importdata-main.component';
import { ImportEmployeeMigrationComponent } from './excel-import-tabs/import-employee-migration/import-employee-migration.component';

import { ImportEmployeeDetailsComponent } from './excel-import-tabs/import_employee_details/import-employee-details.component';
import { ImportValidationComponent } from './excel-import-tabs/import-common/import-validation/import-validation.component';
import { ImportsGridComponent } from './excel-import-tabs/import-common/imports-grid/imports-grid.component';
import { ImportDropdownUploadComponent } from './excel-import-tabs/import-common/import-dropdown-upload/import-dropdown-upload.component';
import {ImportApproverComponent} from "./excel-import-tabs/import-approver/import-approver.component";
import { ImportAzateconMakerComponent } from './excel-import-tabs/import-azatecon-maker/import-azatecon-maker.component';
import { ImportAzaValidationComponent } from './excel-import-tabs/import-common/import-aza-validation/import-aza-validation.component';
import { ImportValidationHistoryComponent } from './excel-import-tabs/import-common/import-validation-history/import-validation-history.component';
//

//     Dashboard Components
import {DashboardComponent} from './dashboard/dashboard.component';
import {EmployeeDashboardComponent} from './dashboard/employee-dashboard/employee-dashboard.component';
import {AdminDashboardComponent} from './dashboard/admin-dashboard/admin-dashboard.component';
// import { CompanyDashboardComponent } from './dashboard/company-dashboard/company-dashboard.component';
import {NgChartsModule } from 'ng2-charts';
// ESS Tabs
import {EssTabsComponent} from './ess-tabs/ess-tabs.component';

// IND ess tabs  components

import {INDEssComponent} from './ess-tabs/IND-ess/IND-ess.component';
import {EmployeeHrmsLeavesComponent} from './ess-tabs/IND-ess/IND-employee-hrms/employee-hrms-leaves/employee-hrms-leaves.component';
import {EmployeeInfoComponent} from './ess-tabs/IND-ess/IND-employee-hrms/employee-info/employee-info.component';
import {LeaveapprovelComponent} from './ess-tabs/IND-ess/IND-employee-hrms/leaveapprovel/leaveapprovel.component';
import {EmployeePayslipComponent} from './ess-tabs/IND-ess/IND-employee-payroll/employee-payslip/employee-payslip.component';
import {InvestmentDeclarationComponent} from './ess-tabs/IND-ess/IND-employee-payroll/investment-declaration/investment-declaration.component';
import {SalaryComputationComponent} from './ess-tabs/IND-ess/IND-employee-payroll/salary-computation/salary-computation.component';
import { EmployeeSalaryCompensationComponent } from './ess-tabs/IND-ess/IND-employee-payroll/employee-salary-compensation/employee-salary-compensation.component';
import { LeaveHomeComponent } from './ess-tabs/IND-ess/IND-employee-hrms/leave-management/leave-home/leave-home.component';
import {TaxComputationComponent} from "./ess-tabs/IND-ess/IND-employee-payroll/tax-computation/tax-computation.component";
import { CalendarComponent } from './common/calendar/calendar.component';
import { ReimbursementComponent } from './ess-tabs/IND-ess/IND-employee-payroll/reimbursement/reimbursement.component';
import { ClaimReimbursementComponent } from './ess-tabs/IND-ess/IND-employee-payroll/reimbursement/claim-reimbursement/claim-reimbursement.component';
import { ReimbursementSlipComponent } from './ess-tabs/IND-ess/IND-employee-payroll/reimbursement/reimbursement-slip/reimbursement-slip.component';
import { MonthinputComponent } from './ess-tabs/IND-ess/IND-employee-payroll/monthinput/monthinput.component';
import { FbpComponent } from './ess-tabs/IND-ess/IND-employee-payroll/fbp/fbp.component';
import { ProvidentFundComponent } from './ess-tabs/IND-ess/IND-employee-payroll/provident-fund/provident-fund.component';
import { PfcontributionComponent } from './ess-tabs/IND-ess/IND-employee-payroll/provident-fund/pfcontribution/pfcontribution.component';
import { PfemployeeoptionsComponent } from './ess-tabs/IND-ess/IND-employee-payroll/provident-fund/pfemployeeoptions/pfemployeeoptions.component';
import { VPFComponent } from './ess-tabs/IND-ess/IND-employee-payroll/provident-fund/vpf/vpf.component';
import { HomepageComponent } from './ess-tabs/IND-ess/homepage/homepage.component';
import { PayslipFormulaPopupComponent } from './ess-tabs/IND-ess/IND-employee-payroll/employee-payslip/payslip-formula-popup/payslip-formula-popup.component';
import { EmployeeSettingsComponent } from './ess-tabs/IND-ess/employee-settings/employee-settings.component';
import { TimeComponent } from './ess-tabs/IND-ess/IND-employee-hrms/time/time.component';
import { DatePipe } from '@angular/common';
import { PayrollAdminTabsComponent } from './admin-tabs/payroll-admin-tabs/payroll-admin-tabs.component';
import { PayrollfileComponent } from './admin-tabs/payroll-admin-tabs/payrollfile/payrollfile.component';
import { AdminpayrollreportComponent } from './admin-tabs/payroll-admin-tabs/adminpayrollreport/adminpayrollreport.component';
import { UserManualComponent } from './ess-tabs/IND-ess/user-manual/user-manual.component';
import { TaxDocumentComponent } from './ess-tabs/IND-ess/IND-employee-hrms/tax-document/tax-document.component';
import { NpsComponent } from './ess-tabs/IND-ess/IND-employee-payroll/provident-fund/nps/nps.component';
import { PfForm11Component } from './ess-tabs/IND-ess/IND-employee-payroll/provident-fund/pf-form11/pf-form11.component';
import { LoansAdvancesComponent } from './ess-tabs/IND-ess/IND-employee-payroll/loans-advances/loans-advances.component';
import { ApplyLoansComponent } from './ess-tabs/IND-ess/IND-employee-payroll/loans-advances/apply-loans/apply-loans.component';
import { LoanStatusComponent } from './ess-tabs/IND-ess/IND-employee-payroll/loans-advances/loan-status/loan-status.component';
import { LoanCurrentComponent } from './ess-tabs/IND-ess/IND-employee-payroll/loans-advances/loan-current/loan-current.component';
import { PreviousSalaryDocumentsComponent } from './ess-tabs/IND-ess/IND-employee-payroll/previous-salary-documents/previous-salary-documents.component';
import { PreviousPayslipComponent } from './ess-tabs/IND-ess/IND-employee-payroll/previous-salary-documents/previous-payslip/previous-payslip.component';
import { PreviousTaxDocumentComponent } from './ess-tabs/IND-ess/IND-employee-payroll/previous-salary-documents/previous-tax-document/previous-tax-document.component';
// managercomponent
import { ManagerPageComponent } from './ess-tabs/IND-ess/IND-employee-hrms/manager-page/manager-page.component';
import { TimeManagerComponent } from './ess-tabs/IND-ess/IND-employee-hrms/manager-page/timemanager/time-manager.component';
import { ShiftmappingComponent } from './ess-tabs/IND-ess/IND-employee-hrms/manager-page/timemanager/shiftmapping/shiftmapping.component';
import { DaytimeviewComponent } from './ess-tabs/IND-ess/IND-employee-hrms/manager-page/timemanager/daytimeview/daytimeview.component';
import { EmployeetimeviewComponent } from './ess-tabs/IND-ess/IND-employee-hrms/manager-page/timemanager/employeetimeview/employeetimeview.component';
import { PayrollreportsComponent } from './ess-tabs/IND-ess/IND-employee-hrms/manager-page/payrollreports/payrollreports.component';
import { FinalComponent } from './ess-tabs/IND-ess/IND-employee-hrms/manager-page/payrollreports/final/final.component';
import { DraftComponent } from './ess-tabs/IND-ess/IND-employee-hrms/manager-page/payrollreports/draft/draft.component';
import {TimeApprovalComponent} from "./ess-tabs/IND-ess/IND-employee-hrms/manager-page/timemanager/time-approval/time-approval.component";
import { PayrolluploadComponent } from './ess-tabs/IND-ess/IND-employee-hrms/manager-page/payrollupload/payrollupload.component';
import { CountryPayrollFormulaComponent } from './admin-tabs/country-payroll-formula/country-payroll-formula.component';
import { PayrollRunComponent } from './admin-tabs/payroll-admin-tabs/payroll-run/payroll-run.component';
import { TodaytimedetailsComponent } from './ess-tabs/IND-ess/IND-employee-hrms/manager-page/timemanager/todaytimedetails/todaytimedetails.component';
import { ReportColorPipe } from './common/pipes/report-color.pipe';
@NgModule({
    declarations: [
// maincomponent

        AllModulesComponent, HeaderComponent, SidebarComponent,NotificationComponent,


// admin tabs component

        DocumenttypeMainComponent, CountriesMainComponent, StatesMainComponent,
        CitiesMainComponent, DocumenttypeMainComponent, MaritalStatusMainComponent,
        IndustriesMainComponent, NationalityMainComponent, NationalitytypeMainComponent,
        GlobalcustomfieldsMainComponent, UserdefinedfieldMainComponent, AdminTabsComponent,
        MenusComponent,EmployeePayrollEngineComponent,PaycomponentimportComponent,CountryPayrollFormulaComponent,
        InvestmentDefinedFieldsComponent,NotifyContentComponent,

// payroll admin tabs component

        PayrollAdminTabsComponent, PayrollfileComponent,AdminpayrollreportComponent,PayrollRunComponent,

// Authentication Component

        UserMainComponent, AclRoleComponent, AuthTabsComponent,MutipleUsersComponent,

// Client Components
        ClientsListComponent, ClientsComponent,

// Company Components
        CompanyorganisationComponent, CompanypaycomponentComponent, CostcenterComponent,
        JobplanComponent, PaycomponentmasterComponent, JobDetailsComponent,ShiftassignmentComponent,
        CompanyComponent, CompanyFinancialyearComponent, CompanyRegistrationcomponent,
        CompanydepartmentComponent, CompanyudvaluesComponent, CompanyformulabuilderComponent,
        LeavemanagementComponent, TeamsComponent, CustompolicyComponent,CompanyDesignationMainComponent,
        OvertimeCompanyComponent, ShiftsComponent,MonthlyShiftComponent,WeeklyShiftComponent,ShiftAssignComponent,
        EmployeePayInfoComponent,EmployeePayVarianceComponent,LeaveCompanySettingsComponent,LeaveplantypeComponent,
        LeavesettingsComponent,EmployeePayslipDownloadComponent,CompanyDocumentsComponent,PaygroupsComponent,
        CompanyLeavePlanTypeComponent,CompanyannouncementComponent,KeySortingComponent,TimeWorkingstatusComponent,ArrearsComponent,CompanyLocationComponent,PaycomponentformulaComponent,ReportFeedbackComponent,EmployeeYtdComponent,ReportsKeySortingComponent,ReportsTypeComponent,
        CompanyLeavePlanTypeComponent,CompanyannouncementComponent,KeySortingComponent,TimeWorkingstatusComponent,ArrearsComponent,CompanyLocationComponent,PaycomponentformulaComponent,ReportFeedfackDetailsComponent,
        CompanyLeavePlanTypeComponent,CompanyannouncementComponent,KeySortingComponent,TimeWorkingstatusComponent,
        ArrearsComponent,CompanyLocationComponent,PaycomponentformulaComponent,
        PreviousSalaryFileUploadComponent,
        ReportFeedbackComponent,EmployeeYtdComponent,ReportsKeySortingComponent,LeavecompanyholidayComponent,
        CompanyLeavePlanTypeComponent,CompanyannouncementComponent,KeySortingComponent,TimeWorkingstatusComponent,ReportFeedfackDetailsComponent,
        ArrearsComponent,CompanyLocationComponent,PaycomponentformulaComponent,PreviousSalaryFileUploadComponent,
// Employee Components
        EmployeeListComponent, EmployeesComponent, BankdetailsComponent,
        DocumentsComponent, EducationComponent, EmployeeExperienceComponent,
        EmployeefamilyComponent, EmploymentDetailsComponent, ExperienceDocumentsComponent,
        SalaryCompensationComponent,EmployeeDeductionsComponent,SkillsComponent,CurrentExperienceComponent,
        EmployeeSalaryBreakupComponent,
// Import Screen Tabs
        ExcelImportTabsComponent, ImportdataMainComponent,ImportdataMainComponent,ImportEmployeeDetailsComponent,
        ImportValidationComponent, ImportsGridComponent,ImportDropdownUploadComponent,ImportEmployeeMigrationComponent,
        ImportApproverComponent,ImportAzateconMakerComponent,ImportAzaValidationComponent,ImportValidationHistoryComponent,


        // DashBoard Components
        EmployeeDashboardComponent, AdminDashboardComponent,

//  Ess Tabs
        EssTabsComponent,

// IND  ESS Tabs
        INDEssComponent, EmployeeHrmsLeavesComponent, EmployeeInfoComponent,
        LeaveapprovelComponent,EmployeePayslipComponent, InvestmentDeclarationComponent,
        SalaryComputationComponent, EmployeeSalaryCompensationComponent,LeaveHomeComponent,
        TaxComputationComponent, CalendarComponent,ReimbursementComponent,ClaimReimbursementComponent,
        ReimbursementSlipComponent,MonthinputComponent,FbpComponent,VPFComponent,TimeComponent,
        ProvidentFundComponent,,PfcontributionComponent,PfemployeeoptionsComponent,EmployeeSettingsComponent,
        HomepageComponent,PayslipFormulaPopupComponent,UserManualComponent,TaxDocumentComponent,NpsComponent,
        PfForm11Component,LoansAdvancesComponent,ApplyLoansComponent,LoanStatusComponent,LoanCurrentComponent,
        PreviousSalaryDocumentsComponent,PreviousPayslipComponent,PreviousTaxDocumentComponent,

   //   Manager page  components

        ManagerPageComponent,TimeManagerComponent,ShiftmappingComponent,DaytimeviewComponent,EmployeetimeviewComponent,
        PayrollreportsComponent,FinalComponent,DraftComponent,TimeApprovalComponent,PayrolluploadComponent,
        TodaytimedetailsComponent, ReportColorPipe,



      ],
    imports: [
        CommonModule, FormsModule, NgxMaterialTimepickerModule, DropdownModule, MultiSelectModule,PaginatorModule,
        CalendarModule, InputNumberModule, TreeTableModule, TableModule,FileUploadModule,
        HttpClientModule, DragDropModule, CdkScrollableModule, FieldsetModule,
        ToastrModule, TooltipModule, MenuModule, SliderModule,RippleModule,DockModule,
        TabViewModule, ChipsModule, BsDatepickerModule.forRoot(),InputSwitchModule,OrderListModule,
        // InMemoryWebApiModule.forRoot(AllModulesData),
       FullCalendarModule,SkeletonModule,SplitterModule,AvatarModule,AvatarGroupModule,BadgeModule,
        NgMultiSelectDropDownModule.forRoot(),ListboxModule,
        TagModule, AllModulesRoutingModule, ReactiveFormsModule,ScrollPanelModule,CarouselModule,PickListModule,NgChartsModule,
        MatTooltipModule, RadioButtonModule,FontAwesomeModule,TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),OverlayPanelModule,TimelineModule,InputMaskModule
    ],
    providers: [
        ApiModulesService,
        AllModulesService,
        documentservice,
        HeaderService,
        DatePipe
    ],

})
export class AllModulesModule {
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
