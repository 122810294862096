import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Table } from 'primeng/table';
import { ApiModulesService } from 'src/app/all-modules/api-modules.service';
import { EmployeeDropdownService } from 'src/app/all-modules/azatecon-services/employee-dropdown.service';
import { SpinnerService } from 'src/app/snipper/spinner.service';

@Component({
  selector: 'app-shiftmapping',
  templateUrl: './shiftmapping.component.html',
  styleUrls: ['./shiftmapping.component.css']
})
export class ShiftmappingComponent {

  @Input() tabUserPermissionData: any = {};
  ClientID: any = localStorage.getItem('client_id');
  CompanyID: any = localStorage.getItem('company_id');
  loading = false;
  nameBgColors: any = ["bg-blue-dim", "bg-blue-dim", "bg-azure-dim", "bg-indigo-dim", "bg-info-dim", "bg-purple-dim", "bg-pink-dim", "bg-orange-dim", "bg-teal-dim", "bg-primary-dim", "bg-success-dim", "bg-warning-dim", "bg-danger-dim", "bg-secondary-dim", "bg-dark-dim", "bg-gray-dim", "bg-blue-dim", "bg-azure-dim", "bg-indigo-dim", "bg-info-dim", "bg-purple-dim", "bg-pink-dim", "bg-orange-dim", "bg-teal-dim", "bg-primary-dim", "bg-success-dim", "bg-warning-dim", "bg-danger-dim", "bg-secondary-dim", "bg-dark-dim", "bg-gray-dim"];
  nameBorderColors: any = ["bg-outline-primary", "bg-outline-success", "bg-outline-info", "bg-outline-gray", "bg-outline-warning", "bg-outline-dark", "bg-outline-light", "bg-outline-secondary", "bg-outline-danger"];
  statuses: { label: string; value: string; }[];
  statusApi: any;
  statusValues: any;
  public ShowForm: any = false;

  public totalRecords: any = 0;
  public shiftAssignData = [];
  public month: any;
  public year: any;
  public addShiftAssignForm: FormGroup;
  shiftEmployeeData = [];
  employeeUnAssignedData = [];
  employeeAssignedData = [];
  shiftAssignDropdown = [];
  mappedData = [];
  showDropdown: boolean = true;
  employeeID = localStorage.getItem('employee_id');
  EmploymentsData=[];
  dropdownType=[];
  dropdownSelected=[];
  SelectedDropDown=[];
  showSelectedDropDown=[];
  EmployeeMaster=[];
  constructor(private spinnerService: SpinnerService,
    private apiModulesService: ApiModulesService,
    private formBuilder: FormBuilder,
    private toaster: ToastrService,
    private toastr: ToastrService,
    private employeeService: EmployeeDropdownService) {
  }

  ngOnInit(): void {
    this.spinnerService.raiseDataEmitterEvent('off');
    this.getShiftAssign();
    this.addShiftAssignForm = this.formBuilder.group({
      month_shift_id: ["", [Validators.required]],
      from_date: ["", [Validators.required]],
      to_date: ["", [Validators.required]],
      shift_assign_id: ["", []]
    });


  }

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
  }

  filterGrid(op: any) {
    // Implement your filtering logic here
    this.filterAssignedUnAssigned();
    op.hide();

  }



  onDropdownChange(event: any) {
    // Handle dropdown changes
  }

  onMoveToTarget(event: any) {
    const selectedItems = event.items;

    // Add the selected items to selectedComponentData
    selectedItems.forEach((item: any) => {
      const existingItem = this.employeeAssignedData.find((selectedItem: any) =>
        selectedItem.company_component_id === item.company_component_id
      );

      if (!existingItem) {
        this.employeeAssignedData.push({
          company_component_id: item.company_component_id,
          // Other properties if needed
        });
      }
    });

  }



  showAddForm() {
    this.ShowForm = true;
  }


  showList() {
    this.ShowForm = false;
  }


  //Get all shift assign data
  public getShiftAssign() {
    this.spinnerService.raiseDataEmitterEvent('on');
    var params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    this.apiModulesService.list("admin/shift_assign/getlist", params).subscribe((data) => {
      this.resetForm();
      this.shiftAssignData = (data.data.shift_assign[0] != undefined) ? data.data.shift_assign[0] : [];
      this.shiftAssignDropdown = (data.data.shift_assign[1] != undefined) ? data.data.shift_assign[1] : [];
      this.getFilterDropdown();
    }, (err) => {
      this.shiftAssignData = [];
      this.spinnerService.toasterError(err);

    });

  }//Get all shift assign data
  public getMappedShift() {
    if (!this.addShiftAssignForm.valid) {
      this.addShiftAssignForm.markAllAsTouched();
      return;
    }
    if (this.employeeAssignedData && this.employeeAssignedData.length > 0) {
      const updatedComponentData = this.employeeAssignedData.map((item, index) => {
        return {
          ...item,
          sequence_no: index + 1 // Update sequence_no with the index
          // Include other necessary properties here if needed
        };
      });

      var params: any = {};
      params.client_id = this.ClientID;
      params.company_id = this.CompanyID;
      params.shift_assign_id = this.addShiftAssignForm.get('shift_assign_id').value;
      params.from_date = this.addShiftAssignForm.get('from_date').value;
      params.to_date = this.addShiftAssignForm.get('to_date').value;
      params.mapped_employees = this.employeeAssignedData.map((val: any) => val.employee_id).toString();
      this.apiModulesService.list("admin/shift_assign/mapped", params).subscribe((data) => {
        this.mappedData = (data.data.shift_assign != undefined) ? data.data.shift_assign : [];
        if (this.mappedData.length != 0) {
          ($('#mapped_employees') as any).modal('show');

        } else {
          this.addShiftAssign();
        }

      }, (err) => {
        this.shiftAssignData = [];
        this.spinnerService.toasterError(err);

      });
    }

  }




  public resetForm() {
    this.addShiftAssignForm.reset();
    this.mappedData = [];
this.clearFilter();

  }

  clear(table: Table) {
    table.clear();
  }

  getSeverity(status: string) {
    switch (status.toLowerCase()) {
      case 'inactive':
        return 'danger';

      case 'active':
        return 'success';
    }
  }

  changeStatus(Status: any, data) {
    let params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.shift_assign_id = data.shift_assign_id;
    this.apiModulesService.edit(params, 'admin/shift_assign/' + Status).subscribe((data) => {
      this.getShiftAssign();
      ($('#assign-status') as any).modal('hide');
      this.toaster.success('Status Changed Successfully!', 'Success')
    }, (err) => {
      this.spinnerService.toasterError(err);
    }
    );
  }
  // add the employee for shift assign
  public addShiftAssign() {
    if (!this.addShiftAssignForm.valid) {
      this.addShiftAssignForm.markAllAsTouched();
      return;
    }
    if (this.employeeAssignedData && this.employeeAssignedData.length > 0) {
      const updatedComponentData = this.employeeAssignedData.map((item, index) => {
        return {
          ...item,
          sequence_no: index + 1 // Update sequence_no with the index
          // Include other necessary properties here if needed
        };
      });



      var params: any = {};
      params.company_id = this.CompanyID;
      params.client_id = this.ClientID;
      params.month_shift_id = this.addShiftAssignForm.get('month_shift_id').value;
      params.from_date = this.addShiftAssignForm.get('from_date').value;
      params.to_date = this.addShiftAssignForm.get('to_date').value;
      params.shift_assign_id = this.addShiftAssignForm.get('shift_assign_id').value;
      params.assign_details = this.employeeAssignedData;
      this.apiModulesService.edit(params, "admin/shift_assign/assign",).subscribe((data) => {
        this.getShiftAssign();
        this.toastr.success('Shift Assign Modified Sucessfully...!', 'Success');
        ($('#mapped_employees') as any).modal('hide');

        this.showList();
      }, err => {
        if (err.status === 437) {
          this.toastr.error('Shift Assign Already Exists...!', 'Failed');
        } else {
          this.spinnerService.toasterError(err);
        }
      });

    } else {
      this.toastr.error('Please select Employee before saving.', 'Error');
    }
  }


  editShiftAssign(shift_assign_id) {
    this.resetForm();
    let params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.shift_assign_id = shift_assign_id;
    this.apiModulesService.edit(params, 'admin/shift_assign/edit').subscribe((data) => {
      const shiftMainData = (data.data.shift_assign[0][0] != undefined) ? data.data.shift_assign[0][0] : [];
      this.employeeAssignedData = (data.data.shift_assign[2] != undefined) ? data.data.shift_assign[2] : [];
      this.filterAssignedUnAssigned();
      this.addShiftAssignForm.patchValue(({
        month_shift_id: shiftMainData.month_shift_id,
        shift_assign_id: shiftMainData.shift_assign_id,
        // department_id: shiftEmployeeData[0].department_id,
        // designation_id: shiftEmployeeData[0].designation_id,
        from_date: shiftMainData.from_date,
        to_date: shiftMainData.to_date,
      }));
      this.showAddForm();
    },(err)=>{
      this.spinnerService.toasterError(err);

    });
  }


  public getFilterDropdown() {
    var params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.employee_id=this.employeeID;
    this.apiModulesService.list("admin/shift_assign/filter", params).subscribe((data) => {
      this.EmployeeMaster = (data.data.shift_assign[0] != undefined) ? data.data.shift_assign[0] : [];
      this.employeeUnAssignedData = (data.data.shift_assign[0] != undefined) ? data.data.shift_assign[0] : [];
      this.EmploymentsData=data.data.shift_assign[1] != undefined ? data.data.shift_assign[1] : [];
      this.dropdownType=data.data.shift_assign[2] != undefined ? data.data.shift_assign[2] : [];
      this.employeeAssignedData = [];
      setTimeout(() => {
        this.spinnerService.raiseDataEmitterEvent('off');
      }, 100);
    }, (err) => {
      this.shiftAssignData = [];
      this.spinnerService.toasterError(err);

    });

  }



  getAcronym(str: any) {
    if (str != null) {
      let matches = str.match(/\b(\w)/g); // ['J','S','O','N']
      matches = matches.slice(0, 2);
      const acronym = matches.join(''); // JSON
      return acronym;
    }
  }

  getBgColors(str: any) {
    if (str != null) {
      const min = 0;
      const max = 15;
      const index = this.findAlphapositions(str, 1);
      return this.nameBgColors[index];
    }
  }

  findAlphapositions(str: any, n: any) {
    var NUM = 31;

    return str[0].charCodeAt(0) & NUM;
  }

  callStatusModel(url_name: any, data: any) {
    this.statusApi = url_name;
    this.statusValues = data;


  }

  onMoveToSource(event: any) {
  }



  filterDropDown(){
    this.showSelectedDropDown=[];
    if(this.dropdownSelected.length!=0){
      this.dropdownSelected.forEach((selVal,selKey)=>{
        this.showSelectedDropDown[selKey]=this.EmploymentsData.filter((value)=>value.type==selVal)
      });
    }else{
    }

  }
  filterAssignedUnAssigned() {

    let unassignedEmployees = this.EmployeeMaster.filter((employee) => {
      // Check if the employee is in the assigned list
      const isAssigned = this.employeeAssignedData.some(
        (assigned) =>
          assigned.employee_id.toString() === employee.employee_id &&
          assigned.employee_no === employee.employee_no
      );

      // Skip assigned employees
      if (isAssigned) {
        return false;
      }

      // Filter by selected dropdown values
      const matchesCriteria = Object.keys(this.SelectedDropDown).every((key) => {
        // Ensure the selected dropdown key exists and employee key is defined
        if (this.SelectedDropDown[key] && employee[key] !== undefined && employee[key] !== null && employee[key].length!=0) {
          // Convert to string to match with dropdown values
          const employeeValue = employee[key].toString();
          const selectedValues = this.SelectedDropDown[key];


          // Ensure `SelectedDropDown[key]` is an array and perform includes
          return Array.isArray(selectedValues) && selectedValues.includes(employeeValue);
        }
        return true; // Skip this criteria if it's not in the dropdown
      });

      return matchesCriteria;
    });

    this.employeeUnAssignedData = unassignedEmployees;
  }

  clearFilter(){
    this.showSelectedDropDown=[];
    this.SelectedDropDown=[];
    this.filterAssignedUnAssigned();
  }
}
