import { Component ,Input,ViewChild} from '@angular/core';
import { ApiModulesService } from 'src/app/all-modules/api-modules.service';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { PreviousPayslipComponent } from './previous-payslip/previous-payslip.component';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-previous-salary-documents',
  templateUrl: './previous-salary-documents.component.html',
  styleUrls: ['./previous-salary-documents.component.css']
})
export class PreviousSalaryDocumentsComponent {
  activeTab="";
  @ViewChild(PreviousPayslipComponent)previousPayslipComponent: PreviousPayslipComponent;
  menuKey: 'previous_salary_document';
  tabsData: any;
  tabPermission: any={};
  isCreate = localStorage.getItem('is_create');
  isModify = localStorage.getItem('is_modify');
  isRemove = localStorage.getItem('is_remove');
    constructor( private spinnerService: SpinnerService,private apiModulesService:ApiModulesService,private cdr:ChangeDetectorRef){}


    ngOnInit()
    {
      this.spinnerService.raiseDataEmitterEvent('on');
        this.activeTabPermission();
     }

     changeTabs(activeTab,data){

      this.activeTab = activeTab;
      this.tabPermission = data;
      this.cdr.detectChanges();

     }
     activeTabPermission() {
      const params = { menu_key: 'previous_salary_document' };
      this.apiModulesService.add(params, 'tabs-permission').subscribe((response) => {
        this.tabsData = response?.data?.tabs ?? [];
        if (this.tabsData.length > 0) {
          this.activeTab = this.tabsData[0].tab_key;
          this.tabPermission = this.tabsData.find(
            (val: any) => val.tab_key === this.activeTab
          );
        }
      });
    }
}
