import { Component, NgZone, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { __values } from 'tslib';
import{ApiModulesService} from '../../../api-modules.service';
import {SpinnerService} from '../../../../snipper/spinner.service';
import {Table} from 'primeng/table';

@Component({
    selector: 'app-acl-role',
    templateUrl: './acl-role.component.html',
    styleUrls: ['./acl-role.component.css']
})
export class AclRoleComponent implements OnInit, OnDestroy, AfterViewInit {
    public formData: FormGroup;
    public pvqForm: FormGroup;
    public questionGroups: FormArray;
    public datalist = [];
    public ShowForm: any = false;
    public ShowTabForm: any = false;
    public ShowPermissionForm: any = false;
    public ShowPermissionTabForm: any = false;
    is_create: any = false
    is_remove: any;
    searchKeyword: string = '';
    public menuPermission: any = false;
    public menuTabPermission: any = false;
    modulesSwitch = '';
    menuSwitch = '';
    clientData: any;
    menus = [];
    menusData: any;
    modules: any;
    statuses:any;
    loading=false;
    choose_role: any;
    masterSelected = false;
    type: any;
    listHeaders = [];
    nameBgColors: any = ['bg-blue-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim'];
    nameBorderColors: any = ['bg-outline-primary', 'bg-outline-success', 'bg-outline-info', 'bg-outline-gray', 'bg-outline-warning', 'bg-outline-dark', 'bg-outline-light', 'bg-outline-secondary', 'bg-outline-danger'];
    statusApi: any;
    statusValues: any;
    permittedMenu=[]
    menuOrder=[]
    pageTabName: any;
    menusTabData: any;
  tabRoleId: any;

    constructor(private apiModuleService: ApiModulesService,
                private toastr: ToastrService,
                private formBuilder: FormBuilder,
                private spinnerService: SpinnerService,
                private ngZone: NgZone) { }

    ngOnInit(): void {
        this.spinnerService.raiseDataEmitterEvent('on');

        this.getList();
        this.getClient();

        //Add form
        this.formData = this.formBuilder.group({
            role_name: ['', [Validators.required]],
            role_desc: ['', []],
            role_id: ['', []],


        });

        this.statuses = [
            { label: 'Active', value: 'active' },
            { label: 'Inactive', value: 'inactive' },
        ];
    }



    getList() {
        this.spinnerService.raiseDataEmitterEvent('on');
        this.apiModuleService.get('admin/roles/list').subscribe((data) => {
          this.datalist = (data.data.roles != undefined) ? data.data.roles : [];
            this.spinnerService.raiseDataEmitterEvent('off');
        }, (err) => {
            this.menusData = [];
            this.spinnerService.raiseDataEmitterEvent('off');
            let errors = err.error.data.roles;
            errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
            this.toastr.error(errors, 'TryAgain');

        });
    }
    importDataColumn(importDataColumn: any) {
        throw new Error('Method not implemented.');
    }

    showAddForm() {
        this.ShowForm = true;

    }

    showPermission(i) {
        this.modulesSwitch = i;
        this.menuSwitch = '';
    }
    showPermissions(i) {
        this.menuSwitch = i;


    }

    showMenuPermission() {
        this.menuPermission = true;

    }
    HideMenuPermission() {
        this.menuPermission = false;

    }

    ngAfterViewInit(): void {
    }


    public getClient() {
        this.apiModuleService.get('commonname/clientname').subscribe((data) => {
            this.clientData = (data);

        });
    }
    onEditRecord(list) {
        this.formData.setValue({
            role_id: list.role_id,
            role_name: list.role_name,
            role_desc: list.role_description,


        });
        // this.ShowForm = true;
        this.ShowPermissionForm = false;
    }
    showList() {
        this.ShowForm = false;
        this.ShowPermissionForm = false;
    }
    showTabList() {
        this.ShowTabForm = false;
        this.ShowPermissionTabForm = false;
    }
    onAddRecord() {
        if (!this.formData.valid) {
            this.formData.markAllAsTouched();
            return;
        }
        let params: any = {};


        params.role_name = this.formData.get('role_name').value,
            params.role_description = this.formData.get('role_desc').value;
        params.role_id = this.formData.get('role_id').value;

        this.apiModuleService.add(params, 'admin/roles/update').subscribe((data) => {
            ($('#add_roles') as any).modal('hide');
            this.toastr.success('Updated successfully..!', 'Success');
            this.getList();
            this.formData.reset();
            this.ShowForm = false;
        }, err => {
            this.toastr.error('Something went wrong!', 'Try Again');
        });

    }


    changeStatus(Status: any, roles: any) {
        let params: any = {};

        params.role_id = roles;

        this.apiModuleService.edit(params, 'admin/roles/' + Status).subscribe((data) => {
            this.getList();
            ($("#role-status") as any).modal("hide");
            this.toastr.success('Status updated successfully..!', 'Success');
        }, err => {
            this.toastr.error('Something went wrong!', 'Try Again');
        });
    }
    public resetForm() {
        this.formData.reset();
    }
    public roleMenu(role_id) {
        let params: any = {}
        this.choose_role = role_id;
        params.role_id = role_id;
        this.spinnerService.raiseDataEmitterEvent('on');
        this.apiModuleService.list('admin/rolepermission/menu', params).subscribe((data) => {
            this.permittedMenu=data.data.role_permission !=undefined && data.data.role_permission !=null ? data.data.role_permission:[];

            this.spinnerService.raiseDataEmitterEvent('off');
        }, (err) => {
            this.spinnerService.raiseDataEmitterEvent('off');
            let errors = err.error.data.role_permission;
            errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
            this.toastr.error(errors, 'TryAgain');

        });
    }
    changeMenuOrder(){
        this.permittedMenu.forEach((value,key)=>{
            this.permittedMenu[key]['menu_order']=key+1
        })
        let params: any = {}
        params.role_id = this.choose_role;
        params.menus = this.permittedMenu;
        this.spinnerService.raiseDataEmitterEvent('on');
        this.apiModuleService.list('admin/rolepermission/order/update', params).subscribe((data) => {
            ($('#order_menu') as any).modal('hide');
            this.toastr.success('Updated successfully..!', 'Success');

            this.spinnerService.raiseDataEmitterEvent('off');
        }, (err) => {
            this.spinnerService.raiseDataEmitterEvent('off');
            let errors = err.error.data.role_permission;
            errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
            this.toastr.error(errors, 'TryAgain');

        });
    }
    public moduiesMenu(role_id) {
        let params: any = {}
        this.choose_role = role_id;
        params.role_id = role_id;
        this.spinnerService.raiseDataEmitterEvent('on');
        this.apiModuleService.list('admin/rolepermission/list', params).subscribe((data) => {
            this.menusData = (data.data.role_permission != undefined) ? data.data.role_permission : [];
            this.ShowPermissionForm = true;
            var modulesList = [];
            var uniquemoduleIds = [];
            for(var i=0;i<this.menusData.length;i++){
                if(!(uniquemoduleIds.includes(this.menusData[i].module_id)) && this.menusData[i].module_id != 0){
                    uniquemoduleIds.push(this.menusData[i].module_id);
                    var moduleObj = [];
                    moduleObj['module_id'] = this.menusData[i].module_id;
                    moduleObj['module_name'] = this.menusData[i].module_name;
                    modulesList.push(moduleObj);
                }

            }
            this.modules = modulesList;
            // for(let i=0;i<=this.menusData.length;i++){
            // this.is_create=this.menusData.is_create;
            // this.is_remove[i]=this.menusData[i].is_remove;
            // if (this.is_create ==1){
            //   this.is_create=true
            // }else{
            //   this.is_create=false
            // }
            // if (this.is_remove[i] ==1){
            //   this.is_remove[i]=true
            // }else{
            //   this.is_remove[i]=false
            // }
            // }
            this.spinnerService.raiseDataEmitterEvent('off');
        }, (err) => {
            this.menusData = [];
            this.spinnerService.raiseDataEmitterEvent('off');
            let errors = err.error.data.role_permission;
            errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
            this.toastr.error(errors, 'TryAgain');

        });
    }
    public modulesMenuTab(role_id) {
      this.tabRoleId=role_id;
        let params: any = {}
        params.role_id = this.tabRoleId;
        this.spinnerService.raiseDataEmitterEvent('on');
        this.apiModuleService.list('admin/rolepermission/tab-list', params).subscribe((data) => {
            this.menusTabData = (data.data.menu_tab != undefined) ? data.data.menu_tab : [];
            this.ShowPermissionTabForm = true;
            var modulesList = [];
            var uniquemoduleIds = [];
            for(var i=0;i<this.menusTabData.length;i++){
                if(!(uniquemoduleIds.includes(this.menusTabData[i].menu_id)) && this.menusTabData[i].menu_id != 0){
                    uniquemoduleIds.push(this.menusTabData[i].menu_id);
                    var moduleObj = [];
                    moduleObj['menu_id'] = this.menusTabData[i].menu_id;
                    moduleObj['menu_name'] = this.menusTabData[i].menu_name;
                    modulesList.push(moduleObj);
                }
            }
            this.modules = modulesList;
            this.spinnerService.raiseDataEmitterEvent('off');
        }, (err) => {
            this.menusTabData = [];
            this.spinnerService.raiseDataEmitterEvent('off');
            let errors = err.error.data.menu_tab;
            errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
            this.toastr.error(errors, 'TryAgain');
        });
    }
    accordianclick(moduleId:any){
        for(var i=0;i<this.modules.length;i++){
            if(this.modules[i]['module_id'] == moduleId){
                if(this.modules[i]['active'] != 1)
                    this.modules[i]['active'] = 1;
                else
                    this.modules[i]['active'] = 0;

            }
        }
    }
    accordianTabClick(menu_id:any){
        for(var i=0;i<this.modules.length;i++){
            if(this.modules[i]['menu_id'] == menu_id){
                if(this.modules[i]['active'] != 1)
                    this.modules[i]['active'] = 1;
                else
                    this.modules[i]['active'] = 0;

            }
        }
    }
    menuRolePermission(permissionform: NgForm) {
        let params: any = {};
        params.role_id = this.choose_role;

        params.permissions = permissionform.value;
        this.apiModuleService.add(params, 'admin/rolepermission/update').subscribe((data) => {
            ($('#permission') as any).modal('hide');
            this.showList();
            this.toastr.success('Updated successfully..!', 'Success');
        }, err => {
            this.toastr.error('Something went wrong!', 'Try Again');
        });
    }
    tabMenuPermission(permissionTabform: NgForm) {
        let params: any = {};
        params.role_id = this.tabRoleId;
        params.tab_permissions = permissionTabform.value;
        this.apiModuleService.add(params, 'admin/rolepermission/update-tab').subscribe((data) => {
            ($('#tab_menu') as any).modal('hide');
            this.showTabList();
            this.toastr.success('Updated successfully..!', 'Success');
        }, err => {
            this.toastr.error('Something went wrong!', 'Try Again');
        });
    }

    isEnabled(value) {
        if (value === 1) {
            return value === 1
        } else {
            return value === 0
        }

    }


    getAcronym(str:any){
        let matches = str.match(/\b(\w)/g); // ['J','S','O','N']
        matches= matches.slice(0,3);
        const acronym = matches.join(''); // JSON
        return acronym;
    }
    getBgColors(str:any){
        const min = 0;
        const max = 15;
        const index = this.findAlphapositions(str,1);
        return this.nameBgColors[index];
    }
    findAlphapositions(str:any, n:any)
    {
        const NUM = 31;

        return str[0].charCodeAt(0) & NUM;
    }
    clear(table: Table) {
        table.clear();
    }

    getSeverity(status: string) {
        switch (status.toLowerCase()) {
            case 'inactive':
                return 'danger';

            case 'active':
                return 'success';
        }
    }
    ngOnDestroy(): void {
        // Do not forget to unsubscribe the event
    }
    callStatusModel(url_name:any,data:any,actionName:string){
        this.statusApi=url_name;
        this.statusValues=data;
      }
}


