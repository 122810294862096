import { Component, EventEmitter, Input, NgModule, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Table } from 'primeng/table';
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, Validators } from '@angular/forms';
import { ApiModulesService } from 'src/app/all-modules/api-modules.service';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { ToastrService } from 'ngx-toastr';
import { documentservice } from 'src/app/all-modules/documents.sevice';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ChangeDetectorRef } from '@angular/core';
@Component({
  selector: 'app-claim-reimbursement',
  templateUrl: './claim-reimbursement.component.html',
  styleUrls: ['./claim-reimbursement.component.css']
})
@NgModule({
  imports: [FormsModule],
})
export class ClaimReimbursementComponent implements OnChanges, OnInit {
  @Output() toggleVisibility = new EventEmitter<boolean>();
  @Input() reimbursement: any = {};
  @Input() voucherUpdated: boolean = false;
  claimRIBData = [];
  nameBgColors: any = ['bg-blue-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim'];
  nameBorderColors: any = ['bg-outline-primary', 'bg-outline-success', 'bg-outline-info', 'bg-outline-gray', 'bg-outline-warning', 'bg-outline-dark', 'bg-outline-light', 'bg-outline-secondary', 'bg-outline-danger'];
  statusApi: any;
  statusValues: any;
  public gridListView: boolean = true;
  editMode: boolean = false;
  activeIndex: any;
  singleBoxCheck: boolean = false;
  originalClaimValue: string;
  clientID = localStorage.getItem('client_id');
  companyID = localStorage.getItem('company_id');
  employeeID = localStorage.getItem('employee_id');
  financialId: any;
  addClaimForm: FormGroup;
  addLTAEligibleForm: FormGroup;
  EmpReimbursementId: any;
  cardBgColors = [
    'rgba(253,126,20,.25)', 'rgba(241,0,117,.25)', 'rgba(0,204,204,.25)', 'rgb(18,182,89,.25)', 'rgba(91,71,251,.2)'
  ]
  cardBorderColors = [
    '25px 19px 27px -20px rgba(253,126,20,.25)', '25px 19px 27px -20px rgba(0,204,204,.25)', '25px 19px 27px -20px rgba(91,71,251,.2)', '25px 19px 27px -20px #fbfcd7', '25px 19px 27px -20px #d9b8f5'
  ]
  filedata: any;
  oldPath: any;
  uploadFile: any;
  isHovered: boolean = false;
  url: string = '';
  urlSafe: SafeResourceUrl;
  awsimages: any;
  isHoveredeligible: boolean = false;
  isFileShow = false;
  isButtonVisible = true;
  transportData = [
    {
      selected_value: 'Air',
      selected_text: 'Air'
    },
    {
      selected_value: 'Train',
      selected_text: 'Train'
    },
    {
      selected_value: 'Road',
      selected_text: 'Road'
    },
  ]
  previousfromDate: Date;
  previoustoDate: Date;
  activeTab: string;
  isCurrentYears: boolean = true;
  activeCard: 'current' | 'previous' = 'current';
  public ShowForm: any = true;
  ltaEligibilityData: any;
  ltaYearData: any;
  ltaActiveIndex: any;
  previousYearData: any;
  currentYearData: any;
  date = new Date();
  public ShowVoucherForm: any = true;
  ShowProofForm: boolean;
  reimbProofFileData: any;
  componentName: any;
  fileNames: any;
  reimbursementId: any;
  reimbProofDownload: any;
  isEditButton: boolean = true;
  constructor(private spinnerService: SpinnerService,
    private apiModulesService: ApiModulesService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    private documentService: documentservice,
    public sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
  ) { }
  ngOnInit() {
    this.addClaimForm = this.formBuilder.group({
      claim_under_process: [''],
      emp_reimbursement_id: [''],
    });
    this.getclaimRIBData();
    this.addLTAEligibleForm = this.formBuilder.group({
      lta_eligible: this.formBuilder.array([]),
    });
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['voucherUpdated']) {
      this.getclaimRIBData();
    }
  }
  showCurrentYears() {
    this.isCurrentYears = true;
    const date = this.currentYearData?.max_from_month_year || new Date().toISOString();
    this.getLTAEligibilityData(date);
    this.activeCard = 'current';
    this.cdr.detectChanges();
  }
  showPreviousYears() {
    this.isCurrentYears = false;
    this.activeCard = 'previous';
    const date = this.previousYearData?.max_from_month_year || new Date().toISOString();
    this.getLTAEligibilityData(date);
    this.cdr.detectChanges();
  }
  ltaEligibilityButton() {
    const date = this.date.toISOString().split('T')[0];
    this.getLTAEligibilityData(date);
  }
  ltaEligibilityBack() {
    this.isFileShow = false;
    this.activeCard = 'current';
    this.getclaimRIBData();
  }
  onLtaEligibilityClick(): void {
    this.toggleVisibility.emit(false); // Emit 'false' to hide parent buttons
  }
  onBackClick(): void {
    this.toggleVisibility.emit(true); // Emit 'true' to show parent buttons
  }
  hideButton() {
    this.ShowForm = true;
  }
  getclaimRIBData() {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.clientID;
    params.company_id = this.companyID;
    params.employee_id = this.employeeID;
    params.month_name = currentMonth;
    this.apiModulesService.list('employee-tabs/IND-employee-payroll/claim-reimbursement/list', params).subscribe((data) => {
      this.claimRIBData = data.data.claim_reimbursement != undefined ? data.data.claim_reimbursement : [];
      if (this.claimRIBData[0]?.voucher_status == 'submit') {
        this.isEditButton = false;
      }
      else {
        this.isEditButton = true;
      }
      this.spinnerService.raiseDataEmitterEvent('off');
      this.getLtaYearData();
    }, (err) => {
      this.spinnerService.toasterError(err);
    });
  }
  getCurrentFinancialYear() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const nextYear = currentYear + 1;
    const financialYearStartMonth = 4;
    const currentMonth = currentDate.getMonth() + 1;
    const financialYear = currentMonth >= financialYearStartMonth
      ? `${currentYear}-${nextYear}`
      : `${currentYear - 1}-${currentYear}`;
    return financialYear
  }
  onAddClaim(item) {
    this.financialId = this.getCurrentFinancialYear();
    const myFormData = new FormData();
    myFormData.append('client_id', this.clientID);
    myFormData.append('company_id', this.companyID);
    myFormData.append('employee_id', this.employeeID);
    myFormData.append('emp_reimbursement_id', item);
    myFormData.append('financial_id', this.financialId);
    myFormData.append('claim_under_process', this.addClaimForm.get('claim_under_process').value);
    this.documentService.post('api/employee-tabs/IND-employee-payroll/claim-reimbursement/update-claim', myFormData).subscribe((data) => {
      this.addClaimForm.reset();
      this.getclaimRIBData();
      this.toastrService.success('Claim Updated Successfully...!', 'Success');
    }, (err) => {
      this.spinnerService.toasterError(err);
    });
    this.activeIndex = null;
    this.editMode = false;
  }
  getLtaYearData() {
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.clientID;
    this.apiModulesService.list('employee-tabs/IND-employee-payroll/claim-reimbursement/getyear', params).subscribe((data) => {
      this.ltaYearData = data.data.lta_previous_current != undefined ? data.data.lta_previous_current : [];
      this.previousYearData = this.ltaYearData.find(item => item.datakey === "previous_year") || {};
      this.currentYearData = this.ltaYearData.find(item => item.datakey === "current_year") || {};
      this.spinnerService.raiseDataEmitterEvent('off');
    }, (err) => {
      this.spinnerService.toasterError(err);
    });
  }
  getLTAEligibilityData(date: string) {
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.clientID;
    params.company_id = this.companyID;
    params.employee_id = this.employeeID;
    params.date = date;
    this.apiModulesService.list('employee-tabs/IND-employee-payroll/claim-reimbursement/getlist', params).subscribe((data) => {
      this.ltaEligibilityData = data.data.lta_eligibility != undefined ? data.data.lta_eligibility : [];
      setTimeout(() => {
        this.resetForm();
        this.spinnerService.raiseDataEmitterEvent('off');
      }, 100)
    }, (err) => {
      this.spinnerService.toasterError(err);
    });
  }
  updateLTAEligible(index: number) {
    const ltaEligibleArray = this.addLTAEligibleForm.get('lta_eligible') as FormArray;
    const eligibility = ltaEligibleArray.at(index).value;
    const params: any = {
      client_id: this.clientID,
      company_id: this.companyID,
      employee_id: this.employeeID,
      lta_eligible_id: eligibility.lta_eligible_id,
      lta_applied_date: eligibility.lta_applied_date,
      from_date: eligibility.from_date,
      to_date: eligibility.to_date,
      lta_claimed: eligibility.lta_claimed,
      mode_of_transport: eligibility.mode_of_transport,
      from_location: eligibility.from_location,
      to_location: eligibility.to_location,
    };
    this.apiModulesService.add(params, "employee-tabs/IND-employee-payroll/claim-reimbursement/update").subscribe((data) => {
      this.toastrService.success('LTA Modified Successfully!', 'Success');
      setTimeout(() => {
        const date = this.date.toISOString().split('T')[0];
        this.getLTAEligibilityData(date);
      }, 100)
    },
      (err) => {
        this.spinnerService.toasterError(err);
      }
    );
  }
  get itemsList(): FormArray {
    return this.addLTAEligibleForm.get('lta_eligible') as FormArray;
  }
  addNewItems(value: any): void {
    this.itemsList.push(this.createLTAEligible(value));
  }
  createLTAEligible(control_value: any): FormGroup {
    return this.formBuilder.group({
      year_label: [control_value.year_label, []],
      year_from_to_date: [control_value.year_from_to_date, []],
      lta_status: [control_value.lta_status, []],
      lta_applied_date: [control_value.lta_applied_date, [Validators.required]],
      from_date: [control_value.from_date, [Validators.required]],
      to_date: [control_value.to_date, [Validators.required]],
      annual_eligibility: [control_value.annual_eligibility],
      lta_claimed: [control_value.lta_claimed, [Validators.required]],
      mode_of_transport: [control_value.mode_of_transport, [Validators.required]],
      from_location: [control_value.from_location, [Validators.required]],
      to_location: [control_value.to_location, [Validators.required]],
      lta_eligible_id: [control_value.lta_eligible_id, [Validators.required]]
    });
  }
  resetForm() {
    this.addClaimForm.reset();
    if (this.isFileShow) {
      const timeStatusArray: any = this.addLTAEligibleForm.get('lta_eligible') as FormArray;
      timeStatusArray.clear();
      this.ltaEligibilityData.forEach((val, key) => {
        this.addNewItems(val);
      })
    }
    this.isFileShow = true;
  }
  cancelForm() {
    this.addClaimForm.reset();
  }
  addCurrentLTA() {
    this.ltaYearData.forEach((val, key) => {
      this.addNewItems(val);
    })
  }
  changeTabsViewToGrid(value) {
  }
  showGrid() {
    this.gridListView = true;
  }
  showListview() {
    this.gridListView = false;
  }
  showGridView() {
    this.gridListView = true;
  }
  hideGridView() {
    this.gridListView = false;
  }
  toggleTextbox(index: any, editedData: any) {
    this.editMode = !this.editMode;
    if (this.editMode) {
      this.addClaimForm.get('file_path')?.enable();
    } else {
      this.addClaimForm.get('file_path')?.disable();
    }
    this.activeIndex = index
    this.oldPath = editedData.file_path
    this.addClaimForm.patchValue({
      claim_under_process: editedData.claim_under_process
    })
  }
  saveChanges() {
    this.activeIndex = null
    this.editMode = false;
  }
  toggleGridTextbox(index: any, editedData: any) {
    this.editMode = !this.editMode;
    this.activeIndex = index;
    this.oldPath = editedData.file_path
    this.addClaimForm.patchValue({
      claim_under_process: editedData.claim_under_process
    })
  }
  saveGridChanges(event: any, index: any) {
    this.activeIndex = null
  }
  setActiveTab(tab: string) {
    this.activeTab = tab;
  }
  getBgColors(str: any) {
    const min = 0;
    const max = 15;
    const index = this.findAlphapositions(str, 1);
    return this.nameBgColors[index];
  }
  findAlphapositions(str: any, n: any) {
    const NUM = 31;
    return str[0].charCodeAt(0) & NUM;
  }
  clear(table: Table) {
    table.clear();
  }
  getSeverity(status: string) {
    switch (status.toLowerCase()) {
      case 'inactive':
        return 'danger';

      case 'active':
        return 'success';
    }
  }
  getAcronym(str: any) {
    let matches = str.match(/\b(\w)/g); // ['J','S','O','N']
    matches = matches.slice(0, 3);
    const acronym = matches.join(''); // JSON
    return acronym;
  }
  callStatusModel(url_name: any, data: any, actionName: string) {
    this.statusApi = url_name;
    this.statusValues = data;
  }
  toggleHover(value: boolean) {
    this.isHovered = value;
  }
  handleClick(i: number, claim: any) {
    this.toggleTextbox(i, claim);
    if (this.isFileShow || (claim && claim.component_key?.trim() === 'LTA_reimbursement')) {
      this.onLtaEligibilityClick();
      this.ltaEligibilityButton();
      this.showCurrentYears();
    }
  }
  handleGridClick(index: number, claim: any) {
    this.toggleGridTextbox(index, claim);
    if (this.isFileShow || (claim && claim.component_key?.trim() === 'LTA_reimbursement')) {
      this.onLtaEligibilityClick();
      this.ltaEligibilityButton();
      this.showCurrentYears();
    }
  }
  showProofForm() {
    this.ShowProofForm = true;
  }
  hideProofForm() {
    this.ShowProofForm = false;
  }
  veiwListFile(id: any, name: any) {
    this.componentName = name;
    this.reimbursementId = id;
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.clientID;
    params.company_id = this.companyID;
    params.employee_id = this.employeeID;
    params.emp_reimbursement_id = this.reimbursementId;
    this.apiModulesService.list('employee-tabs/IND-employee-payroll/claim-reimbursement/list-file', params).subscribe((data) => {
      this.reimbProofFileData = data?.data?.proof_reimb_file ?? [];
      ($('#reimb_file_details') as any).modal('show');
      setTimeout(() => {
        this.spinnerService.raiseDataEmitterEvent('off');
      }, 100)
    }, (err) => {
      this.spinnerService.toasterError(err);
    });
  }
  handleFileUpload(event: any) {
    this.filedata = event.target.files[0];
  }
  uploadFileDocuments() {
    if (!this.filedata || !this.fileNames) {
      this.toastrService.error('Please select a file before uploading', 'Error');
      return;
    }
    this.spinnerService.raiseDataEmitterEvent('on');
    this.financialId = this.getCurrentFinancialYear();
    const myFormData = new FormData();
    myFormData.append('client_id', this.clientID);
    myFormData.append('company_id', this.companyID);
    myFormData.append('employee_id', this.employeeID);
    myFormData.append('emp_reimbursement_id', this.reimbursementId);
    myFormData.append('financial_id', this.financialId);
    myFormData.append('file_name', this.fileNames);
    myFormData.append('file_path', this.filedata);
    this.documentService.post('api/employee-tabs/IND-employee-payroll/claim-reimbursement/upload', myFormData).subscribe((data) => {
      this.toastrService.success('Claim Updated Successfully...!', 'Success');
      this.filedata = [];
      this.fileNames = [];
      this.uploadFile = null;
      this.veiwListFile(this.reimbursementId, this.componentName);
    },
      (err) => {
        this.spinnerService.toasterError(err);
      }
    );
  }
  downloadReimbProofFile(id: any) {
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.clientID;
    params.company_id = this.companyID;
    params.employee_id = this.employeeID;
    params.reimbursement_proof_id = id;
    this.apiModulesService.list('employee-tabs/IND-employee-payroll/claim-reimbursement/download', params).subscribe((data) => {
      const filePath = data?.data?.proof_reimb_file?.[0]?.file_path ?? null;
      if (filePath) {
        this.documentService.DownloadExcel('Reimbursement.pdf', filePath);
        this.toastrService.success('File Download Successfully...!', 'Success');
      } else {
        this.toastrService.error('Download PDF issue!', 'Error');
      }
      this.spinnerService.raiseDataEmitterEvent('off');
    }, (err) => {
      this.spinnerService.toasterError(err);
    });
  }
  deletProofFile(id: any) {
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.clientID;
    params.company_id = this.companyID;
    params.employee_id = this.employeeID;
    params.emp_reimbursement_id = this.reimbursementId;
    params.reimbursement_proof_id = id;
    this.apiModulesService.list('employee-tabs/IND-employee-payroll/claim-reimbursement/delete', params).subscribe((data) => {
      this.veiwListFile(this.reimbursementId, this.componentName);
      setTimeout(() => {
        // this.resetForm();
        this.spinnerService.raiseDataEmitterEvent('off');
      }, 100)
    }, (err) => {
      this.spinnerService.toasterError(err);
    });
  }
}

