import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ApiModulesService } from '../../api-modules.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder } from '@angular/forms';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { Table } from 'primeng/table';
import jsPDF from 'jspdf';

@Component({
  selector: 'app-employee-salary-breakup',
  templateUrl: './employee-salary-breakup.component.html',
  styleUrls: ['./employee-salary-breakup.component.css']
})
export class EmployeeSalaryBreakupComponent {
    private user_type=localStorage.getItem('user_type');
    searchKeyword: string = '';
    @Input() authEmployeeData: any;
    @Input() pageName: any = {};

    tabToGrid=false;
    @Output() parenFun: EventEmitter<any> = new EventEmitter();
       compensationData=[];
       ctcData=[];
       employeeDetails=[];
       nameBgColors: any = ['bg-blue-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim'];
       nameBorderColors: any = ['bg-outline-primary', 'bg-outline-success', 'bg-outline-info', 'bg-outline-gray', 'bg-outline-warning', 'bg-outline-dark', 'bg-outline-light', 'bg-outline-secondary', 'bg-outline-danger'];
       showTableYearly = true;
       Downloads = false;
       download = false;
      constructor( private spinnerService: SpinnerService,
                  private apiModuleService: ApiModulesService,
                  private formBuilder: FormBuilder,
       ) {
   }
   
   
     ngOnInit(): void {
    
       this.spinnerService.raiseDataEmitterEvent('on');
       this.salaryCompensationData();
   
      
     }
     beforeDownloadFunction() {
       this.spinnerService.raiseDataEmitterEvent('on');
       this.Downloads = true;
       setTimeout(() => {
         this.Convert_HTML_To_PDF();
       }, 100);
     }
   
     print() {
       this.beforeDownloadFunction();
       setTimeout(() => {
         this.printContent();
       }, 100);
     }
   
     downloads() {
       this.beforeDownloadFunction();
     }
     afterDownloadFunction() {
   
       this.Downloads = false;
      //  this.showTableYearly = false;
       this.download = false;
       this.spinnerService.raiseDataEmitterEvent('off');
   
     }
    // Convert HTML content to PDF
    Convert_HTML_To_PDF() {
     const doc = new jsPDF();
     // Source HTMLElement or a string containing HTML.
     const elementHTML: any = document.querySelector('#contentToPrint');
     doc.html(elementHTML, {
       callback: function (doc) {
         // Save the PDF
         // doc.addPage('1','p')
         for (let i = doc.getNumberOfPages(); i != 1; i--) {
           doc.deletePage(i);
         }
   
         doc.setLineHeightFactor(10);
         doc.setFontSize(20);
         doc.setLineHeightFactor(10);
         doc.save('ctc-'+this.employeeDetails?.employee_no+'.pdf');
       },
       margin: [10, 10, 10, 10],
       autoPaging: 'slice',
       x: 0,
       y: 10,
       width: 190, // target width in the PDF document
       windowWidth: 1100 // window width in CSS pixels
   
     });
     this.afterDownloadFunction();
   }
   
   printContent() {
     const printContents = document.getElementById('contentToPrint').innerHTML;
     const originalContents = document.body.innerHTML;
     // Replace the entire content of the page with the content you want to print
     document.body.innerHTML = printContents;
     // Trigger the print dialog
     window.print();
     // Restore the original content after printing
     document.body.innerHTML = originalContents;
     this.afterDownloadFunction();
   }
   
     salaryCompensationData(){
       this.afterDownloadFunction();
       this.spinnerService.raiseDataEmitterEvent('on');
       const params: any = {};
       params.client_id = this.authEmployeeData.client_id;
       params.company_id = this.authEmployeeData.company_id;
       params.employee_id = this.authEmployeeData.employee_id;
   
       this.apiModuleService.list('admin/ctc/list', params).subscribe((data:any)=>{
   
      this.employeeDetails=data.data.salary_compensation[0][0] != undefined ? data.data.salary_compensation[0][0]: [];
      this.compensationData=data.data.salary_compensation[1] != undefined ? data.data.salary_compensation[1] : [];
      this.ctcData=data.data.salary_compensation[2][0] != undefined ? data.data.salary_compensation[2][0] : [];
   
      this.spinnerService.raiseDataEmitterEvent('off');
       }, (err) => {
         this.spinnerService.toasterError(err);
        })
     }
     getSeverity(status: string) {
       switch (status.toLowerCase()) {
           case 'inactive':
           return 'danger';
   
           case 'active':
           return 'success';
       }
   }
   getAcronym(str: any) {
     let matches = str.match(/\b(\w)/g); // ['J','S','O','N']
     matches = matches.slice(0, 2);
     const acronym = matches.join(''); // JSON
     return acronym;
   }
   
   getBgColors(str: any) {
     const min = 0;
     const max = 15;
     const index = this.findAlphapositions(str, 1);
     return this.nameBgColors[index];
   }
   
   findAlphapositions(str: any, n: any) {
     const NUM = 31;
   
     return str[0].charCodeAt(0) & NUM;
   }
     clear(table: Table) {
       table.clear();
   }
   parentFun(){

    this.parenFun.emit(this.tabToGrid)
}
}
