import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reportColor'
})
export class ReportColorPipe implements PipeTransform {

  transform(headers: any, employee_id: any, reportColor: any[]): boolean {
    // Find the matching report color based on employee_id
    const filter = reportColor.find(data => data.employee_id === employee_id);

    if (filter && filter[headers] === "1") {
      return true;
    }

    return false;
  }

}
