import { Component, OnInit } from '@angular/core';
import { ApiModulesService } from '../../api-modules.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray, } from '@angular/forms';
import { SpinnerService } from '../../../snipper/spinner.service';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-report-feedfack-details',
  templateUrl: './report-feedfack-details.component.html',
  styleUrls: ['./report-feedfack-details.component.css']
})
export class ReportFeedfackDetailsComponent implements OnInit {
  ClientDropDown = [];
  CompanyDropDown = [];
  ClientID: any;
  CompanyID: any;
  PaygroupDropDown: any;
  PaygroupID: any;
  ReportTypeDropDown = [];
  ReportID: any;
  reportTypeID: any;
  ReportDetailsData = [];
  ComponentdropdownData = [];
  ComponentID: any;
  filterheader = [];
  component_key: any;
  month_year_name: any;
  MonthDropDown = [];
  sequenceDropdown = [];
  reports_feedback_id: any;
  constructor(private apiModuleService: ApiModulesService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder, private spinnerService: SpinnerService,
    private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.spinnerService.raiseDataEmitterEvent('off');
    this.GetClientDropDown();

  }

  /* client DropDown codes*/
  public GetClientDropDown() {
    this.apiModuleService.get('DropDown/client').subscribe((data) => {
      this.ClientDropDown = data.data.client;
      this.ClientID = data.data.client[0].client_id;
      if (this.ClientDropDown.length !== 0) {
        setTimeout(() => {
          this.ClientID = data.data.client[0].client_id;
          this.GetCompanyDropDown();
        }, 100)
      }
    })
  }

  public GetCompanyDropDown() {
    const params: any = {};
    params.client_id = this.ClientID;
    this.apiModuleService.list('DropDown/company', params).subscribe((data) => {
      this.CompanyDropDown = data.data.company != undefined ? data.data.company : [];
      if (this.CompanyDropDown.length !== 0) {
        setTimeout(() => {
          this.CompanyID = this.CompanyDropDown[0].company_id;
          this.GetMonthDropDown();
        }, 100)
      }
      else {
        this.CompanyID = null;
      }
    }
    )
  }

  public GetMonthDropDown() {
    var params: any = {
      client_id: this.ClientID || '',
      company_id: this.CompanyID || ''
    };

    console.log('Params being sent:', params); // Log to verify values

    this.apiModuleService.list('admin/reportcumulative/month_dropDown', params).subscribe(
      (data) => {
        console.log('Full API Response:', data);
        this.MonthDropDown = data?.data?.reports_cumulative ?? [];

        if (this.MonthDropDown.length > 0) {
          this.month_year_name = this.MonthDropDown[0]?.month_year_name ?? null;
          setTimeout(() => {
            this.SequenceDropdown();
          }, 100);
        } else {
          console.warn('Dropdown data is empty.');
          this.month_year_name = null;
        }
      },
      (error) => {
        console.error('API Error:', error);
      }
    );


  }

  public SequenceDropdown() {
    var params: any = {}
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.month_year_name = this.month_year_name;


    this.apiModuleService.list('admin/reportcumulative/sequence-dropDown', params).subscribe((data) => {
      this.sequenceDropdown = data?.data?.reports_cumulative ?? [];

      console.log(this.sequenceDropdown);
      if (this.sequenceDropdown.length > 0) {
        this.reports_feedback_id = this.sequenceDropdown[0]?.reports_feedback_id ?? null;
        setTimeout(() => {
          this.ComponentDropdown();
        }, 100);
      } else {
        console.warn('Dropdown data is empty.');
        this.reports_feedback_id = null;
      }
    }, (error) => {
      console.error('API Error:', error);
    });
  }


  public ComponentDropdown() {
    var params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.component_key = this.component_key;

    this.apiModuleService.list('admin/reportcumulative/dropdown', params).subscribe((data) => {
      if (data?.data?.dropDown && Array.isArray(data.data.dropDown)) {
        this.ComponentdropdownData = data.data.dropDown;
        console.log("Dropdown Data:", this.ComponentdropdownData);

        if (this.ComponentdropdownData.length > 0) {
          this.ComponentID = this.ComponentdropdownData[0]?.component_id ?? null;
          setTimeout(() => {
            this.component_key = this.ComponentdropdownData[0]?.component_key ?? null;
            this.GetReportDetails();  // Ensure "All" condition is handled
          }, 100);
        } else {
          this.ComponentID = null;
          this.component_key = null;
        }
      } else {
        console.warn('Dropdown data is missing or not an array:', data);
        this.ComponentdropdownData = [];
        this.ComponentID = null;
        this.component_key = null;
      }
    }, (error) => {
      console.error('API Error:', error);
    });
  }



  public GetReportDetails() {
    var params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.component_key = this.component_key;
    params.month_year_name = this.month_year_name;
    params.reports_feedback_id = this.reports_feedback_id;
    params.component_id = this.ComponentID;
    

    this.apiModuleService.list('admin/reportcumulative/report-grid', params).subscribe((data) => {
      console.log('API Response:', data.data); // Debugging line

      if (Array.isArray(data.data.reports_cumulative) && data.data.reports_cumulative.length > 0 &&
        Array.isArray(data.data.reports_cumulative) && data.data.reports_cumulative.length > 0) {

        this.ReportDetailsData = data.data.reports_cumulative ?? [];
      } else {
        this.ReportDetailsData = [];
      }

      setTimeout(() => {
        console.log(this.ReportDetailsData);
        this.componentFilter();
      }, 100);
    });
  }

  public componentFilter() {
    if (!Array.isArray(this.ReportDetailsData) || this.ReportDetailsData.length === 0) {
      console.log('No data to filter.');
      this.filterheader=[]
      return;
    }

    if (this.component_key === 'All') {
      this.filterheader = this.ReportDetailsData;
    } else {
      this.filterheader = this.ReportDetailsData.filter(item => item.component_key == this.component_key);
      console.log('Filtered Header:', this.filterheader);
    }
  }

}  