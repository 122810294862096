// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 76%;
    outline: 0;
    margin:auto;
}
.col-md-10{
    position: relative;
    margin-top: 30px;
    
}
.table-container {
  max-height: 480px; /* Adjust as needed */
  overflow-y: auto; /* Enables vertical scrolling */
  overflow-x: hidden; /* Hide horizontal scroll */
  border: 1px solid #ddd;
  padding: 10px;
  display: block; /* Ensures proper scrolling */
}

.table-contain {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}

.table-scroll-container {
  max-height: 500px; /* Adjust height as needed */
  overflow: auto;
}

  



  

  
  

`, "",{"version":3,"sources":["webpack://./src/app/all-modules/company/employee-pay-info/employee-pay-info.component.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,UAAU;IACV,UAAU;IACV,WAAW;AACf;AACA;IACI,kBAAkB;IAClB,gBAAgB;;AAEpB;AACA;EACE,iBAAiB,EAAE,qBAAqB;EACxC,gBAAgB,EAAE,+BAA+B;EACjD,kBAAkB,EAAE,2BAA2B;EAC/C,sBAAsB;EACtB,aAAa;EACb,cAAc,EAAE,6BAA6B;AAC/C;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB,EAAE,4BAA4B;EAC/C,cAAc;AAChB","sourcesContent":[".modal-content {\r\n    position: relative;\r\n    display: flex;\r\n    flex-direction: column;\r\n    width: 76%;\r\n    outline: 0;\r\n    margin:auto;\r\n}\r\n.col-md-10{\r\n    position: relative;\r\n    margin-top: 30px;\r\n    \r\n}\r\n.table-container {\r\n  max-height: 480px; /* Adjust as needed */\r\n  overflow-y: auto; /* Enables vertical scrolling */\r\n  overflow-x: hidden; /* Hide horizontal scroll */\r\n  border: 1px solid #ddd;\r\n  padding: 10px;\r\n  display: block; /* Ensures proper scrolling */\r\n}\r\n\r\n.table-contain {\r\n  width: 100%;\r\n  overflow-x: auto;\r\n  white-space: nowrap;\r\n}\r\n\r\n.table-scroll-container {\r\n  max-height: 500px; /* Adjust height as needed */\r\n  overflow: auto;\r\n}\r\n\r\n  \r\n\r\n\r\n\r\n  \r\n\r\n  \r\n  \r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
