import { Component, Input } from '@angular/core';
import { ApiModulesService } from 'src/app/all-modules/api-modules.service';
import { SpinnerService } from 'src/app/snipper/spinner.service';
@Component({
  selector: 'app-loan-current',
  templateUrl: './loan-current.component.html',
  styleUrls: ['./loan-current.component.css']
})
export class LoanCurrentComponent {
  @Input() UserPermissionLoans: any = {};
  showAccountNumber = true;
  showDate = true;
  showUanNumber = true;
  clientID = localStorage.getItem('client_id');
  companyID = localStorage.getItem('company_id');
  employeeID = localStorage.getItem('employee_id');
  employeeDetailsData = [];
  currentLoanData: any=[];
  currentLoanDetailsData = [];
  LoanFilterData: any=[];
  constructor(private apimoduleservice: ApiModulesService, private spinnerService: SpinnerService,) { }
  ngOnInit() {
    this.spinnerService.raiseDataEmitterEvent('on');
    this.currentLoansData();
  }
  currentLoansData() {
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.clientID;
    params.company_id = this.companyID;
    params.employee_id = this.employeeID;
    this.apimoduleservice.list('employee-tabs/IND-employee-payroll/loans/current-loan-list', params).subscribe((data) => {
      this.employeeDetailsData = data.data.employee_data != undefined ? data.data.employee_data : [];
      this.currentLoanData = data.data.current_loan != undefined ? data.data.current_loan : [];
      this.spinnerService.raiseDataEmitterEvent('off');
    }, (err: any) => {
      this.spinnerService.toasterError(err);
    })
  }
  currentLoansDetails(value: any) {
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.clientID;
    params.company_id = this.companyID;
    params.employee_id = this.employeeID;
    params.current_loan_id = value;
    this.apimoduleservice.list('employee-tabs/IND-employee-payroll/loans/current-loan-details', params).subscribe((data) => {
      this.LoanFilterData = data.data.loan_data != undefined ? data.data.loan_data : [];
      this.currentLoanDetailsData = data.data.current_loan_details != undefined ? data.data.current_loan_details : [];
      this.spinnerService.raiseDataEmitterEvent('off');
    }, (err: any) => {
      this.spinnerService.toasterError(err);
    })
  }

}
