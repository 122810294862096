import { Injectable } from '@angular/core';
import { ApiModulesService } from '../all-modules/api-modules.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  permittedTabs = []
  constructor(private ApiModuleService: ApiModulesService) { }

  activeComponentTabPermission(menu_key, active_tab) {
    var params: any = {
      'menu_key': menu_key
    }
    this.ApiModuleService.add(params, 'tabs-permission').subscribe((data) => {
      this.permittedTabs = data?.data?.tabs ?? [];
    })
  }

}
