import { Component, OnInit } from '@angular/core';
import { ApiModulesService } from '../../api-modules.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray, } from '@angular/forms';
import { SpinnerService } from '../../../snipper/spinner.service';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-reports-type',
  templateUrl: './reports-type.component.html',
  styleUrls: ['./reports-type.component.css']
})
export class ReportsTypeComponent {
  countryname: any;
  CountryID: any;
  ReportTypeData = [];
  statusApi: any;
  statusValues: any;
  statuses: { label: string; value: string; }[];
  reportTypeId: any;


  constructor(private apiModuleService: ApiModulesService,
      private toastrService: ToastrService,
      private formBuilder: FormBuilder, private spinnerService: SpinnerService,
      private cdr: ChangeDetectorRef) {
    }

    ngOnInit(): void {
      this.spinnerService.raiseDataEmitterEvent('off');
      this.getCountriesName();
     
      this.statuses = [
        { label: 'Active', value: 'active' },
        { label: 'Inactive', value: 'inactive' },
      ];
  
    }
  public getCountriesName() {
    this.apiModuleService.get('admin/paycomponent/dropdown').subscribe((data) => {
      this.countryname = data.data.pay_component;
      this.CountryID = this.countryname[0].country_id;
      this.getReportsType();
    });
  }

  public getReportsType(){
    var params:any = {}
    params.country_id = this.CountryID;
    this.apiModuleService.list('admin/reportstype/list',params).subscribe((data)=>{
      this.ReportTypeData = data.data.reports_type;
      this.reportTypeId = data.data.reports_type[0].reports_type_id;
      console.log(this.ReportTypeData);    
      },
      (err) => {
       
        this.spinnerService.raiseDataEmitterEvent('off');
      });
  }

  callStatusModel(url_name: any, data: any) {
    this.statusApi = url_name;
    this.statusValues = data;
  }

  getSeverity(status: string) {
    switch (status.toLowerCase()) {
      case 'inactive':
        return 'danger';

      case 'active':
        return 'success';
    }
  }

  changeStatus(status: any) {

    var params: any = {};
    params.reports_type_id = this.reportTypeId;
    //
    this.apiModuleService.edit(params, 'admin/reportstype/' + status).subscribe((data) => {
      this.getReportsType();
      ($('#report-status') as any).modal('hide');
      this.toastrService.success('Status Changed Sucessfully...!', 'Success');
    }, (err) => {
      this.toastrService.error('Something went wrong!', 'Try Again');
    });
  }
}
