import { Component } from '@angular/core';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import {ApiModulesService} from "../../../../../api-modules.service";
@Component({
  selector: 'app-time-manager',
  templateUrl: './time-manager.component.html',
  styleUrls: ['./time-manager.component.css']
})
export class TimeManagerComponent {
  menu_key = 'manager_time';
    activeTab="";
    tabsData: any = [];
    tabPermission: any ={};
    isCreate = localStorage.getItem('is_create');
    isModify = localStorage.getItem('is_modify');
    isRemove = localStorage.getItem('is_remove');
    constructor( private spinnerService: SpinnerService,private apiModulesService:ApiModulesService){}


    ngOnInit()
    {
        this.spinnerService.raiseDataEmitterEvent('on');
        this.activeTabPermission();
    }

    changeTabs(activeTab){

        this.activeTab = activeTab;

    }
    activeTabPermission() {
      const params = { menu_key: 'manager_time' };
      this.apiModulesService.add(params, 'tabs-permission').subscribe((response) => {
        this.tabsData = response?.data?.tabs ?? [];
        if (this.tabsData.length > 0) {
          this.activeTab = this.tabsData[0].tab_key;
          this.tabPermission = this.tabsData.find(
            (val: any) => val.tab_key === this.activeTab
          );
        }
      });
    }
}
