import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiModulesService } from 'src/app/all-modules/api-modules.service';
import { SpinnerService } from 'src/app/snipper/spinner.service';
@Component({
  selector: 'app-apply-loans',
  templateUrl: './apply-loans.component.html',
  styleUrls: ['./apply-loans.component.css']
})
export class ApplyLoansComponent implements OnInit {
  @Input() UserPermissionLoans: any = {};
  showAccountNumber = true;
  showDate = true;
  showUanNumber = true;
  isFormDisabled = true;
  selectedLoan: string | null = null;
  clientID = localStorage.getItem('client_id');
  companyID = localStorage.getItem('company_id');
  employeeID = localStorage.getItem('employee_id');
  employeeDetailsData = [];
  loanTypeData: any = {};
  loanDropdownData = [];
  addLoansForm: FormGroup;

  constructor(private apimoduleservice: ApiModulesService, private spinnerService: SpinnerService,
    private formBuilder: FormBuilder, private toastr: ToastrService,) { }
  ngOnInit() {
    this.spinnerService.raiseDataEmitterEvent('off');
    this.loansAdvanceData();
    this.addLoansForm = this.formBuilder.group({
      application_date: [{ value: '', disabled: true }],
      loan_application_no: [{ value: '', disabled: true }],
      loan_type_id: [{ value: '', disabled: true }],
      intrest_rate: [{ value: '', disabled: true }],
      max_tenure_month: [{ value: '', disabled: true }],
      max_loan_value_month: [{ value: '', disabled: true }],
      loan_amount: [{ value: '', disabled: true }],
      instalments: [{ value: '', disabled: true }],
      start_month: [{ value: '', disabled: true }],
      emi: [{ value: '', disabled: true }],
      end_month: [{ value: '', disabled: true }],
    });
    this.addLoansForm.get('instalments').valueChanges.subscribe(() => this.updateEndMonth());
  this.addLoansForm.get('start_month').valueChanges.subscribe(() => this.updateEndMonth());

  }
  calculateEmi() {
    const loanAmount = this.addLoansForm.get('loan_amount')?.value;
    const instalments = this.addLoansForm.get('instalments')?.value;

    if (loanAmount && instalments) {
      const emi = (loanAmount / instalments).toFixed(2);  // Round to 2 decimal places
      this.addLoansForm.get('emi')?.setValue(emi);
    }
  }
  updateEndMonth() {
    const instalments = this.addLoansForm.get('instalments').value;
    const startMonth = this.addLoansForm.get('start_month').value;

    if (instalments && startMonth) {
      const startDate = new Date(startMonth);
      const endDate = new Date(startDate.setMonth(startDate.getMonth() + +instalments));
      const formattedEndDate = `${endDate.getFullYear()}-${('0' + (endDate.getMonth() + 1)).slice(-2)}`; // Format as yyyy-MM

      this.addLoansForm.get('end_month').setValue(formattedEndDate);
    }
  }
  enableForm() {
    this.isFormDisabled = false;
    Object.keys(this.addLoansForm.controls).forEach((controlName) => {
      this.addLoansForm.get(controlName)?.enable();
    });
  }
  loansAdvanceData() {
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.clientID;
    params.company_id = this.companyID;
    params.employee_id = this.employeeID;
    this.apimoduleservice.list('employee-tabs/IND-employee-payroll/loans/list', params).subscribe((data) => {
      this.employeeDetailsData = data.data.employee_data != undefined ? data.data.employee_data : [];
      this.loanDropdownData = data.data.type_name != undefined ? data.data.type_name : [];
      this.spinnerService.raiseDataEmitterEvent('off');
    }, (err: any) => {
      this.spinnerService.toasterError(err);
    })
  }
  onLoansTypeDetails(event: any) {
    this.spinnerService.raiseDataEmitterEvent('on');
    const selectedLoanTypeId = event.value;
    if (!selectedLoanTypeId) return;
    const params: any = {};
    params.client_id = this.clientID;
    params.company_id = this.companyID;
    params.employee_id = this.employeeID;
    params.loan_type_id = selectedLoanTypeId;
    this.apimoduleservice.list('employee-tabs/IND-employee-payroll/loans/loan-type', params).subscribe((data) => {
      this.loanTypeData = data.data.loans_type_data[0] != undefined ? data.data.loans_type_data[0] : {};
      this.spinnerService.raiseDataEmitterEvent('off');
    }, (err: any) => {
      this.spinnerService.toasterError(err);
    })
  }
  addLoansAdvance() {
    var params: any = {};

    // Retrieve maximum limits
    const maxLoanEligibility = this.employeeDetailsData[0]?.max_monthly_limit || 0;
    const maxLoanMonthValue = this.employeeDetailsData[0]?.max_loan_value_month || 0;
    const maxLoanInstalment = this.loanTypeData?.max_tenure_month || 0;

    // Get form values
    const enteredEmi = this.addLoansForm.get('emi')?.value || 0;
    const instalments = this.addLoansForm.get('instalments')?.value || 0;

    // Validate EMI against max_monthly_limit
    if (enteredEmi > maxLoanEligibility && enteredEmi > maxLoanMonthValue) {
      this.toastr.error(
        `EMI cannot exceed the maximum allowed value of ${maxLoanEligibility}.`,
        'Validation Error'
      );
      return;
    }

    // Validate instalments against max_tenure_month
    if (instalments > maxLoanInstalment) {
      this.toastr.error(
        `Number of instalments cannot exceed the maximum allowed months of ${maxLoanInstalment}.`,
        'Validation Error'
      );
      return;
    }

    // Prepare parameters for API call
    params.company_id = this.companyID;
    params.client_id = this.clientID;
    params.employee_id = this.employeeID;
    params.loan_type_id = this.addLoansForm.get('loan_type_id')?.value;
    params.application_date = this.addLoansForm.get('application_date')?.value;
    params.loan_application_no = this.addLoansForm.get('loan_application_no')?.value;
    params.loan_amount = this.addLoansForm.get('loan_amount')?.value;
    params.instalments = instalments;
    params.emi = enteredEmi;
    params.start_month = this.addLoansForm.get('start_month')?.value;
    params.end_month = this.addLoansForm.get('end_month')?.value;

    // API call to add loan details
    this.apimoduleservice.add(params, "employee-tabs/IND-employee-payroll/loans/add").subscribe(
      (data) => {
        this.toastr.success('Loans Updated Successfully!', 'Success');
        this.addLoansForm.reset();
        this.loansAdvanceData(); // Refresh data
      },
      (err) => {
        this.spinnerService.toasterError(err);
      }
    );
  }

  cancelLoansAdvance() {
    this.addLoansForm.reset();
    this.loansAdvanceData();

  }
}
