import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Messaging, getToken, onMessage } from "@angular/fire/messaging";
import { environment } from "../../environments/environment";
import { ApiModulesService } from "../all-modules/api-modules.service";

@Injectable({
  providedIn: "root",
})
export class MessagingService {
  currentMessage = new BehaviorSubject<any>(null);

  constructor(private messaging: Messaging,private apiService:ApiModulesService) {}

  requestPermission() {
    getToken(this.messaging, {
      vapidKey: environment.firebaseConfig.vapidKey, // Ensure VAPID Key is in environment.ts
    })
      .then((token) => {
        if (token) {
          this.apiService.add({"fcm_token":token},'fcm_token_update').subscribe((data)=>{

          })
        } else {
          console.warn("No registration token available.");
        }
      })
      .catch((err) => {
      });
  }

  receiveMessage() {
    onMessage(this.messaging, (payload) => {
  
      const title = payload?.notification?.title || "New Notification";
      const body = payload?.notification?.body || "You have a new message";
      const clickUrl = payload?.data?.app_url+payload?.data?.user_type+'/'+payload?.data?.menu_link || "http://localhost:4200/"; // Default URL
  
      // Show browser notification
      const notification = new Notification(title, {
        body: body,
        icon: "/assets/notification-icon.png", // Change this to your icon
        data: { url: clickUrl }, // Store the URL in the notification data
      });
  
      // Handle notification click
      notification.onclick = function (event) {
        event.preventDefault(); // Prevent default action
        window.open(notification.data.url, "_blank"); // Open in new tab
      };
    });
  }
  
}
