import { Component, Output, EventEmitter, OnInit, Input } from '@angular/core';
import { ApiModulesService } from '../../api-modules.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SpinnerService } from '../../../snipper/spinner.service';
import { Table } from 'primeng/table';
import { documentservice } from '../../documents.sevice';

@Component({
  selector: 'app-report-feedback',
  templateUrl: './report-feedback.component.html',
  styleUrls: ['./report-feedback.component.css']
})
export class ReportFeedbackComponent implements OnInit {
  @Output() parenFun: EventEmitter<any> = new EventEmitter();
  @Input() ClientID: any;
  @Input() CompanyID: any;
  @Input() employee_id: any;
  @Input() component_key: any;
  @Input() value:any;
  @Input() value_type:string='';
  @Input() reportId: any;
  @Input() editReportFeedbackForm: FormGroup
  @Input() activeTab: any;
  @Input() bulkID:any;
  @Input() component_name: any;
  @Input() employee_name: any;
  @Input() monthYear: any;
  showParent = true;
  componentId : any;
  component_id: any;
  statusApi: any;
  statusValues: any;
  reportValueType = [];
  feedback_by: String = 'client';





  constructor(private Documentservice: documentservice, private apiModulesService: ApiModulesService, private toaster: ToastrService, private toastr: ToastrService, private formBuilder: FormBuilder, private spinnerService: SpinnerService,) {
  }

  ngOnInit() {

    this.spinnerService.raiseDataEmitterEvent('on');

    this.onEditback();

    this.editReportFeedbackForm = this.formBuilder.group({
      remarks: ['', Validators.required],
      value: ['', Validators.required],
      value_type: ['', Validators.required],
    });

    // console.log(this.employee_id,this.component_key)
  }

  //   chageDropdown() {
  //     if (this.activeTab === 'all') {
  //         this.EmployeePayInfoDetails();
  //     }
  //     if (this.activeTab === 'recurring') {
  //         this.EmployeeRecurringDetails();
  //     }
  //     if (this.activeTab === 'non_recurring') {
  //         this.EmployeeNonRecurringDetails();
  //     }
  //     if (this.activeTab === 'employee_data') {
  //         this.EmployeeData();
  //     }
  // }

  public updateReports() {

    //this.slap_type = this.activeTab;
    this.spinnerService.raiseDataEmitterEvent('on');
    const params = {
      client_id: this.ClientID,
      company_id: this.CompanyID,
      employee_id: this.employee_id,
      component_key: this.component_key,
      component_id: this.componentId,
      month_year: this.monthYear,
      feedback_by: this.feedback_by,
      value: this.editReportFeedbackForm.value.value,
      value_type: this.editReportFeedbackForm.value.value_type,
      reports_feedback_id: this.reportId,
      bulk_id: this.bulkID ? this.bulkID.toString() : '',
      remarks: this.editReportFeedbackForm.value.remarks,
      slab_type: this.activeTab,
    };

    // Debugging logs
    console.log('Form Data:', this.editReportFeedbackForm.value);
    console.log('Params:', params);

    this.apiModulesService.edit(params, 'admin/reportfeedback/create').subscribe(
      (data) => {
        ($("#reports_feedback") as any).modal("hide");
        this.toaster.success("ReportFeedback Edited Successfully!", "Success");
        this.ToparentFun();
      },
      (err) => {
        if (err.error && err.error.reports_feedback) {
          const errorMessages = Object.keys(err.error.reports_feedback)
            .map((key) => `${key}: ${err.error.reports_feedback[key].join(', ')}`)
            .join('\n');
          this.toaster.error(errorMessages, "Validation Error");
        } else {
          this.toaster.error("ReportFeedback Already Exists or Another Error Occurred", "Failed");
        }
        this.spinnerService.raiseDataEmitterEvent('off');
      }
    );
  }

  public onEditback() {
    var params: any = {};
    params.component_name = this.component_name;
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
  
    this.apiModulesService.list('admin/payInfo/component-name', params).subscribe({
      next: (data) => {
        const componentName = data?.data?.component_name;
        if (componentName && componentName.length > 0) {
          this.componentId = componentName[0]?.component_id ?? "";
        } else {
          this.componentId = ""; // Set to empty string when undefined or empty
          console.warn("Component name is undefined or empty.");
        }
        console.log("Component Data:", this.componentId);
        this.editFunction();
      },
      error: (err) => {
        console.error("Error fetching component data:", err);
      }
    });
  }
  

  editFunction() {

    this.resetForm();
    this.spinnerService.raiseDataEmitterEvent('on');
    var editparams: any = {};
    editparams.client_id = this.ClientID;
    editparams.company_id = this.CompanyID;
    editparams.employee_id = this.employee_id;
    editparams.component_key = this.component_key;
    editparams.month_year = this.monthYear;
    editparams.feedback_by = this.feedback_by;
    editparams.value = this.value;
    editparams.value_type = this.value_type;
    editparams.component_id = this.componentId;
    editparams.reports_feedback_id = this.reportId;
    
    this.apiModulesService.edit(editparams, "admin/reportfeedback/edit").subscribe((data) => {
      console.log('API Response:', data);
    
      if (data && data.data && data.data.reports_feedback) {
        let reportfeedbackDetail = data.data.reports_feedback[0][0] || {};
        this.reportValueType = data.data.reports_feedback[1] || [];
    
        this.editReportFeedbackForm.setValue({
          remarks: reportfeedbackDetail.remarks || '',
          value: reportfeedbackDetail.value || '',
          value_type: reportfeedbackDetail.value_type || '',
        });
    
        ($("#reports_feedback") as any).modal("show");
      } else {
        console.error('Unexpected API response format');
      }
    
      this.spinnerService.raiseDataEmitterEvent('off');
    });
    
  }
 

  public deleteReports(){
    this.spinnerService.raiseDataEmitterEvent('on');
    const params = {
      client_id: this.ClientID,
      company_id: this.CompanyID,
      employee_id: this.employee_id,
      month_year: this.monthYear,
      feedback_by: this.feedback_by,
      component_key: this.component_key,
      component_id: this.componentId,
      reports_feedback_id: this.reportId,
      bulk_id: this.bulkID ? this.bulkID : '',
      remarks: this.editReportFeedbackForm.value.remarks,
      value: this.editReportFeedbackForm.value.value,
      value_type: this.editReportFeedbackForm.value.value_type,
      slab_type: this.activeTab,
    };
    this.apiModulesService.edit(params, 'admin/reportfeedback/delete-remarks').subscribe(
      (data) => {
        ($("#delete-reports") as any).modal("hide");
        this.toaster.success("ReportFeedback Delete Successfully!", "Success");
        this.ToparentFun();
      },
      (err) => {
        if (err.error && err.error.reports_feedback) {
          const errorMessages = Object.keys(err.error.reports_feedback)
            .map((key) => `${key}: ${err.error.reports_feedback[key].join(', ')}`)
            .join('\n');
          this.toaster.error(errorMessages, "Validation Error");
        } else {
          this.toaster.error("ReportFeedback Already Exists or Another Error Occurred", "Failed");
        }
        this.spinnerService.raiseDataEmitterEvent('off');
      }
    );
  }


  public resetForm() {
    this.editReportFeedbackForm.reset();
  }


  ToparentFun() {
    this.showParent = false;
    this.parenFun.emit(this.showParent);

  }
}
