import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiModulesService } from 'src/app/all-modules/api-modules.service';
import { SpinnerService } from 'src/app/snipper/spinner.service';
@Component({
  selector: 'app-pf-form11',
  templateUrl: './pf-form11.component.html',
  styleUrls: ['./pf-form11.component.css']
})
export class PfForm11Component implements OnInit{
  @Input() tabUserPermissionData: any = {};
  isEditing = false;
  clientID = localStorage.getItem('client_id');
  companyID = localStorage.getItem('company_id');
  employeeID = localStorage.getItem('employee_id');
  form11Data=[];
  addForm11:FormGroup;
  form:FormGroup;
  mobileRegex = '^((\\+91-?)|0)?[0-9]{10,13}$';
  passportRegex='^[A-PR-WYa-pr-wy][1-9][0-9]{6}$';
  constructor(private spinnerService: SpinnerService, private apiModulesService: ApiModulesService,
    private toastr: ToastrService, private formBuilder: FormBuilder) { }
  ngOnInit() {
    this.spinnerService.raiseDataEmitterEvent('on');
  this.getForm11Data();

  this.addForm11 = this.formBuilder.group({
    father_name: new FormControl('', []),
    martial_status: ["", []],
    spouse_name: ["", []],
    mobile: ["", [Validators.pattern(this.mobileRegex)]],
    effective_to: ["", []],
    pf_number: ["", []],
    uan_number: ["", []],
    passport_no: new FormControl('', [
      Validators.pattern(this.passportRegex)
    ]),
    pan_no: new FormControl('', [
      Validators.pattern('^[A-Z]{5}[0-9]{4}[A-Z]{1}$')
    ]),
    pan_name: ["", []],
    aadhar_no: ["", []],
    aadhar_name: ["", []],
    passport_issue_date: ["", []],
    passport_expiry_date: ["", []],
    emp_bank_name: ["", []],
    bank_account_no: ["", []],
    bank_code: ["", []],
    nationality: ["", []],
    emp_eps_option: ["", []],
    employement_scheme_cert_no: ["", []],
    pf_maintained_by: ["", []],
    previous_pf_member_id: ["", []],
    establishment_code: ["", []],
    extension: ["", []],
    region_code: ["", []],
    office_code: ["", []],
    account_no: ["", []],
    emp_pf_scheme_no: ["", []],
    pension_payment_order_no: ["", []],
    international_worker: ["", []],
    passport_issuing_number: ["", []],
    estabish_name_address: ["", []],
    aadhar_remarks: ["", []],
    choose_pan:["",[]],
    choose_aad:["",[]],

  });

  }

  getForm11Data() {
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.clientID;
    params.company_id = this.companyID;
    params.employee_id = this.employeeID;
    this.apiModulesService.list('employee-tabs/IND-employee-payroll/form-11/list', params).subscribe((data) => {
      this.form11Data = data.data.pf_form11 != undefined ? data.data.pf_form11 : [];
      this.addForm11.patchValue({
        emp_eps_option:this.form11Data[0]?.eps_option,
        emp_pf_scheme_no:this.form11Data[0]?.emp_pf_scheme_no,
        pf_maintained_by:this.form11Data[0]?.pf_mainatained_by,
        international_worker:this.form11Data[0]?.international_worker
      })
      this.spinnerService.raiseDataEmitterEvent('off');

    },(err)=>{
      this.spinnerService.toasterError(err);

    });
  }
  onPhoneNumberInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const phoneNumber = inputElement.value.replace(/\D/g, ''); // Remove all non-digit characters
    const truncatedPhoneNumber = phoneNumber.slice(0, 10); // Truncate to 10 digits if longer
    inputElement.value = truncatedPhoneNumber; // Update the input field with the cleaned phone number
    this.addForm11.get('mobile').setValue(truncatedPhoneNumber); // Update the form control value
  }
  convertToUpperCase(event: Event): void {
    const input = event.target as HTMLInputElement;
    const value = input.value.toUpperCase();
    this.addForm11.get('pan_no')?.setValue(value, { emitEvent: false });
  }
  onPassportNumberInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    const value = input.value.toUpperCase();
    this.addForm11.get('passport_no')?.setValue(value, { emitEvent: false });
}

  addPfForm11() {
    if (!this.addForm11.valid) {
      this.addForm11.markAllAsTouched();
      return;
    }
    var params: any = {};
    params.company_id = this.companyID;
    params.client_id = this.clientID;
    params.employee_id = this.employeeID;
    params.employement_scheme_cert_no = this.addForm11.get('employement_scheme_cert_no').value;
    params.pf_mainatained_by = this.addForm11.get('pf_maintained_by').value;
    params.previous_pf_member_id = this.addForm11.get('previous_pf_member_id').value;
    params.establishment_code = this.addForm11.get('establishment_code').value;
    params.extension = this.addForm11.get('extension').value;
    params.region_code = this.addForm11.get('region_code').value;
    params.office_code = this.addForm11.get('office_code').value;
    params.account_no = this.addForm11.get('account_no').value;
    params.emp_pf_scheme_no = this.addForm11.get('emp_pf_scheme_no').value;
    params.pension_payment_order_no = this.addForm11.get('pension_payment_order_no').value;
    params.international_worker = this.addForm11.get('international_worker').value;
    params.passport_issuing_number = this.addForm11.get('passport_issuing_number').value;
    params.estabish_name_address = this.addForm11.get('estabish_name_address').value;
    params.aadhar_remarks = this.addForm11.get('aadhar_remarks').value;
    params.father_name = this.addForm11.get('father_name').value;
    params.martial_status = this.addForm11.get('martial_status').value;
    params.spouse_name = this.addForm11.get('spouse_name').value;
    params.mobile = this.addForm11.get('mobile').value;
    params.effective_to = this.addForm11.get('effective_to').value;
    params.pf_number = this.addForm11.get('pf_number').value;
    params.uan_number = this.addForm11.get('uan_number').value;
    params.passport_no = this.addForm11.get('passport_no').value;
    params.pan_no = this.addForm11.get('pan_no').value;
    params.pan_name = this.addForm11.get('pan_name').value;
    params.aadhar_no = this.addForm11.get('aadhar_no').value;
    params.aadhar_name = this.addForm11.get('aadhar_name').value;
    params.passport_issue_date = this.addForm11.get('passport_issue_date').value;
    params.passport_expiry_date = this.addForm11.get('passport_expiry_date').value;
    params.emp_bank_name = this.addForm11.get('emp_bank_name').value;
    params.bank_account_no = this.addForm11.get('bank_account_no').value;
    params.bank_code = this.addForm11.get('bank_code').value;
    params.nationality = this.addForm11.get('nationality').value;
    params.emp_eps_option = this.addForm11.get('emp_eps_option').value;
    params.input_document_type = this.addForm11.get('input_document_type')?.value;
    this.apiModulesService.add(params, "employee-tabs/IND-employee-payroll/form-11/add",).subscribe((data) => {
      this.toastr.success('PF Form 11 Added Sucessfully...!', 'Success');
      this.addForm11.reset();
        this.getForm11Data();
      }, (err) => {
      this.spinnerService.toasterError(err)
      });


  }
}
