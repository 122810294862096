import { Component, OnInit } from '@angular/core';
import { ApiModulesService } from 'src/app/all-modules/api-modules.service';
import { SpinnerService } from 'src/app/snipper/spinner.service';
@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {
  items: any[] = [];
  isLoading: boolean = true;
  clicked_item: any;
  clientID = localStorage.getItem('client_id');
  companyID = localStorage.getItem('company_id');
  employeeID = localStorage.getItem('employee_id');
  routes = [];
  cardBorderColors = [
    '25px 19px 27px -20px rgba(253,126,20,.25)', '25px 19px 27px -20px rgba(0,204,204,.25)', '25px 19px 27px -20px rgba(91,71,251,.2)', '25px 19px 27px -20px #fbfcd7', '25px 19px 27px -20px #d9b8f5'
  ]
  announcementData = [];
  imageSources = [
    'assets/img/Scenery1.jpg',
    'assets/img/Scenery2.jpg',
    'assets/img/Scenery3.jpg',
    'assets/img/Birthday1.jpg'
  ];
  userType = localStorage.getItem('user_type');
  constructor(private spinnerservice: SpinnerService, private apiModulesService: ApiModulesService,) { }
  ngOnInit(): void {
    this.spinnerservice.raiseDataEmitterEvent('on');
    this.employeeImageData()
  }
  toggleExpand(item: any) {
    item.isExpanded = !item.isExpanded;
  }
  employeeImageData() {
    this.spinnerservice.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.clientID;
    params.company_id = this.companyID;
    params.employee_id = this.employeeID;
    this.apiModulesService.list('employee-tabs/settings/home/list', params).subscribe((data) => {
      var employee_setting = data.data.employee_setting.personal_image[0] != undefined ? data.data.employee_setting.personal_image[0] : {};
      this.announcementData = data.data.employee_setting.announcement != undefined ? data.data.employee_setting.announcement : [];
      // this.announcementData.forEach((value, index) => {
      //   this.announcementData[index].image_path = value.image_path != undefined && value.image_path != null ? 'data:image/png;base64,' + value.image_path : 'assets/img/Scenery2.jpg';
      // })
      this.routes = data.data.employee_setting.routes != undefined ? data.data.employee_setting.routes : [];
      this.routes.forEach((value, index) => {
        this.routes[index].menu_image_link = value.menu_image_link != undefined && value.menu_image_link != null ? value.menu_image_link : 'assets/img/route-default-modified.png';
      })
      this.imageSources = [employee_setting.image_1 != undefined && employee_setting.image_1 != null ? 'data:image/png;base64,' + employee_setting.image_1 : 'assets/img/Scenery1.jpg',
      employee_setting.image_2 != undefined && employee_setting.image_2 != null ? 'data:image/png;base64,' + employee_setting.image_2 : 'assets/img/Scenery2.jpg',
      employee_setting.image_3 != undefined && employee_setting.image_3 != null ? 'data:image/png;base64,' + employee_setting.image_3 : 'assets/img/Scenery3.jpg',
      employee_setting.image_4 != undefined && employee_setting.image_4 != null ? 'data:image/png;base64,' + employee_setting.image_4 : 'assets/img/Birthday1.jpg'
      ]
      this.spinnerservice.raiseDataEmitterEvent('off');
    }, (err: any) => {
      this.spinnerservice.toasterError(err);
    })
  }
}
