import { Component, ViewChild, AfterViewInit, OnInit, ChangeDetectorRef } from '@angular/core';
import { ApiModulesService } from 'src/app/all-modules/api-modules.service';
import { FormArray, FormGroup, FormBuilder, Validators, NgForm, AbstractControl, ValidationErrors, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Subject, config, forkJoin } from 'rxjs';
import { InvestmentDeclarationService } from './investment-declaration.service';
import { SpinnerService } from '../../../../../snipper/spinner.service';
import { Table } from "primeng/table";
import { HRACalculationService } from "./HRA-calculation.service";
import { documentservice } from 'src/app/all-modules/documents.sevice';
import { copyArrayItem } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-investment-declaration',
  templateUrl: './investment-declaration.component.html',
  styleUrls: ['./investment-declaration.component.css']
})
export class InvestmentDeclarationComponent implements OnInit {
  @ViewChild('letoutproperty') letoutpropertyForm: NgForm;
  @ViewChild('selfproperty') selfpropertyForm: NgForm;
  @ViewChild('invdeclaration') invdeclarationForm: NgForm;
  @ViewChild('previousEmployer') previousEmployerForm: NgForm;
  countryID: any;
  country_id: any;
  emp_inv_dec_hra_id: any;
  ClientID = localStorage.getItem('client_id');
  CompanyID = localStorage.getItem('company_id');
  EmployeeID = localStorage.getItem('employee_id');
  invesDecId: any;
  invDecCategoryID: any;
  fileData: any;
  ShowProofForm = true;
  countryId: any;
  invesmentdeclarationData = [];
  lossofpropertyData = [];
  selfoccupiedpropertyData: any[] = [];
  letoutpropertyData: any[] = [];
  lossofoldnewregionData = [];
  isNewEmployee = 'false';
  componentvalueData = [];
  metroData = [];
  limitTotalInvestment: any
  limitInvestment: any
  limitsubInvestment = []
  Gross_Salary: any;
  self_occupied_property = false;
  let_out_property = false;
  selectAll = false;
  public totalRecords: any = 0;
  public statesList = [];
  public citiesList = [];
  radioInputIds = []
  selectedTaxTypeName: any
  emailRegex =
    '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
  mobileRegex = '^((\\+91-?)|0)?[0-9]{10,13}$';
  panRegex = "[A-Z]{5}[0-9]{4}[A-Z]{1}";
  nameBgColors: any = ['bg-blue-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim'];
  nameBorderColors: any = ['bg-outline-primary', 'bg-outline-success', 'bg-outline-info', 'bg-outline-gray', 'bg-outline-warning', 'bg-outline-dark', 'bg-outline-light', 'bg-outline-secondary', 'bg-outline-danger'];
  employeedeclarationData = [];
  declarationData = [];
  hraDetailsData = [];
  previousEmployerDetailsData: any = [];
  rentPanRequiredData = [];
  oldregimeValuesShow: any;
  oldregimeValuesUpdate: any;
  newregimeValuesShow: any;
  newregimeValuesUpdate: any;
  HraTotal: number;
  addEmployeedeclarationForm: FormGroup;
  addotherincomeForm: FormGroup;
  regimeSelected: boolean = false;
  isOldRegimeSelected: boolean = false;
  firstresidentialproperty = [
    {
      aliasName: 'Yes'
    },
    {
      aliasName: 'No'
    },
  ]
  filteredProducts: any;
  products: any;
  filter: any;
  statuses = [];
  loading: boolean = false;

  searchKeyword: string = '';
  lossofHouseProperty: FormGroup;
  showSelfOccupied: boolean = false;
  showLetOut: boolean = false;
  selectedTaxTypeId: any;
  radioParent = {};
  taxRegimeData: any;
  startDate: Date;
  endDate: Date;
  joiningDate: Date;
  frequency = [
    {
      selected_value: '1',
      selected_text: '1'
    },
    {
      selected_value: '3',
      selected_text: '3'
    },
    {
      selected_value: '6',
      selected_text: '6'
    },
    {
      selected_value: '12',
      selected_text: '12'
    },
  ]
  previousEmployeeData: any;
  selectedRegimeDetails:{tax_type_key:string,tax_type_name:string,tax_type_id:number}={tax_type_key:'',tax_type_name:'',tax_type_id:null};
  from_date: Date;
  to_date: Date;
  rentFinancialFromDate: Date;
  rentFinancialToDate: Date;
  lossofoldregimeData = 0;
  lossofnewregimeData =0;
  HousePropertyType: any;
  HousePropertyTypeSeqNo: any;
  self_occupied_property_value: any;
  let_out_property_value: any;
  otherIncomeData: any;
  showTable: string = 'yes';
  limitForSelfLopChange = 0;
  limitForSelfLopFixed = 0;
  SelfLopData: any;
  paraentInvestValue: any;
  radioButtonValue: any;
  totalTax: any;
  isChecked = false;
  public ShowForm: any = false;
  public showForm12BB: any = false;
  public sanitizedUrl: SafeResourceUrl;
  signedFilePath: any;
  formType: any = 'Form12BB';
  filePath: any;
  investmentProofData: any = [];
  investmentDosDontsData: any;
  policyForm: FormGroup;
  empinvestmentProofData: any;
  invProofFilePath: any;
  empinvestmentProofType: any;
  isDisabled: boolean = true;
  fileRow: any;
  fileName: '';
  fileNames: string[] = [];
  fileDataList: File[] = [];
  proofDetailsId: any;
  fileId: any;
  proofFileData: any;
  invDecCategoryKey: any;
  proofSupportingData: any;
  investmentParentNameData: any;
  public showProofStatus: any = false;
  public showProofFiles:boolean=false;
  public is_saved:boolean=false;
  public is_submitted:boolean=true;
  proofStatusData: any;
  fileCategoryKey: any;
  lastSelectedTab: string;
  constructor(private apiModulesService: ApiModulesService,
    private toastrService: ToastrService,
    private spinnerService: SpinnerService,
    private invesdecservice: InvestmentDeclarationService,
    private formBuilder: FormBuilder,
    private HRACalcution: HRACalculationService,
    private Documentservice: documentservice,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    private sanitizer: DomSanitizer,
    private fb: FormBuilder,
  ) {
  }
  dateRangeValidator(control: AbstractControl): ValidationErrors | null {
    const fromDate = control.get('from_date')?.value;
    const toDate = control.get('to_date')?.value;
    if (fromDate && toDate && fromDate >= toDate) {
      return { dateRangeInvalid: true };
    }
    return null;
  }
  ngOnInit(): void {
    this.spinnerService.raiseDataEmitterEvent('on');
    this.statuses = [
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ];
    const url = 'assets/img/FAQs';  // Replace with your desired URL
    this.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);

    this.calculateTaxRegime('submit');
    this.addEmployeedeclarationForm = this.formBuilder.group({
      from_date: ['', [Validators.required]],
      emp_inv_dec_hra_id: [''],
      to_date: ['', [Validators.required]],
      rent_per_month: ['', [Validators.required]],
      rent_period_in_months: ['', [Validators.required]],
      address_info: ['', [Validators.required]],
      state_id: ['', [Validators.required]],
      city_id: ['', [Validators.required]],
      post_code: ['', [Validators.required]],
      landlord_name: ['', [Validators.required]],
      landlord_pan: [''],
      address_info_2: [''],
      metro_city: ['', [Validators.required]],

    }, { validator: this.dateRangeValidator });
    this.addotherincomeForm = this.formBuilder.group({
      other_income_name: ['', [Validators.required]],
      other_income_id: [''],
      other_income_value: ['', [Validators.required]]
    });
    this.addEmployeedeclarationForm.get('from_date').valueChanges.subscribe(() => {
      this.calculateRentPeriodInMonths();
    });

    this.addEmployeedeclarationForm.get('to_date').valueChanges.subscribe(() => {
      this.calculateRentPeriodInMonths();
    });

    const selectedTaxTypeName = localStorage.getItem('selectedTaxTypeName');
    if (selectedTaxTypeName) {
      this.selectedTaxTypeName = selectedTaxTypeName;
    }
    this.policyForm = this.fb.group({
      policies: this.fb.array([]) // Initialize FormArray
    });

  }


  public MonthYearDropDown(inputDateString) {

    var date = new Date(inputDateString);
    var day: any = date.getDate();
    var month: any = date.getMonth() + 1; // Month is zero-based, so add 1
    var year: any = date.getFullYear();

    // Format day and month with leading zeros if needed
    day = (day < 10 ? '0' : '') + day;
    month = (month < 10 ? '0' : '') + month;

    // Format the date as 'DD/MM/YYYY'
    var formattedDate = day + '/' + month + '/' + year;
    // Output the formatted date
    return formattedDate;

  }


  onPhoneNumberInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const phoneNumber = inputElement.value.replace(/\D/g, ''); // Remove all non-digit characters
    const truncatedPhoneNumber = phoneNumber.slice(0, 10); // Truncate to 10 digits if longer
    inputElement.value = truncatedPhoneNumber; // Update the input field with the cleaned phone number
    this.addEmployeedeclarationForm.get('phone_number').setValue(truncatedPhoneNumber); // Update the form control value
  }
  // Get all companyformula data
  public getInvestmentDeclaration(item) {
    this.spinnerService.raiseDataEmitterEvent('on');
    var params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.employee_id = this.EmployeeID;
    params.key = item;
    this.apiModulesService.list('employee-tabs/IND-employee-payroll/investment-declaration/list', params).subscribe((data) => {
      this.employeedeclarationData = data.data.employee_declaration;
      this.isNewEmployee = this.employeedeclarationData[0].previous_emp_show;
      this.rentPanRequiredData = this.employeedeclarationData[0].pan_based_rent;
      this.selectedRegimeDetails.tax_type_key=this.employeedeclarationData[0].tax_type_key;
      this.selectedRegimeDetails.tax_type_id=this.employeedeclarationData[0].tax_type_id;
      this.selectedRegimeDetails.tax_type_name=this.employeedeclarationData[0].tax_type_name;
      this.regimeSelected = this.selectedRegimeDetails.tax_type_id != null ? true : false;
      this.declarationData = data.data.declaration;
      this.otherIncomeData = data.data.other_income_details
      this.invesmentdeclarationData = data.data.inv_declaration;
      // this.paraentInvestValue=data.data.inv_declaration.filter((val:any)=>val.parent_id==0)
      this.SelfLopData = data.data.self_lop;
      this.self_occupied_property_value = this.SelfLopData.filter((val: any) => val.property_key == 'self_occupied_property').map((map: any) => map.sum_value)[0]
      this.let_out_property_value = this.SelfLopData.filter((val: any) => val.property_key == 'let_out_property').map((map: any) => map.sum_value)[0]
      var SOLlimit = this.SelfLopData.filter((val: any) => val.property_key == 'sop_and_lop').map((map: any) => map.loss_of_property_value)[0]
      this.limitForSelfLopChange = SOLlimit;
      this.limitForSelfLopFixed = SOLlimit;
      var invesLimit = this.invesmentdeclarationData
        .filter((val) => val.parent_id == 0)
        .map((map) => {
          var obj = {};
          obj[map.inv_dec_category_id] = map.inv_limit;
          return obj;
        });
      var radioButtonValue = this.invesmentdeclarationData
        .filter((val) => val.parent_id == 0)
        .map((map) => {
          var obj = {};
          obj['parent_id'] = map.inv_dec_category_id;
          obj['value'] = map.inv_dec_values;
          return obj;
        });
      var invesChildLimit = this.invesmentdeclarationData
        .filter((val) => val.parent_id != 0)
        .map((map) => {
          var obj = {};
          obj['values'] = map.inv_dec_values;
          obj['inv_dec_category_id'] = map.inv_dec_category_id;
          obj['parent_id'] = map.parent_id;
          return obj;
        });
      function transformArrayToObject(array) {
        const result = {};
        for (let i = 0; i < array.length; i++) {
          const item = array[i];

          const key = Object.keys(item)[0];
          const value = parseInt(item[key]);

          result[key] = value;
        }
        return result;
      }

      var limit = transformArrayToObject(invesLimit);
      this.limitInvestment = transformArrayToObject(invesLimit);
      // radioButtonValue.forEach((val:any,key)=>{
      //   this.change(val.parent_id,val.value)
      // })
      this.limitsubInvestment = invesChildLimit;
      this.limitTotalInvestment = transformArrayToObject(invesLimit);
      this.lossofpropertyData = data.data.loss_of_property;
      this.selfoccupiedpropertyData = this.lossofpropertyData.filter(item => item.property_type === 'self_occupied_property');
      this.letoutpropertyData = this.lossofpropertyData.filter(item => item.property_type === 'let_out_property');
      this.lossofoldnewregionData = data.data.loss_of_oldnewregion;
      var tax_total_tax_payable = this.lossofoldnewregionData.filter((val: any) => val.property_key == 'tax_total_tax_payable');
      this.lossofoldregimeData = tax_total_tax_payable[0]?.old_regime_loss_of_property_value != undefined ? tax_total_tax_payable[0].old_regime_loss_of_property_value : 0;
      this.lossofnewregimeData = tax_total_tax_payable[0]?.new_regime_loss_of_property_value != undefined ? tax_total_tax_payable[0].new_regime_loss_of_property_value : 0;
      this.componentvalueData = data.data.component_values;
      this.metroData = data.data.metro_dropdown;
      this.taxRegimeData = data.data.tax_type;
      this.hraDetailsData = data.data.hra_details;
      this.previousEmployerDetailsData = data.data.previous_employer_details;
      this.invesDecId = this.employeedeclarationData[0].emp_inv_dec_id;
      this.countryId = this.employeedeclarationData[0].country_id;
      this.rentFinancialFromDate = new Date(this.employeedeclarationData[0].financial_from_date);
      this.rentFinancialToDate = new Date(this.employeedeclarationData[0].financial_to_date);
      this.cdr.detectChanges();
      // this.rentFinancialFromDate=this.employeedeclarationData[0].financial_from_date;
      // this.rentFinancialToDate=this.employeedeclarationData[0].financial_to_date;

      this.regimeCalculation();
      this.getStates();
      if (this.componentvalueData.length !== 0) {
        this.Filters('Gross_Salary', 'componentvalueData', 'component_key', 'Gross_Salary');
      }
      this.spinnerService.raiseDataEmitterEvent('off');
    }, (err: any) => {
      this.spinnerService.raiseDataEmitterEvent('off');
    });
  }

  Filters(filteredArray, mainArray, keyFilter, filterBasedValue) {
    this[filteredArray] = this[mainArray].filter((val: any) => val[keyFilter] === filterBasedValue);
  }

  validationChange(event, parentId, childId) {
    var calculatedValue = 0
    let promise = new Promise((resolve, reject) => {
      this.limitsubInvestment.forEach((value: any, key: any) => {
        if (value.inv_dec_category_id == childId && value.parent_id == parentId) {
          this.limitsubInvestment[key]['values'] = Number(event.target.value)
        }
        if (value.parent_id == parentId) {
          calculatedValue = calculatedValue + Number(value.values)
        }
      });
      resolve(calculatedValue)
      reject('no data')
    });
    promise.then((result) => {
      this.limitInvestment[parentId] = Number(this.limitTotalInvestment[parentId]) - Number(result);
    });

  }

  inputRadio(parent_id, value) {
    this.radioInputIds[parent_id] = value
  }
  change(parent_id, event_id) {
    // this.limitInvestment[parent_id]=event_id;
    var id = this.radioParent[parent_id] != undefined && this.radioParent[parent_id] != null ? this.radioParent[parent_id] : null
    this.invesmentdeclarationData.forEach((value, key) => {

      if (value.inv_dec_category_id == id) {
        this.invesmentdeclarationData[key]['inv_dec_values'] = 0;
      }
      if (value.inv_dec_category_id == event_id) {
        this.invesmentdeclarationData[key]['parent_value'] = event_id;
      }

    })
    this.radioParent[parent_id] = event_id;
  }
  textInputChange(radioInputKey, categoryId) {
    this.invesmentdeclarationData.forEach((value, key) => {

      if (value.inv_dec_category_id != categoryId && value.radio_input_key == radioInputKey) {
        this.invesmentdeclarationData[key]['inv_dec_values'] = 0;
      }


    })
  }
  LOPInputChange(event: any, property: any, propertyData: any[], loss_of_property_seq) {
    if (
      property.property_key === 'lop_rent_received_fin_year' ||
      property.property_key === 'lop_less_municipal_tax' ||
      property.property_key === 'lop_interest_borrowed_captital'

    ) {
      var filData = propertyData.filter((val: any) => val.loss_of_property_seq == loss_of_property_seq);
      this.calculateNetAnnualValue(filData, loss_of_property_seq);
    }
  }
  calculateNetAnnualValue(dataArray: any[], loss_of_property_seq) {
    let rentReceived = 0;
    let lessMunicipalTax = 0;
    let interestOnLoanForConstruction = 0;

    // Find and sum the values for lop_rent_received_fin_year and lop_less_municipal_tax
    for (let item of dataArray) {
      if (item.property_key === 'lop_rent_received_fin_year') {
        rentReceived += item.loss_of_property_value || 0;
      } else if (item.property_key === 'lop_less_municipal_tax') {
        lessMunicipalTax += item.loss_of_property_value || 0;
      } else if (item.property_key === 'lop_interest_borrowed_captital') {
        interestOnLoanForConstruction += item.loss_of_property_value || 0;
      }
    }

    // Calculate the net annual value
    const netAnnualValue = rentReceived - lessMunicipalTax;
    const repairsOf30Percentage = netAnnualValue * 0.3
    const netRentalIncome = netAnnualValue - repairsOf30Percentage
    const TaxablePropertyIncome = netRentalIncome - interestOnLoanForConstruction

    // Find the item with property_key = lop_net_annual_value and update its loss_of_property_value
    const netAnnualValueItem = this.letoutpropertyData.find(item => item.property_key === 'lop_net_annual_value' && item.loss_of_property_seq == loss_of_property_seq);
    const repairsOf30PercentageItem = this.letoutpropertyData.find(item => item.property_key === 'lop_less_repairs' && item.loss_of_property_seq == loss_of_property_seq);
    const TaxablePropertyIncomeItem = this.letoutpropertyData.find(item => item.property_key === 'lop_total_interest_by_me' && item.loss_of_property_seq == loss_of_property_seq);
    const netRentalIncomeItem = this.letoutpropertyData.find(item => item.property_key === 'lop_let_rental_income' && item.loss_of_property_seq == loss_of_property_seq);
    if (netAnnualValueItem) {
      netAnnualValueItem.loss_of_property_value = netAnnualValue;
      repairsOf30PercentageItem.loss_of_property_value = repairsOf30Percentage;
      TaxablePropertyIncomeItem.loss_of_property_value = TaxablePropertyIncome;
      netRentalIncomeItem.loss_of_property_value = netRentalIncome;
    }
  }

  public onAddRentDetails() {
    if (this.addEmployeedeclarationForm.invalid) {
      this.addEmployeedeclarationForm.markAllAsTouched();
      return;
    }
    var params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.employee_id = this.EmployeeID;
    params.emp_inv_dec_id = this.invesDecId;
    params.from_date = this.MonthYearDropDown(this.addEmployeedeclarationForm.value.from_date);;
    params.to_date = this.MonthYearDropDown(this.addEmployeedeclarationForm.value.to_date);
    params.rent_per_month = this.addEmployeedeclarationForm.value.rent_per_month;
    params.rent_period_in_months = this.addEmployeedeclarationForm.value.rent_period_in_months;
    params.address_info = this.addEmployeedeclarationForm.value.address_info;
    params.state_id = this.addEmployeedeclarationForm.value.state_id;
    params.city_id = this.addEmployeedeclarationForm.value.city_id;
    params.post_code = this.addEmployeedeclarationForm.value.post_code;
    params.landlord_name = this.addEmployeedeclarationForm.value.landlord_name;
    params.landlord_pan = this.addEmployeedeclarationForm.value.landlord_pan;
    params.address_info_2 = this.addEmployeedeclarationForm.value.address_info_2;
    params.metro_city = this.addEmployeedeclarationForm.value.metro_city;

    const emp_inv_dec_hra_id = this.addEmployeedeclarationForm.get('emp_inv_dec_hra_id').value;
    if (emp_inv_dec_hra_id != '' && emp_inv_dec_hra_id != null) {
      params.emp_inv_dec_hra_id = this.addEmployeedeclarationForm.value.emp_inv_dec_hra_id;
      this.apiModulesService.add(params, 'employee-tabs/IND-employee-payroll/investment-declaration/update-declaration',).subscribe((data) => {
        this.getHra();
        this.addEmployeedeclarationForm.reset();
        ($('#add_new_row') as any).modal('hide');

      }, (err) => {
        if (err.status === 437) {
          this.toastrService.error('Employeedeclaration Already Exists...!', 'Failed');
        } else {
          this.toasterError(err);
        }
      });
    } else {
      this.apiModulesService.add(params, 'employee-tabs/IND-employee-payroll/investment-declaration/add-declaration',).subscribe((data) => {
        this.getHra();
        this.addEmployeedeclarationForm.reset();
        ($('#add_new_row') as any).modal('hide');

      }, (err) => {
        if (err.status === 437) {
          this.toastrService.error('Employeedeclaration Already Exists...!', 'Failed');
        } else {
          this.toasterError(err);
        }
      });
    }

  }

  public onAddOtherIncome() {
    if (this.addotherincomeForm.invalid) {
      this.addotherincomeForm.markAllAsTouched();
      return;
    }
    var params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.employee_id = this.EmployeeID;
    params.emp_inv_dec_id = this.invesDecId;
    params.other_income_name = this.addotherincomeForm.value.other_income_name;
    params.other_income_value = this.addotherincomeForm.value.other_income_value;

    const other_income_id = this.addotherincomeForm.get('other_income_id').value;
    if (other_income_id != '' && other_income_id != null) {
      params.other_income_id = this.addotherincomeForm.value.other_income_id;
      this.apiModulesService.add(params, 'employee-tabs/IND-employee-payroll/investment-declaration/update-otherincome',).subscribe((data) => {
        this.getOtherIncome();
        this.addotherincomeForm.reset();
        ($('#add_other_income') as any).modal('hide');

      }, (err) => {
        if (err.status === 437) {
          this.toastrService.error('Employeedeclaration Already Exists...!', 'Failed');
        } else {
          this.toasterError(err);
        }
      });
    } else {
      this.apiModulesService.add(params, 'employee-tabs/IND-employee-payroll/investment-declaration/add-otherincome',).subscribe((data) => {

        this.getOtherIncome();
        this.addotherincomeForm.reset();
        ($('#add_other_income') as any).modal('hide');

      }, (err) => {
        if (err.status === 437) {
          this.toastrService.error('Employeedeclaration Already Exists...!', 'Failed');
        } else {
          this.toasterError(err);
        }
      });
    }

  }

  public getStates() {
    var params: any = {};
    params.country_id = this.countryId;
    this.apiModulesService.list('country/states', params).subscribe((data) => {
      this.statesList = (data.data.states !== undefined) ? data.data.states : [];
    });
  }

  public getCities() {
    const params: any = {};
    params.state_id = this.addEmployeedeclarationForm.get('state_id').value;
    this.apiModulesService.list('country/cities', params).subscribe((data) => {
      this.citiesList = (data.data.states !== undefined) ? data.data.states : [];
    });
  }
  clear(table: Table) {
    table.clear();
  }

  getSeverity(status: string) {
    switch (status.toLowerCase()) {
      case 'inactive':
        return 'danger';

      case 'active':
        return 'success';
    }
  }

  getAcronym(str: any) {
    var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
    matches = matches.slice(0, 3);
    var acronym = matches.join(''); // JSON
    return acronym;
  }

  getBgColors(str: any) {
    var min = 0;
    var max = 15;
    var index = this.findAlphapositions(str, 1);
    return this.nameBgColors[index];
  }

  findAlphapositions(str: any, n: any) {
    var NUM = 31;

    return str[0].charCodeAt(0) & NUM;
  }

  onEdit(value: any) {
    this.addEmployeedeclarationForm.setValue({
      from_date: new Date(value.from_date),
      emp_inv_dec_hra_id: value.emp_inv_dec_hra_id,
      to_date: new Date(value.to_date),
      rent_per_month: value.rent_per_month,
      rent_period_in_months: value.rent_period_in_months,
      address_info: value.address_info,
      state_id: value.state_id,
      city_id: value.city_id,
      post_code: value.post_code,
      landlord_name: value.landlord_name,
      landlord_pan: value.landlord_pan,
      address_info_2: value.address_info_2,
      metro_city: value.metro_city,
    });
    this.getCities();

  }
  editOtherIncome(value: any) {
    this.addotherincomeForm.setValue({
      other_income_id: value.other_income_id,
      other_income_name: value.other_income_name,
      other_income_value: value.other_income_value,
    });
  }


  invdecUpdate(item): Promise<any> {
    if (this.invdeclarationForm.invalid) {
      // invdeclaration.();

      return;
    }
    var params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.employee_id = this.EmployeeID;
    params.emp_inv_dec_id = this.invesDecId;
    params.key = item;
    params.inv_declaration = [this.invdeclarationForm.value];
    return new Promise((resolve, reject) => {
      // Your existing code here
      this.apiModulesService.edit(params, 'employee-tabs/IND-employee-payroll/investment-declaration/update-invdeclaration')
        .subscribe(data => {
          // Handle success
          resolve(data);
        }, err => {
          // Handle error
          reject(err);
          this.toasterError(err);
        });
    });
  }

  invlossofpropertyUpdate(invlossofproperty, item): Promise<any> {
    var params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.employee_id = this.EmployeeID;
    params.emp_inv_dec_id = this.invesDecId;
    params.key = item;
    if (invlossofproperty == 'letoutproperty') {
      params.inv_declaration = [this.letoutpropertyForm.value];

    } else if (invlossofproperty == 'selfproperty') {
      params.inv_declaration = [this.selfpropertyForm.value];
    }
    return new Promise((resolve, reject) => {
      // Your existing code here
      this.apiModulesService.edit(params, 'employee-tabs/IND-employee-payroll/investment-declaration/update-lossof-property')
        .subscribe(data => {
          // Handle success
          resolve(data);
        }, err => {
          // Handle error
          reject(err);
          this.toasterError(err);
        });
    });
  }



  private selfoccupied(event) {

    this.self_occupied_property = true;

  }

  private letoutoccupied(event) {

    this.let_out_property = true;
  }


  regimeCalculation() {
    const basic: number = filters('Basic', this.componentvalueData);
    const gross_salary: number = filters('Gross_Salary ', this.componentvalueData);

    function filters(filter_key, filterData): number {
      return filterData.filter((val: any) => val.component_key === filter_key).map((data: any) => data.component_value)[0]
    }
    let HraTotal = 0;
    if (this.declarationData.length != 0) {
      HraTotal = this.HRACalcution.calculateHra(this.declarationData, basic)
    }
    else {
      HraTotal = 0
    }

  }

  downloadExcel() {
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.employee_id = this.EmployeeID;
    this.apiModulesService.list('employee-tabs/IND-employee-payroll/investment-declaration/download-excel', params).subscribe((data) => {
      const fileDetails = data.data.inv_declaration.file;
      this.Documentservice.DownloadExcel(fileDetails.name, fileDetails.excel)
      this.spinnerService.raiseDataEmitterEvent('off');
    }, (err: any) => {
      let errors = err.error.data.inv_declaration;
      this.toasterError(err);
    });

  }


  calculateTaxRegime(item: string) {
    const params: any = {
      employee_id: this.EmployeeID,
      client_id: this.ClientID,
      company_id: this.CompanyID,
      property_key: 'yearly',
      key: item,
    };
    const taxCalculationObservable = this.apiModulesService.list('employee-tabs/IND-employee-payroll/tax-calculation/simulation_oldregime', params);
    const taxNewRegimeObservable = this.apiModulesService.list('employee-tabs/IND-employee-payroll/tax-calculation/simulation_newregime', params);
    forkJoin([taxCalculationObservable, taxNewRegimeObservable]).subscribe(
      ([taxData, taxNewRegimeData]) => {
        this.getInvestmentDeclaration(item);
      },
      error => {
        this.getInvestmentDeclaration(item);
      }
    );
  }
  updateTaxRegime(type) {
    const params: any = {};
    params.employee_id = this.EmployeeID;
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.emp_inv_dec_id = this.invesDecId;
    params.tax_type_id = this.selectedRegimeDetails.tax_type_id;

    this.apiModulesService.list('employee-tabs/IND-employee-payroll/investment-declaration/update-regime', params).subscribe((data) => {
      this.callFunctions(type,this.letoutpropertyForm, this.selfpropertyForm, this.invdeclarationForm, this.previousEmployerForm);
      ($('#regime-submit') as any).modal('hide');
    })
  }
  simulateTaxRegime(type){
    this.callFunctions(type,this.letoutpropertyForm, this.selfpropertyForm, this.invdeclarationForm, this.previousEmployerForm);
  }


  updatePreviousEmployer(item): Promise<any> {
    var params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.employee_id = this.EmployeeID;
    params.emp_inv_dec_id = this.invesDecId;
    params.inv_declaration = [this.previousEmployerForm.value];
    params.key = item;
    return new Promise((resolve, reject) => {
      this.apiModulesService.edit(params, 'employee-tabs/IND-employee-payroll/investment-declaration/update-previousemployer').subscribe((data) => {
        resolve(data);
      }, err => {
        reject(err);
        this.toasterError(err);
      });
    });
  }


  calculateRentPeriodInMonths() {
    const fromDate = this.addEmployeedeclarationForm.get('from_date').value;
    const toDate = this.addEmployeedeclarationForm.get('to_date').value;
    if (fromDate && toDate) {
      const startDate = new Date(fromDate);
      const endDate = new Date(toDate);
      const diffInMs = endDate.getTime() - startDate.getTime();
      const diffInMonths = Math.round(diffInMs / (1000 * 60 * 60 * 24 * 30));
      this.addEmployeedeclarationForm.patchValue({
        rent_period_in_months: diffInMonths
      });
    }
  }

  checkRentAndPan(): boolean {
    const rentPerMonth = this.addEmployeedeclarationForm.get('rent_per_month').value;
    const landlordPan = this.addEmployeedeclarationForm.get('landlord_pan').value;
    const rentPanRequired = this.rentPanRequiredData;
    if (rentPerMonth > rentPanRequired && (!landlordPan || landlordPan.trim() === '')) {
      return true;
    }
    return false;
  }



  showTaxRegime() {

    this.regimeSelected = true;
  }

  isInputEnabled(invDecCategoryId: number): boolean {
    return invDecCategoryId === this.invesmentdeclarationData[0].inv_dec_category_id;
  }


  // showRegimeSelection(regime: any) {
  //   this.selectedRegimeName = regime.tax_type_name;
  // }

  addHouseProperty(item: any) {
    var params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.employee_id = this.EmployeeID;
    params.emp_inv_dec_id = this.invesDecId;
    params.property_key = item
    this.apiModulesService.add(params, 'employee-tabs/IND-employee-payroll/investment-declaration/update-house-property',).subscribe((data) => {
      this.getHouseProperty(item);

    }, (err) => {
      if (err.status === 437) {
        this.toastrService.error('Employeedeclaration Already Exists...!', 'Failed');
      } else {
        this.toasterError(err);
      }
    });
  }
  deleteHouseProperty(item: any, num: any) {
    var params: any = {};

    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.employee_id = this.EmployeeID;
    params.emp_inv_dec_id = this.invesDecId;
    params.property_key = item;
    params.loss_of_property_seq = num;
    this.apiModulesService.add(params, 'employee-tabs/IND-employee-payroll/investment-declaration/delete-house-property',).subscribe((data) => {
      this.getHouseProperty(item);
      ($('#delete-status') as any).modal('hide');
    }, (err) => {
      if (err.status === 437) {
        this.toastrService.error('Employeedeclaration Already Exists...!', 'Failed');
      } else {
        this.toasterError(err);
      }
    });
  }
  callStatusModel(item: any, num: any) {
    this.HousePropertyType = item;
    this.HousePropertyTypeSeqNo = num;
  }
  // Utility function to mark all controls as touched
  markFormGroupTouched(formGroup: NgForm) {
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.controls[key];
      control.markAsTouched();
    });
  }
  async callFunctions(item: string, letoutpropertyForm: NgForm, selfpropertyForm: NgForm, invdeclarationForm: NgForm, previousEmployerForm: NgForm) {
    // Check if any of the forms are invalid
    if(this.selectedRegimeDetails.tax_type_key==='old_regime'){

    if (letoutpropertyForm.invalid || selfpropertyForm.invalid || invdeclarationForm.invalid || previousEmployerForm.invalid) {
      // Mark all controls as touched in each form to show validation errors
      this.markFormGroupTouched(letoutpropertyForm);
      this.markFormGroupTouched(selfpropertyForm);
      this.markFormGroupTouched(invdeclarationForm);
      this.markFormGroupTouched(previousEmployerForm);

      this.toastrService.error('Please fill in all fields correctly.');
      return;
    }
  }
    // Turn on the spinner
    this.spinnerService.raiseDataEmitterEvent('on');

    try {
      // Call your functions in parallel
      if(this.selectedRegimeDetails.tax_type_key==='old_regime'){
        const results = await Promise.all([
          this.functionName('invdecUpdate', '', item),
          this.functionName('updatePreviousEmployer', '', item),
          this.functionName('invlossofpropertyUpdate', 'selfproperty', item),
          this.functionName('invlossofpropertyUpdate', 'letoutproperty', item),
        ]);
      }


      await this.calculateTaxRegime(item);
      ($('#tax-submit') as any).modal('hide');
      // Show success notification
      this.toastrService.success('Investment Declaration updated Successfully...!', 'Success');
    } catch (err) {
      // Show error notifications based on error status
      if (err.status === 437) {
        this.toastrService.error('Investment Declaration Already Exists...!', 'Failed');
      } else {
        this.toasterError(err);
        this.toastrService.error('An unexpected error occurred. Please try again.', 'Error');
      }
      this.spinnerService.raiseDataEmitterEvent('off');

    } finally {
    }
  }

  toasterError(err) {
    let errors = err.error.data.inv_declaration;
    errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
    errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 18 for SQL Server][SQL Server]', 'Error: ')
    this.toastr.error(errors, 'TryAgain');
  }

  functionName(functionName, key, item: string): Promise<any> {
    // Return a promise for invdecUpdate
    return new Promise((resolve, reject) => {
      try {
        var result: any;
        if (key != '' && key != null) {
          result = this[functionName](key, item);
        } else {
          result = this[functionName](item);
        }

        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  }
  public getHouseProperty(item) {
    var params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.employee_id = this.EmployeeID;
    params.emp_inv_dec_id = this.invesDecId;
    params.property_key = item;
    this.apiModulesService.list('employee-tabs/IND-employee-payroll/investment-declaration/houseProperty-list', params).subscribe((data) => {
      if (params.property_key == 'self_occupied_property') {
        this.selfoccupiedpropertyData = data.data.inv_declaration

      } else if (params.property_key == 'let_out_property') {
        this.letoutpropertyData = data.data.inv_declaration

      } else if (params.property_key == 'all') {
        this.letoutpropertyData = data.data.inv_declaration
        this.selfoccupiedpropertyData = data.data.inv_declaration

      }
    }, (err: any) => {
      this.toasterError(err);
    });
  }
  public getHra() {
    var params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.employee_id = this.EmployeeID;
    params.emp_inv_dec_id = this.invesDecId;
    this.apiModulesService.list('employee-tabs/IND-employee-payroll/investment-declaration/hra_list', params).subscribe((data) => {
      this.declarationData = data.data.inv_declaration
    }, (err: any) => {
      this.toasterError(err);
    });
  }
  public getOtherIncome() {
    var params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.employee_id = this.EmployeeID;
    params.emp_inv_dec_id = this.invesDecId;
    this.apiModulesService.list('employee-tabs/IND-employee-payroll/investment-declaration/other_income_list', params).subscribe((data) => {
      this.otherIncomeData = data.data.inv_declaration
    }, (err: any) => {

      this.toasterError(err);
    });
  }
  public deleteOtherIncome(item) {
    var params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.employee_id = this.EmployeeID;
    params.emp_inv_dec_id = this.invesDecId;
    params.other_income_id = item.other_income_id;
    this.apiModulesService.list('employee-tabs/IND-employee-payroll/investment-declaration/delete_other_income', params).subscribe((data) => {
      this.getOtherIncome();
    }, (err: any) => {
      this.toasterError(err);
    });
  }
  public deleteHraDetails(item) {
    var params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.employee_id = this.EmployeeID;
    params.emp_inv_dec_id = this.invesDecId;
    params.emp_inv_dec_hra_id = item.emp_inv_dec_hra_id;
    this.apiModulesService.list('employee-tabs/IND-employee-payroll/investment-declaration/delete_hra_details', params).subscribe((data) => {
      this.getHra();
    }, (err: any) => {
      this.toasterError(err);
    });
  }
  regimeChange(item) {
    console.log(item);
    this.selectedRegimeDetails = item
  }
  form12BB() {
    this.ShowForm = true;
    this.showForm12BB = true;

  }
  goBack() {
    this.ShowForm = false;
    this.showForm12BB = false
  }
  onFileSelect(event: any): void {
    const file = event.target.files[0];
    if (file) {
      this.signedFilePath = file;
    }
  }
  public generateForm12BB() {
    this.spinnerService.raiseDataEmitterEvent('on');
    var params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.employee_id = this.EmployeeID;
    params.form_type_key = this.formType;
    params.emp_inv_dec_id = this.invesDecId;
    this.apiModulesService.list('employee-tabs/IND-employee-payroll/investment-declaration/form12bb', params).subscribe((data) => {
      this.spinnerService.raiseDataEmitterEvent('off');
    }, (err: any) => {
      this.spinnerService.raiseDataEmitterEvent('off');
    });
  }
  uploadSignedForm() {
    if (!this.signedFilePath) {
      this.toastr.error('Please select a file before uploading', 'Error');
      return;
    }
    this.spinnerService.raiseDataEmitterEvent('on');
    const myFormData = new FormData();
    myFormData.append('client_id', this.ClientID);
    myFormData.append('company_id', this.CompanyID);
    myFormData.append('employee_id', this.EmployeeID);
    myFormData.append('form_type_key', this.formType);
    myFormData.append('signed_file_path', this.signedFilePath);
    myFormData.append('emp_inv_dec_id', this.invesDecId);
    this.Documentservice.post('api/employee-tabs/IND-employee-payroll/investment-declaration/upload-form', myFormData).subscribe((data) => {
      this.toastr.success('Inv Dec Form Upload Sucessfully...!', 'Success');
      this.spinnerService.raiseDataEmitterEvent('off');
    },
      (err) => {
        this.spinnerService.toasterError(err);
      }
    );
  }
  getGenerateFile() {
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.employee_id = this.EmployeeID;
    params.emp_inv_dec_id = this.invesDecId;
    this.apiModulesService.list('employee-tabs/IND-employee-payroll/investment-declaration/generate-download', params).subscribe((data) => {
      const filePath = data?.data?.download_file?.[0]?.file_path ?? null;
      if (filePath != null) {
        this.Documentservice.DownloadExcel('Form12BB.pdf', filePath);
        this.toastr.success('Download Sucessfully...!', 'Success');
      } else {
        this.toastr.error('Download PDF Issue!', 'Error');
      }
      this.spinnerService.raiseDataEmitterEvent('off');
    },
      (err) => {
        this.spinnerService.toasterError(err);
        this.spinnerService.raiseDataEmitterEvent('off');
      }
    );
  }
  getSignedFile() {
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.employee_id = this.EmployeeID;
    params.emp_inv_dec_id = this.invesDecId;
    this.apiModulesService.list('employee-tabs/IND-employee-payroll/investment-declaration/signed-download', params).subscribe((data) => {
      const filePath = data?.data?.download_file?.[0]?.signed_file_path ?? null;
      if (filePath != null) {
        this.Documentservice.DownloadExcel('SignedForm12BB.pdf', filePath);
        this.toastr.success('Download Sucessfully...!', 'Success');
      } else {
        this.toastr.error('Download PDF Issue!', 'Error');
      }
      this.toastr.success('Download Sucessfully...!', 'Success');
      this.spinnerService.raiseDataEmitterEvent('off');
    },
      (err) => {
        this.spinnerService.toasterError(err);
        this.spinnerService.raiseDataEmitterEvent('off');
      }
    );
  }
  public invProofPopup(val: any, key: any) {
    this.empinvestmentProofData = [];
    this.PolicyResetForm();
    this.invDecCategoryKey = key;
    this.invDecCategoryID = val;
    this.showProofForm();
    this.spinnerService.raiseDataEmitterEvent('on');
    var params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.employee_id = this.EmployeeID;
    params.inv_dec_category_id = val;
    params.emp_inv_dec_id = this.invesDecId;
    params.inv_dec_category_key = key;
    this.apiModulesService.list('employee-tabs/IND-employee-payroll/investment-declaration/inv-proof', params).subscribe((data) => {
      this.investmentDosDontsData = data?.data?.dos_donts[0] != undefined ? data?.data?.dos_donts[0] : [];
      this.investmentParentNameData = data?.data?.parent_name[0] != undefined ? data?.data?.parent_name[0] : [];
      this.investmentProofData = data.data.inv_proof != undefined ? data?.data?.inv_proof : {};
      this.empinvestmentProofData = data?.data?.proof_details != undefined ? data?.data?.proof_details : [];
      this.proofSupportingData = data?.data?.proof_details[0]?.no_of_supporting_documents;
      this.empinvestmentProofType = this.empinvestmentProofData[0]?.proof_submittion_type;
      if (this.empinvestmentProofType === 'submit') {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
      if (this.empinvestmentProofData.length != 0) {
        this.editRow();
      } else {
        this.addPolicy();
      }
      setTimeout(() => {
          ($('#inv_proof') as any).modal('show');
      }, 100);

      this.spinnerService.raiseDataEmitterEvent('off');
    }, (err: any) => {
      this.spinnerService.raiseDataEmitterEvent('off');
    });
  }
  get policies(): FormArray {
    return this.policyForm.get('policies') as FormArray;
  }
  createEmptyPolicy(): FormGroup {
    const policyGroup = this.fb.group({
      emp_inv_proof_details_id: [''],
      file_path: [''],
      row_order: [this.policies.length]
    });
    this.investmentProofData.forEach(field => {
      policyGroup.addControl(field.inv_ud_field_key, this.fb.control(''));
    });
    return policyGroup;
  }
  addPolicy(): void {
    this.policies.push(this.createEmptyPolicy());
  }
  deletePolicy(index: number): void {
    if (this.policies.length > 1) {
      this.policies.removeAt(index);
    }
  }
  editRow(): void {
    this.empinvestmentProofData.forEach((record: any) => {
      const policy = this.fb.group({
        emp_inv_proof_details_id: [record.emp_inv_proof_details_id],
        file_path: [record.file_path],
        row_order: [record.row_order],
      });
      this.investmentProofData.forEach((field: any) => {
        policy.addControl(
          field.inv_ud_field_key,
          this.fb.control(record?.proof_details_json?.[field.inv_ud_field_key] ?? null)
        );
      });
      this.policies.push(policy);
    });
  }
  updateProof(value: any): void {
    const policyData = this.policyForm.value.policies.map((policy) => {
      const { emp_inv_proof_details_id, emp_inv_proof_file_id, row_order, ...proofDetailsJson } = policy;
      return {
        emp_inv_proof_details_id,
        emp_inv_proof_file_id,
        row_order,
        proof_details_json: proofDetailsJson
      };
    });
    var params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.employee_id = this.EmployeeID;
    params.inv_dec_category_id = this.invDecCategoryID;
    params.emp_inv_dec_id = this.invesDecId;
    params.proof_submission_type = value;
    params.inv_dec_category_key = this.invDecCategoryKey;
    params.policies = policyData;
    this.apiModulesService.list('employee-tabs/IND-employee-payroll/investment-declaration/update-proof', params).subscribe((data) => {
      this.invProofPopup(this.invDecCategoryID, this.invDecCategoryKey);
      this.toastr.success('Investment Proof updated successfully!', 'Success');
      ($('#proof-submission') as any).modal('hide');
    });
  }
  PolicyResetForm(): void {
    const policiesArray = this.policyForm.get('policies') as FormArray;
    policiesArray.clear();
    this.fileRow=[];
    this.fileNames=[];
    this.fileDataList=[];
  }
  showProofForm() {
    this.ShowProofForm = true;
  }

  hideProofForm() {
    this.ShowProofForm = false;
  }

  proofStatus() {
    this.ShowForm = true;
    this.showProofStatus = true;
    if (this.is_submitted === true && this.is_saved === false) {
      this.is_submitted = true;
      this.is_saved = false;
    } else {
      this.is_submitted = !this.is_submitted;
      this.is_saved = !this.is_saved;
    }
  }

  goPrevious() {
    this.ShowForm = false;
    this.showProofStatus = false;
  }

  showSubmitted() {
    this.is_submitted = true;
    this.is_saved = false;
  }

  showSaved() {
    this.is_submitted = false;
    this.is_saved = true;
  }

  handleFileUpload(event: any, index: number) {
    const file = event.target.files[0];
    if (file) {
      // this.fileNames[index] = file.name;
      this.fileDataList[index] = file;
    }
  }
  uploadImage(index: number, key: string) {
    const selectedRow = this.empinvestmentProofData[index];
    if (!this.fileDataList[index] || !this.fileNames[index]) {
      this.toastr.error('Please select a file and input before uploading', 'Error');
      return;
    }
    this.spinnerService.raiseDataEmitterEvent('on');
    const myFormData = new FormData();
    myFormData.append('client_id', this.ClientID);
    myFormData.append('company_id', this.CompanyID);
    myFormData.append('employee_id', this.EmployeeID);
    myFormData.append('emp_inv_dec_id', this.invesDecId);
    myFormData.append('inv_dec_category_id', this.invDecCategoryID);
    myFormData.append('inv_dec_category_key', key);
    myFormData.append('emp_inv_proof_details_id', selectedRow.emp_inv_proof_details_id);
    myFormData.append('file_name', this.fileNames[index]); // Use array value
    myFormData.append('file_path', this.fileDataList[index]); // Use array value
    this.Documentservice.post('api/employee-tabs/IND-employee-payroll/investment-declaration/upload-proof', myFormData)
      .subscribe((data) => {
        this.toastr.success('Investment Proof Document uploaded successfully!', 'Success');
        this.spinnerService.raiseDataEmitterEvent('off');
        this.fileDataList[index] = null;
        this.fileNames[index] = '';
        const fileInput = document.getElementById(`fileInput${index}`) as HTMLInputElement;
        if (fileInput) {
          fileInput.value = '';
        }
      },
      (error) => {
        this.toastr.error('Failed to upload document. Please try again.', 'Error');
        this.spinnerService.raiseDataEmitterEvent('off');
      });
  }
  showFiles(){
    this.showProofFiles=true;
  }
  hideFiles(){
    this.showProofFiles=false;
  }
  viewFile(value,key) {
    this.showFiles();
    this.fileRow=[];
    this.proofDetailsId = value;
    this.fileCategoryKey=key;
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.employee_id = this.EmployeeID;
    params.emp_inv_dec_id = this.invesDecId;
    params.inv_dec_category_id = this.invDecCategoryID;
    params.inv_dec_category_key = key;
    params.emp_inv_proof_details_id = this.proofDetailsId;
    this.apiModulesService.list('employee-tabs/IND-employee-payroll/investment-declaration/view-proof', params).subscribe((data) => {
      this.fileRow = data?.data?.proof_view_file ?? [];
      this.spinnerService.raiseDataEmitterEvent('off');
    },
      (err) => {
        this.spinnerService.toasterError(err);
        this.spinnerService.raiseDataEmitterEvent('off');
      }
    );
  }
  downloadFile(value) {
    this.fileId = value;
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.employee_id = this.EmployeeID;
    params.emp_inv_dec_id = this.invesDecId;
    params.inv_dec_category_id = this.invDecCategoryID;
    params.emp_inv_proof_details_id = this.proofDetailsId;
    params.emp_inv_proof_file_id = this.fileId;
    this.apiModulesService.list('employee-tabs/IND-employee-payroll/investment-declaration/download-proof', params).subscribe((data) => {
      const filePath = data?.data?.proof_download_file?.[0]?.file_path ?? null;
      if (filePath) {
        this.Documentservice.DownloadExcel('Investment Proof.pdf', filePath);
        this.toastr.success('Downloaded successfully!', 'Success');
      } else {
        this.toastr.error('Download PDF issue!', 'Error');
      }
      this.spinnerService.raiseDataEmitterEvent('off');
    },
      (err) => {
        this.spinnerService.toasterError(err);
        this.spinnerService.raiseDataEmitterEvent('off');
      }
    );
  }
  deleteFile(item) {
    this.fileId = item;
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.employee_id = this.EmployeeID;
    params.emp_inv_dec_id = this.invesDecId;
    params.inv_dec_category_id = this.invDecCategoryID;
    params.emp_inv_proof_details_id = this.proofDetailsId;
    params.emp_inv_proof_file_id = this.fileId;
    this.apiModulesService.list('employee-tabs/IND-employee-payroll/investment-declaration/delete-proof', params).subscribe((data) => {
      this.proofFileData = data?.data?.proof_delete_file ?? null;
      this.toastr.success('Deleted successfully!', 'Success');
      this.viewFile(this.proofDetailsId,this.fileCategoryKey);
      this.spinnerService.raiseDataEmitterEvent('off');
    },
      (err) => {
        this.spinnerService.toasterError(err);
        this.spinnerService.raiseDataEmitterEvent('off');
      }
    );
  }
  invEmpProofStatus(tab: string) {
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.employee_id = this.EmployeeID;
    params.emp_inv_dec_id = this.invesDecId;
    params.proof_submission_type = tab;
    this.apiModulesService.list('employee-tabs/IND-employee-payroll/investment-declaration/proof-status', params).subscribe((data) => {
      this.proofStatusData = data?.data?.emp_proof_status ?? null;
      this.spinnerService.raiseDataEmitterEvent('off');
    },
    (err) => {
      this.spinnerService.toasterError(err);
      this.spinnerService.raiseDataEmitterEvent('off');
    });
}


}
