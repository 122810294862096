import { Component, OnChanges, OnInit, ViewChild, SimpleChanges, AfterViewInit, OnDestroy } from '@angular/core';
import { ApiModulesService } from '../../api-modules.service';
import { FormGroup, FormBuilder, Validators, } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { Subject, config } from 'rxjs';
import { NgForm } from '@angular/forms';
import { NgFor } from '@angular/common';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { Table } from 'primeng/table';
import { CountryPayrollFormulaComponent } from '../country-payroll-formula/country-payroll-formula.component';

@Component({
  selector: 'app-paycomponentmaster',
  templateUrl: './paycomponentmaster.component.html',
  styleUrls: ['./paycomponentmaster.component.css']
})
export class PaycomponentmasterComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(CountryPayrollFormulaComponent) CountryPayroll: CountryPayrollFormulaComponent;

  paycomponentData:any;
  public addPaycomponentForm: FormGroup;
  countryname: any;
  componentname: any[];
  addpayComponent: any
  listHeaders = [];
  isDtInitialized = false;
  public frequencyname = [];
  public selected_country: any;
  editPaycomponentForm: any;
  public paycomponentDetail = [];
  public totalRecords: any = 0;
  nameRegex = /^[A-Za-z\s]+$/;
  public ShowForm: any = false;
  isFileShow = false;
  sortOrder: any;
  country_selected: any;
  frequency_id: any;
  is_mandatory: any;
  componentDropDown = [];
  frequencyDropDown = [];
  configValueDropDown = [];
  component_id: any;
  pay_frequency_id: any;
  country_id: any;
  component_type_id: any;
  component_key: any;
  component_shot: any;
  component_name: any;
  component_display_name: any;
  component_desc: any;
  default_values: any;
  config_values: any;
  configvalues: any;
  searchKeyword: string = '';
  loading = false;
  nameBgColors: any = ['bg-blue-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim'];
  nameBorderColors: any = ['bg-outline-primary', 'bg-outline-success', 'bg-outline-info', 'bg-outline-gray', 'bg-outline-warning', 'bg-outline-dark', 'bg-outline-light', 'bg-outline-secondary', 'bg-outline-danger'];
  statuses: { label: string; value: string; }[];
  statusApi: any;
  statusValues: any;
  slap_id: any;
  Formula_key: any;
  paycomponentlabelData: any;
  selected_component: any;
  arrear_component_id: any;
  month_component_id: any;
  ArrearComponentDropdown: any;
  constructor(private apiModuleService: ApiModulesService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder, private spinnerService: SpinnerService) { }

  ngOnInit(): void {

    this.getCountriesName();

    // Add
    this.addPaycomponentForm = this.formBuilder.group({
      country_id: ['', [Validators.required]],
      component_type_id: ['', [Validators.required]],
      component_key: ['', [Validators.required]],
      // component_shot: ['', [Validators.required,Validators.pattern(this.nameRegex)]],
      component_name: ['', [Validators.required, Validators.pattern(this.nameRegex)]],
      component_display_name: ['', [Validators.required, Validators.pattern(this.nameRegex)]],
      frequency_id: ['', [Validators.required]],
      // is_mandatory: ['', [Validators.required]],
      component_desc: ['', [Validators.required]],
      default_values: ['', [Validators.required]],
    });

    // Edit
    this.editPaycomponentForm = this.formBuilder.group({
      country_id: ['', [Validators.required]],
      component_type_id: ['', [Validators.required]],
      component_key: ['', [Validators.required]],
      // component_shot: ['', [Validators.required,Validators.pattern(this.nameRegex)]],
      component_name: ['', [Validators.required, Validators.pattern(this.nameRegex)]],
      component_display_name: ['', [Validators.required, Validators.pattern(this.nameRegex)]],
      frequency_id: ['', [Validators.required]],
      // is_mandatory: ['', [Validators.required]],
      component_desc: ['', [Validators.required]],
      default_values: ['', [Validators.required]],
      component_id: ['', []],
    });
    this.statuses = [
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ];
  }
  ngAfterViewInit(): void {

  }

  rerender(): void {

  }

  // Get all paycomponent data
  public getPaycomponent() {
    this.spinnerService.raiseDataEmitterEvent('on');
    var params: any = {};
    params.country_id = this.selected_country;

    this.apiModuleService.list('admin/paycomponent/list', params).subscribe((data) => {

      this.paycomponentData = (data.data.pay_component != undefined) ? data.data.pay_component : [];
      this.loading = false;
      var totalRecords = this.paycomponentData.length;
      this.totalRecords = totalRecords;
      this.resetForm();
      setTimeout(() => {
        this.spinnerService.raiseDataEmitterEvent('off');
      }, 100);
    }, (err) => {
      this.paycomponentData = [];
      this.spinnerService.raiseDataEmitterEvent('off');
      // this.rerender();
    });
  }
  clear(table: Table) {
    table.clear();
  }

  getSeverity(status: string) {
    switch (status.toLowerCase()) {
      case 'inactive':
        return 'danger';

      case 'active':
        return 'success';
    }
  }


  //Reset paycomponent
  public resetForm() {


    this.frequency_id = null;
    this.arrear_component_id = null;
    this.month_component_id = null;
    this.component_id = null;
    this.pay_frequency_id = null;
    this.country_id = null;
    this.component_type_id = null;
    this.component_key = null;
    this.component_shot = null;
    this.component_name = null;
    this.component_display_name = null;
    this.component_desc = null;
    this.default_values = null;
    this.config_values = [];
    this.configvalues = [];
  }

  //add paycomponent
  public onAddpaycomponent(addpayComponent: NgForm) {
    let config = {};

    for (let i = 0; i < this.configValueDropDown.length; i++) {
      config[this.configValueDropDown[i].ud_field_key] = (addpayComponent.value[i][this.configValueDropDown[i].ud_field_key] != undefined) ? addpayComponent.value[i][this.configValueDropDown[i].ud_field_key] : null;
    }

    var params: any = {};
    params.country_id = addpayComponent.value.country_id;
    params.component_type_id = addpayComponent.value.component_type_id;
    params.component_key = addpayComponent.value.component_key;
    // params.component_shot = addpayComponent.value.component_shot;
    params.component_name = addpayComponent.value.component_name
    params.component_display_name = addpayComponent.value.component_display_name;
    params.pay_frequency_id = addpayComponent.value.pay_frequency_id;
    // params.is_mandatory = addpayComponent.value.is_mandatory;
    params.component_desc = addpayComponent.value.component_desc;
    params.default_values = addpayComponent.value.default_values;
    params.config_values = config;
    params.arrear_component_id = addpayComponent.value.arrear_component_id;
    params.month_component_id = addpayComponent.value.month_component_id;


    this.apiModuleService.add(params, 'admin/paycomponent/add',).subscribe((data) => {
      this.toastrService.success('Paycomponent added successfully...!', 'Success');
      this.selected_country = addpayComponent.value.country_id;
      this.getPaycomponent();
      ($('#add_paycomponent') as any).modal('hide');
      addpayComponent.reset();
    }, (err) => {
      if (err.status === 437) {
        this.toastrService.error('Paycomponent Already Exists...!', 'Failed');
      } else {
        this.toastrService.error('Something Went Wrong Please Try Again...!', 'Error');
      }
    });
  }

  public getCountriesName() {
    this.apiModuleService.get('admin/paycomponent/dropdown').subscribe((data) => {
      this.countryname = data.data.pay_component;
      this.selected_country = this.countryname[0].country_id;
      this.getPaycomponent();

    });
  }


  public getDropdowncomponent() {
    var params: any = {}
    params.country_id = this.selected_country
    this.apiModuleService.list('admin/paycomponent/dropdowncomponent', params).subscribe((data) => {

      this.componentDropDown = data.data.pay_component[0];
      this.frequencyDropDown = data.data.pay_component[1];
      this.configValueDropDown = data.data.pay_component[2];
      this.ArrearComponentDropdown = data.data.pay_component[3];

    });
  }


  // Edit paycomponent
  public onEditPaycomponent(paycomponentId: any) {
    var params: any = {}
    params.country_id = this.selected_country
    this.apiModuleService.list('admin/paycomponent/dropdowncomponent', params).subscribe((data) => {

      this.componentDropDown = data.data.pay_component[0];
      this.frequencyDropDown = data.data.pay_component[1];
      this.configValueDropDown = data.data.pay_component[2];
      this.ArrearComponentDropdown = data.data.pay_component[3]; 
      var params: any = {}
      params.country_id = this.selected_country
      params.component_id = paycomponentId;

      this.apiModuleService.edit(params, 'admin/paycomponent/edit').subscribe((data) => {
        let paycomponentDetail = data.data.pay_component[0];
        this.component_id = paycomponentDetail.component_id;
        this.country_id = paycomponentDetail.country_id;
        this.component_type_id = paycomponentDetail.component_type_id;
        this.component_key = paycomponentDetail.component_key;
        // this.component_shot=paycomponentDetail.component_shot;
        this.arrear_component_id = paycomponentDetail.arrears_off;
        this.month_component_id = paycomponentDetail.monthly_component_id;

        this.component_name = paycomponentDetail.component_name;
        this.component_display_name = paycomponentDetail.component_name;
        this.pay_frequency_id = paycomponentDetail.pay_frequency_id;
        // this.is_mandatory=paycomponentDetail.is_mandatory;
        this.component_desc = paycomponentDetail.component_desc;
        this.default_values = paycomponentDetail.default_values;
        const length = this.configValueDropDown.length;
        for (let i = 0; i < length; i++) {
          let key_name = this.configValueDropDown[i].ud_field_key;

          this.configValueDropDown[i]['fields'] = paycomponentDetail.config_values[key_name];

        }
      });

    });
  }
  // edit paycomponent
  public onEditChanges(editpayComponent: NgForm) {
    let configvalue = {};

    for (let i = 0; i < this.configValueDropDown.length; i++) {
      configvalue[this.configValueDropDown[i].ud_field_key] = (editpayComponent.value[i][this.configValueDropDown[i].ud_field_key] != undefined) ? editpayComponent.value[i][this.configValueDropDown[i].ud_field_key] : null;
    }
    const params: any = {};
    params.component_id = this.component_id;
    params.country_id = editpayComponent.value.country_id;
    params.component_type_id = editpayComponent.value.component_type_id;
    // params.component_shot = editpayComponent.value.component_shot;
    params.component_name = editpayComponent.value.component_name
    params.component_display_name = editpayComponent.value.component_display_name;
    params.pay_frequency_id = editpayComponent.value.pay_frequency_id;
    // params.is_mandatory = editpayComponent.value.is_mandatory;
    params.component_desc = editpayComponent.value.component_desc;
    params.default_values = editpayComponent.value.default_values;
    params.config_values = configvalue;
    params.arrears_off = editpayComponent.value.arrear_component_id;
    params.month_component_id = editpayComponent.value.month_component_id;
    this.apiModuleService.edit(params, 'admin/paycomponent/update').subscribe((data) => {
      this.toastrService.success('Paycomponent Edited Sucessfully...!', 'Success');
      this.selected_country = editpayComponent.value.country_id;
      this.getPaycomponent();
      ($('#edit_paycomponent') as any).modal('hide');
      editpayComponent.reset();
    }, (err) => {
      if (err.status === 437) {
        this.toastrService.error('Paycomponent Already Exists...!', 'Failed');
      } else {
        this.toastrService.error('Something Went Wrong Please Try Again...!', 'Error');
      }
    });
  }

  // status change
  changeStatus(status: any, paycomponentId: any) {

    var params: any = {};
    params.component_id = paycomponentId;
    //
    this.apiModuleService.edit(params, 'admin/paycomponent/' + status).subscribe((data) => {
      this.getPaycomponent();
      ($('#paycomponent-status') as any).modal('hide');
      this.toastrService.success('Status Changed Sucessfully...!', 'Success');
    }, (err) => {
      this.toastrService.error('Something went wrong!', 'Try Again');
    });
  }
  ngOnDestroy() {

  }
  getAcronym(str: any) {
    var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
    matches = matches.slice(0, 2);
    var acronym = matches.join(''); // JSON
    return acronym;
  }
  getBgColors(str: any) {
    var min = 0;
    var max = 15;
    var index = this.findAlphapositions(str, 1);
    return this.nameBgColors[index];
  }
  findAlphapositions(str: any, n: any) {
    var NUM = 31;

    return str[0].charCodeAt(0) & NUM;
  }
  callStatusModel(url_name: any, data: any) {
    this.statusApi = url_name;
    this.statusValues = data;
  }

  showAddForm() {
    this.ShowForm = true;
  }
  showGrid() {
    this.ShowForm = false;
  }

  changeSortOrder() {
    this.paycomponentData.forEach((value,key)=>{
      this.paycomponentData[key]['sort_order']=key+1
      this.paycomponentData[key]['key_type']='country_components'
  })
    let params: any = {}
    params.country_id = this.selected_country;
    params.component_data = this.paycomponentData;
   
    this.spinnerService.raiseDataEmitterEvent('on');
    this.apiModuleService.list('admin/paycomponent/component-order', params).subscribe((data) => {
      ($('#order_key') as any).modal('hide');
      this.toastrService.success('Updated successfully..!', 'Success');

      this.spinnerService.raiseDataEmitterEvent('off');
    }, (err) => {
      this.spinnerService.raiseDataEmitterEvent('off');
      let errors = err.error.data.keysort;
      errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
      this.toastrService.error(errors, 'TryAgain');

    });
  }

  showDatePage(event) {
    this.isFileShow = false;
    this.getPaycomponent();
  }

  showFilePage(ID, CountryID, Formula_key) {
    this.component_id = ID;
    this.country_id = CountryID;
    this.Formula_key = Formula_key;
    this.isFileShow = true;
  }

}
