import { Component, Input, OnInit } from '@angular/core';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { ApiModulesService } from 'src/app/all-modules/api-modules.service';
import { ToastrService } from 'ngx-toastr';
import { documentservice } from 'src/app/all-modules/documents.sevice';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
@Component({
  selector: 'app-pfemployeeoptions',
  templateUrl: './pfemployeeoptions.component.html',
  styleUrls: ['./pfemployeeoptions.component.css']
})
export class PfemployeeoptionsComponent implements OnInit{
  selectedOption: string = '';
  selectedOption1: string='';
  employeeOptionData: any[];
  calculatedValues: any[];
  basicComponent: any;
  specialAllowanceComponent: any;
  basicValue: number;
  specialAllowanceValue: number;
  sum: any;
  result: string;
  clientID = localStorage.getItem('client_id');
  companyID = localStorage.getItem('company_id');
  employeeID = localStorage.getItem('employee_id');
  pfemployeeOptionData:any[] = [];
  selected_pf_employee_option: any;
  filedata: string | Blob;
  filedata1: string | Blob;
  epsemployeeOptionData: any;
  selected_eps_employee_option: any;
  url: string;
  urlSafe: SafeResourceUrl;
  selected_file_path_2014: any;
  selected_file_path_current_year: any;
  @Input() tabUserPermissionData: any = {};
  constructor( private spinnerService: SpinnerService,private apiModulesService: ApiModulesService,
    private toastrService: ToastrService,private documentService: documentservice,public sanitizer: DomSanitizer,
  ) {
  }
  ngOnInit() {
    this.spinnerService.raiseDataEmitterEvent('on');
    this.getList();

  }

  toggleTable(option: string) {
    this.selectedOption = option;
  }
  toggleTable1(option: string) {
    this.selectedOption1 = option;
  }
  getList() {
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.clientID;
    params.company_id = this.companyID;
    params.employee_id = this.employeeID;
    this.apiModulesService.list('employee-tabs/IND-employee-payroll/pfoptions/list', params).subscribe((data) => {
      this.employeeOptionData=data.data.pf_employee_option[0]
      this.pfemployeeOptionData=(data.data.pf_employee_option[1] != undefined) ? data.data.pf_employee_option[1]:[]
      this.epsemployeeOptionData=(data.data.pf_employee_option[2] != undefined) ? data.data.pf_employee_option[2]:[]
      if (this.pfemployeeOptionData.length != 0){
        this.selected_pf_employee_option = (this.pfemployeeOptionData[0].pf_employee_option != undefined) ? this.pfemployeeOptionData[0].pf_employee_option : [];
      }
      if (this.epsemployeeOptionData.length != 0){
        this.selected_eps_employee_option = (this.epsemployeeOptionData[0].eps_employee_option != undefined) ? this.epsemployeeOptionData[0].eps_employee_option : [];
        this.selected_file_path_2014 = (this.epsemployeeOptionData[0].file_path_2014 != undefined) ? this.epsemployeeOptionData[0].file_path_2014 : [];
        this.selected_file_path_current_year= (this.epsemployeeOptionData[0].file_path_current_year != undefined) ? this.epsemployeeOptionData[0].file_path_current_year : [];
      }
    this.calculatedValues = this.employeeOptionData.map(item => ({
        ...item,
        component_values: (parseFloat(item.component_values) * 12 / 100).toFixed(2)
    }));

  this.basicComponent = this.employeeOptionData.find(item => item.component_key === "Basic");
this.specialAllowanceComponent = this.employeeOptionData.find(item => item.component_key === "Special_allowance");

if (this.basicComponent && this.specialAllowanceComponent) {
  this.basicValue = parseFloat(this.basicComponent.component_values);
  this.specialAllowanceValue = parseFloat(this.specialAllowanceComponent.component_values);
  this.sum = this.basicValue + this.specialAllowanceValue;

    let result: number;
    if (this.sum >= 15000) {
        result = (15000 * 0.12);
    } else {
        result = (this.sum * 0.12);
    }

    this.result = result.toFixed(2);
} else {
}
this.spinnerService.raiseDataEmitterEvent('off');

},(err)=>{
  this.spinnerService.toasterError(err);

});
  }

  public onAddPf() {

    var params: any = {};
    params.client_id = this.clientID;
    params.company_id = this.companyID;
    params.employee_id = this.employeeID;

    if (this.selectedOption === 'select1') {
      params.pf_employee_option = '12%_on_basic';
      params.pf_amount = this.calculatedValues[0]?.component_values;
    } else if (this.selectedOption === 'select2') {
      params.pf_employee_option = '12%_on_15,000';
      params.pf_amount = this.result;
    }
      this.apiModulesService.add(params,'employee-tabs/IND-employee-payroll/pfoptions/create',).subscribe((data) => {
      this.getList();
      this.toastrService.success('pf added successfully...!', 'Success');
      ($('#save-pf') as any).modal('hide');
      }, (err:any) => {
        this.spinnerService.toasterError(err);      });
    }

    onAddClaim(item) {

      if(this.filedata1) {
        const myFormData = new FormData();
        myFormData.append('client_id', this.clientID);
        myFormData.append('company_id', this.companyID);
        myFormData.append('employee_id', this.employeeID);


      this.documentService.post('api/employee-tabs/IND-employee-payroll/pfoptions/upload', myFormData).subscribe((data) => {
        this.getList();
        this.toastrService.success('Successfully...!', 'Success');
      }, (err:any) => {
        this.spinnerService.toasterError(err);
         });

        if (this.selectedOption1 === 'selectYes') {
          myFormData.append('file_path_2014', this.filedata);
          myFormData.append('file_path_current_year', this.filedata1);
          myFormData.append('eps_employee_option', 'yes');
        } else if (this.selectedOption1 === 'selectNo') {
          myFormData.append('file_path_2014', null);
          myFormData.append('file_path_current_year', null);
          myFormData.append('eps_employee_option', 'no');
        }

        this.documentService.post('api/employee-tabs/IND-employee-payroll/pfoptions/upload', myFormData).subscribe((data) => {
          this.getList();
          this.toastrService.success('Successfully...!', 'Success');
          ($('#save-eps') as any).modal('hide');
        }, (err:any) => {
          this.spinnerService.toasterError(err);
               });
      } else {
        this.toastrService.error('File data is Empty Upload Document!', 'Error');
      }

    }

    fileEvent(e) {
      this.filedata = e.target.files[0];
    }
    fileEv(e) {
      this.filedata1 = e.target.files[0];
    }
    uploadViewFile(item){
      this.url = item != null ? 'data:application/pdf;base64,' + item : null;
      this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    }

  }

