import { Component } from '@angular/core';
import { ApiModulesService } from 'src/app/all-modules/api-modules.service';
import { SpinnerService } from 'src/app/snipper/spinner.service';

@Component({
  selector: 'app-payrollreports',
  templateUrl: './payrollreports.component.html',
  styleUrls: ['./payrollreports.component.css']
})
export class PayrollreportsComponent {
  menu_key = 'payroll_report';
  activeTab="";
  tabsData: any = [];
  tabPermission: any ={};
  isCreate = localStorage.getItem('is_create');
  isModify = localStorage.getItem('is_modify');
  isRemove = localStorage.getItem('is_remove');
    constructor( private spinnerService: SpinnerService,private apiModulesService:ApiModulesService){}


    ngOnInit()
    {
      this.spinnerService.raiseDataEmitterEvent('on');
      this.activeTabPermission();
     }

     changeTabs(activeTab){

      this.activeTab = activeTab;

     }
     activeTabPermission() {
      const params = { menu_key: 'payroll_report' };
      this.apiModulesService.add(params, 'tabs-permission').subscribe((response) => {
        this.tabsData = response?.data?.tabs ?? [];
        if (this.tabsData.length > 0) {
          this.activeTab = this.tabsData[0].tab_key;
          this.tabPermission = this.tabsData.find(
            (val: any) => val.tab_key === this.activeTab
          );
        }
      });
    }
}
