import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Table } from 'primeng/table';
import { ApiModulesService } from 'src/app/all-modules/api-modules.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {
notify=true
  @Output() closeNotification: EventEmitter<any> = new EventEmitter();
  notificationData=[];
  userType=localStorage.getItem('user_type')
  client_id=localStorage.getItem('client_id')
  constructor(private apiModulesService: ApiModulesService,private sanitizer: DomSanitizer){}
  public gridListView: boolean = true;
  cardBgColors = [
    'rgba(253,126,20,.25)', 'rgba(241,0,117,.25)', 'rgba(0,204,204,.25)', 'rgb(18,182,89,.25)', 'rgba(91,71,251,.2)'
  ]
  cardBorderColors = [
    '25px 19px 27px -20px rgba(253,126,20,.25)', '25px 19px 27px -20px rgba(0,204,204,.25)', '25px 19px 27px -20px rgba(91,71,251,.2)', '25px 19px 27px -20px #fbfcd7', '25px 19px 27px -20px #d9b8f5'
  ]
  nameBgColors: any = ['bg-blue-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim'];
  nameBorderColors: any = ['bg-outline-primary', 'bg-outline-success', 'bg-outline-info', 'bg-outline-gray', 'bg-outline-warning', 'bg-outline-dark', 'bg-outline-light', 'bg-outline-secondary', 'bg-outline-danger'];

  ngOnInit(){
    this.getUserNotification()

  }

  closeNotify(){
    this.notify=false;
    this.closeNotification.emit(this.notify)

  }
  changeTabsViewToGrid(value) {
  }
  showGrid() {
    this.gridListView = true;
  }
  showListview() {
    this.gridListView = false;
  }
  showGridView() {
    this.gridListView = true;
  }
  hideGridView() {
    this.gridListView = false;
  }
  activeGridOrCard() {
    // this.gridListView=this.claimReImburstment.gridListView

  }

  getBgColors(str: any) {
    const min = 0;
    const max = 15;
    const index = this.findAlphapositions(str, 1);
    return this.nameBgColors[index];
  }
  findAlphapositions(str: any, n: any) {
    const NUM = 31;

    return str[0].charCodeAt(0) & NUM;
  }
  clear(table: Table) {

    table.clear();
  }
  getSeverity(status: string) {
    switch (status.toLowerCase()) {
      case 'inactive':
        return 'danger';

      case 'active':
        return 'success';
    }
  }
  getAcronym(str: any) {
    let matches = str.match(/\b(\w)/g); // ['J','S','O','N']
    matches = matches.slice(0, 3);
    const acronym = matches.join(''); // JSON
    return acronym;
  }
  public getUserNotification() {
    this.apiModulesService.get("notification").subscribe((data) => {
    this.notificationData=data?.data?.notification

    }, (err: any) => {
      //  alert('something went wrong please try again');
      // window.location.reload()

    })
  }
  public updateNotificationStatus(notification_id){
    const params:any={}
    params.notification_id=notification_id;
    params.msg_status='read';
    params.client_id=this.client_id !=null || this.client_id!='' ? this.client_id:0;
    this.apiModulesService.add(params,"notification/status/update").subscribe((data) => {
      this.notificationData=data?.data?.notification

      }, (err: any) => {
        //  alert('something went wrong please try again');
        // window.location.reload()

      })
  }
  getSafeImageUrl(url: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
}
