import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AllModulesService } from '../../all-modules.service';
import { FormGroup, FormBuilder, Validators, NgForm } from "@angular/forms";
import { ApiModulesService } from '../../api-modules.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { SpinnerService } from 'src/app/snipper/spinner.service';
import { Subject } from 'rxjs';
import { File } from 'filepond';
import { Table } from 'primeng/table';
import { documentservice } from "../../documents.sevice";


declare const $: any;

@Component({
  selector: 'app-menus',
  templateUrl: './menus.component.html',
  styleUrls: ['./menus.component.css']
})
export class MenusComponent implements OnInit, OnDestroy, AfterViewInit {

  public menusData = [];
  public listHeaders = [];
  addMenusForm: FormGroup;
  parentMenuDropDown: any;
  editMenusForm: FormGroup;
  filedata: any = [];
  searchKeyword: string = '';
  moduleDropDown: any;
  rows: any;
  search: any;
  menuobjectsData: any;
  choose_menu_id: any;
  addMenuobjectsForm: FormGroup;
  addPageTabForm: FormGroup;
  editPageTabForm: FormGroup;
  choose_menu_name: any;
  loading = false;
  menu_id: any;
  public pageNo = 1;
  public limit = 10;
  public totalRecords: any = 0;
  nameBgColors: any = ["bg-blue-dim", "bg-blue-dim", "bg-azure-dim", "bg-indigo-dim", "bg-info-dim", "bg-purple-dim", "bg-pink-dim", "bg-orange-dim", "bg-teal-dim", "bg-primary-dim", "bg-success-dim", "bg-warning-dim", "bg-danger-dim", "bg-secondary-dim", "bg-dark-dim", "bg-gray-dim", "bg-blue-dim", "bg-azure-dim", "bg-indigo-dim", "bg-info-dim", "bg-purple-dim", "bg-pink-dim", "bg-orange-dim", "bg-teal-dim", "bg-primary-dim", "bg-success-dim", "bg-warning-dim", "bg-danger-dim", "bg-secondary-dim", "bg-dark-dim", "bg-gray-dim"];
  nameBorderColors: any = ["bg-outline-primary", "bg-outline-success", "bg-outline-info", "bg-outline-gray", "bg-outline-warning", "bg-outline-dark", "bg-outline-light", "bg-outline-secondary", "bg-outline-danger"];

  statuses: any;
  statusApi: any;
  statusValues: any;
  pageTabName: any;
  ShowPageGrid = true;
  isAdding = false;
  isEditing = false;
  menuTabId: any;
  pageTabeId: any;
  constructor(private apiModulesService: ApiModulesService,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private http: HttpClient,
    private documentService: documentservice,
    private spinnerService: SpinnerService
  ) { }

  ngOnInit(): void {
    this.spinnerService.raiseDataEmitterEvent('on');
    const that = this;
    that.getMenus();
    this.getDropdowm();

    this.addMenuobjectsForm = this.formBuilder.group({
      items: this.formBuilder.array([]),

    });

    this.statuses = [
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ];
    //Add
    this.addMenusForm = this.formBuilder.group({
      menu_title: ["", [Validators.required]],
      menu_name: ["", [Validators.required]],
      menu_description: ["", [Validators.required]],
      menu_link: ["", [Validators.required]],
      menu_image: ["", [Validators.required]],
      parent_menu_id: ["", []],
      menu_image_link: ["", []],
      module_id: ["", [Validators.required]],
      menu_key: ["", [Validators.required]],
    });

    //edit
    this.editMenusForm = this.formBuilder.group({
      menu_id: ["", []],
      menu_title: ["", [Validators.required]],
      menu_name: ["", [Validators.required]],
      menu_description: ["", [Validators.required]],
      menu_link: ["", [Validators.required]],
      menu_image: ["", [Validators.required]],
      parent_menu_id: ["", [Validators.required]],
      module_id: ["", [Validators.required]],
      old_path: ["", []],
      menu_image_link: ["", []],
      module_name: ["", []],
      menu_key: ["", [Validators.required]]
    });

    // //Add
    this.addMenuobjectsForm = this.formBuilder.group({
      menu_object_id: ["", []],
      menu_id: ["", []],
      menu_object_route: ["", [Validators.required]],
      menu_object_method_name: ["", [Validators.required]],
      method_type: ["", [Validators.required]],
      access_type: ["", [Validators.required]]
    });

    this.addPageTabForm = this.formBuilder.group({
      tab_name: ["", [Validators.required]],
      tab_key: ["", [Validators.required]],
      tab_order: ["", [Validators.required]],

    });
    this.editPageTabForm = this.formBuilder.group({
      tab_name: ["", [Validators.required]],
      tab_key: ["", [Validators.required]],
      tab_order: ["", [Validators.required]],

    });
  }

  ngAfterViewInit(): void {
  }

  //  //for adding rows
  //  addItems(f: NgForm) {
  //   this.itemsList.push(this.newItem());
  // }

  //getmenus
  public getMenus() {
    this.spinnerService.raiseDataEmitterEvent('on');

    var params: any = {};
    params.page_no = this.pageNo;
    params.records = this.limit;
    this.apiModulesService.list("admin/menus/list", params).subscribe((data) => {

      this.menusData = (data.data.menus != undefined) ? data.data.menus : [];
      this.loading = false;
      var totalRecords = (this.menusData[0] != undefined) ? this.menusData.length : 0;
      this.totalRecords = totalRecords;
      this.resetform();
      this.spinnerService.raiseDataEmitterEvent('off');
    }, (err) => {
      this.menusData = [];
      this.spinnerService.raiseDataEmitterEvent('off');
      let errors = err.error.data.menus;
      errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
      this.toastrService.error(errors, 'TryAgain');

    });
  }

  //add reset
  public resetform() {
    this.addMenusForm.reset();
    this.addPageTabForm.reset();
  }

  clear(table: Table) {
    table.clear();
  }

  getSeverity(status: string) {
    switch (status.toLowerCase()) {
      case 'inactive':
        return 'danger';

      case 'active':
        return 'success';
    }
  }




  //Add Documents
  public onAddmenus() {
    if (!this.addMenusForm.valid) {
      this.addMenusForm.markAllAsTouched();
      return;
    }
    var myFormData = new FormData();
    myFormData.append('menu_title', this.addMenusForm.value.menu_title);
    myFormData.append('menu_name', this.addMenusForm.value.menu_name);
    myFormData.append('menu_image', this.addMenusForm.value.menu_image);
    myFormData.append('menu_description', this.addMenusForm.value.menu_description);
    myFormData.append('menu_link', this.addMenusForm.value.menu_link);
    myFormData.append('parent_menu_id', this.addMenusForm.value.parent_menu_id);
    myFormData.append('module_id', this.addMenusForm.value.module_id);
    myFormData.append('menu_key', this.addMenusForm.value.menu_key);
    myFormData.append('image_path', this.filedata);

    //   if (this.filedata != null && this.filedata != '') {
    // }


    this.documentService.post('api/admin/menus/add', myFormData).subscribe((data) => {
      this.getMenus();

      this.addMenusForm.reset();
      this.filedata = null;
      ($("#add_menus") as any).modal("hide");
      this.toastrService.success('Successfully Add menus!', 'Success');
      /* Image Post Request */
      this.filedata = [];
      // this.url = []
      this.spinnerService.raiseDataEmitterEvent('off');

    }, (err) => {
      let errors = err.error.data.menus;
      errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
      this.toastrService.error(errors, 'TryAgain');
      this.spinnerService.raiseDataEmitterEvent('off');

    });
  }
  /* File onchange event */
  fileEvent(e) {
    this.filedata = e.target.files[0];

  }


  //Edit paycomponent
  public onEditMenus(menuId: any) {

    var params: any = {};
    params.menu_id = menuId;
    this.apiModulesService.edit(params, "admin/menus/edit").subscribe((data) => {
      var menusDetail = data.data.menus;
      this.editMenusForm.setValue({
        menu_id: menusDetail.menu_id,
        menu_title: menusDetail.menu_title,
        menu_name: menusDetail.menu_name,
        menu_description: menusDetail.menu_description,
        menu_link: menusDetail.menu_link,
        menu_image: menusDetail.menu_image,
        parent_menu_id: menusDetail.parent_menu_id,
        module_id: menusDetail.module_id,
        menu_image_link: null,
        old_path: menusDetail.menu_image_link,
        module_name: menusDetail.module_name,
        menu_key: menusDetail.menu_key


      });
    });
  }

  //edit paycomponent
  public onEditChanges() {

    if (!this.editMenusForm.valid) {
      this.editMenusForm.markAllAsTouched();
      return;
    }
    var myFormData = new FormData();
    myFormData.append('menu_title', this.editMenusForm.value.menu_title);
    myFormData.append('menu_name', this.editMenusForm.value.menu_name);
    myFormData.append('menu_id', this.editMenusForm.value.menu_id);
    myFormData.append('menu_image', this.editMenusForm.value.menu_image);
    myFormData.append('menu_description', this.editMenusForm.value.menu_description);
    myFormData.append('menu_link', this.editMenusForm.value.menu_link);
    myFormData.append('parent_menu_id', this.editMenusForm.value.parent_menu_id);
    myFormData.append('module_id', this.editMenusForm.value.module_id);
    myFormData.append('menu_key', this.editMenusForm.value.menu_key);
    myFormData.append('old_path', this.editMenusForm.value.old_path);
    myFormData.append('image_path', this.filedata);

    //   if (this.filedata != null && this.filedata != '') {
    // }


    this.documentService.post('api/admin/menus/update', myFormData).subscribe((data) => {
      this.getMenus();

      this.editMenusForm.reset();
      this.filedata = null;
      ($("#edit_menus") as any).modal("hide");
      this.toastrService.success('Successfully update menus!', 'Success');
      /* Image Post Request */
      this.filedata = [];
      // this.url = []
      this.spinnerService.raiseDataEmitterEvent('off');

    }, (err) => {
      let errors = err.error.data.menus;
      errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ')
      this.toastrService.error(errors, 'TryAgain');
      this.spinnerService.raiseDataEmitterEvent('off');

    });

  }






  getDropdowm() {
    var params: any = {};
    params.login_user_id = 1;
    this.apiModulesService.list("admin/menus/dropdown", params).subscribe((data) => {
      this.parentMenuDropDown = data.data.menus[0];
      this.moduleDropDown = data.data.menus[1];
    });
  }

  //search by Country Name
  searchName(name) {
    this.rows.splice(0, this.rows.length);
    let temp = this.search.filter(function (d) {
      name = name.toLowerCase();
      return d.menu_name.toLowerCase().indexOf(name) !== -1 ||
        d.module_id.toLowerCase().indexOf(name) !== -1 ||
        d.menu_id.toLowerCase().indexOf(name) !== -1;

    });
    this.rows.push(...temp);
  }
  delete(i) {
    this.menuobjectsData.removeAt(i)
  }
  changeStatus(Status: any, menu_id: any) {
    let params: any = {};
    params.menu_id = menu_id,
      this.apiModulesService.edit(params, 'admin/menus/' + Status).subscribe((data) => {
        this.getMenus();
        ($('#menus-status') as any).modal('hide');
        this.toastrService.success('Status Changed Successfully!', 'Success');
      })
  }



  getMenusObjects(menu_id: any, menu_name) {
    var params: any = {};

    params.login_user_id = 1;
    params.menu_id = menu_id;
    this.apiModulesService.list("admin/menus_objects/list", params).subscribe((data) => {
      this.menuobjectsData = data;
      this.choose_menu_id = menu_id;
      this.choose_menu_name = menu_name;
    });
  }

  public onAddmenuobjects(f: NgForm) {
    var params: any = {};
    params.menu_id = this.choose_menu_id;
    params.login_user_id = 1;
    params.menuobjects = f.value;
    this.apiModulesService.add(params, "admin/menus_objects/add").subscribe((data) => {
      ($("#add_menu_objects") as any).modal("hide");

      this.toastrService.success("Menuobjects Added Sucessfully...!", "Success");
    });
  }
  public editValue(
    menuobjectsDetail: any) {

    this.addMenuobjectsForm.setValue({
      menu_object_id: menuobjectsDetail.menu_object_id,
      menu_id: menuobjectsDetail.menu_id,
      menu_object_route: menuobjectsDetail.menu_object_route,
      menu_object_method_name: menuobjectsDetail.menu_object_method_name,
      method_type: menuobjectsDetail.method_type,
      access_type: menuobjectsDetail.access_type,
    });

  };

  onSubmit(f: NgForm) {
  }
  menusplus() {

    this.menuobjectsData.push(
      [this.menuobjectsData.menu_object_route = '',
      this.menuobjectsData.menu_object_method_name = '',
      this.menuobjectsData.method_type = '',
      this.menuobjectsData.access_type = '',
      this.menuobjectsData.menu_id = this.choose_menu_id,
      this.menuobjectsData.menu_object_id = undefined,
      ]);
  }

  menusdelete(deleteme) {

    this.menuobjectsData.splice(deleteme, 1)
  }
  pageTabMenuData(menu_id: any) {
    this.ShowPageGrid = true;
    this.isAdding = false;
    this.spinnerService.raiseDataEmitterEvent('on');
    this.menuTabId = menu_id;
    const params: any = {};
    params.menu_tab_id = menu_id;
    this.apiModulesService.list("admin/menus/tab-list", params).subscribe((data) => {
      this.pageTabName = data.data.menus != undefined ? data.data.menus : [];
      this.spinnerService.raiseDataEmitterEvent('off');
    }, (err: any) => {
      this.spinnerService.toasterError(err);
    })
  }
  showAddForm() {
    this.isAdding = true;
    this.isEditing = false;
    this.ShowPageGrid = false;
  }

  hideAddForm() {
    this.isAdding = false;
    this.ShowPageGrid = true;
  }
  showEditForm() {
    this.isEditing = true;
    this.isAdding = false;
    this.ShowPageGrid = false;
  }

  hideEditForm() {
    this.isEditing = false;
    this.ShowPageGrid = true;
  }
  addPageTab() {
    if (!this.addPageTabForm.valid) {
      this.addPageTabForm.markAllAsTouched();
      return;
    }
    var params: any = {};
    params.menu_tab_id = this.menuTabId;
    params.tab_name = this.addPageTabForm.get('tab_name')?.value;
    params.tab_key = this.addPageTabForm.get('tab_key')?.value;
    params.tab_order = this.addPageTabForm.get('tab_order')?.value;
    this.apiModulesService.add(params, 'admin/menus/add-tab').subscribe(
      (data) => {
        this.toastrService.success('Page Tab Added Successfully!', 'Success');
        this.addPageTabForm.reset();
        this.pageTabMenuData(this.menuTabId);
        this.ShowPageGrid = true;
        this.isAdding = false;
      },
      (err) => {
        this.spinnerService.toasterError(err);
      }
    );
  }

  public editpageTab(tabId: any) {
    this.pageTabeId = tabId;
    var params: any = {};
    params.page_tab_id = tabId;
    this.apiModulesService.edit(params, "admin/menus/edit-tab").subscribe((data) => {
      if (data.data && data.data.menus) {
        var tabDetail = data.data.menus;
        this.editPageTabForm.setValue({
            tab_name: tabDetail.tab_name || '',
            tab_key: tabDetail.tab_key || '',
            tab_order: tabDetail.tab_order || ''
        });
    }

    });
  }

  updatePageTab() {
    this.ShowPageGrid = false;
    this.isEditing = true;
    if (!this.editPageTabForm.valid) {
      this.editPageTabForm.markAllAsTouched();
      return;
    }
    var params: any = {};
    params.menu_tab_id = this.menuTabId;
    params.tab_name = this.editPageTabForm.value.tab_name;
    params.tab_key = this.editPageTabForm.value.tab_key;
    params.tab_order = this.editPageTabForm.value.tab_order;
    params.page_tab_id = this.pageTabeId;
    this.apiModulesService.add(params, 'admin/menus/update-tab').subscribe(
      (data) => {
        this.toastrService.success('Page Tab Updated Successfully!', 'Success');
        this.editPageTabForm.reset();
        this.pageTabMenuData(this.menuTabId);
        this.ShowPageGrid = true;
        this.isEditing = false;
      },
      (err) => {
        this.spinnerService.toasterError(err);
      }
    );
  }
  changeTabStatus(Status: any,page_tab_id:any) {
    let params: any = {};
    params.page_tab_id = page_tab_id,
      this.apiModulesService.edit(params, 'admin/menus/' + Status).subscribe((data) => {
        this.pageTabMenuData(this.menuTabId);
        ($('#tab-status') as any).modal('hide');
        this.toastrService.success('Status Changed Successfully!', 'Success');
      })
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event

  }
  getAcronym(str: any) {
    var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
    matches = matches.slice(0, 3);
    var acronym = matches.join(''); // JSON
    return acronym;
  }
  getBgColors(str: any) {
    var min = 0;
    var max = 15;
    var index = this.findAlphapositions(str, 1);
    return this.nameBgColors[index];
  }
  findAlphapositions(str: any, n: any) {
    var NUM = 31;

    return str[0].charCodeAt(0) & NUM;
  }
  callStatusModel(url_name: any, data: any, actionName: string) {
    this.statusApi = url_name;
    this.statusValues = data;
  }
}
