import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { ApiModulesService } from '../../api-modules.service';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-notify-content',
  templateUrl: './notify-content.component.html',
  styleUrls: ['./notify-content.component.css']
})
export class NotifyContentComponent {
  public addNotificationForm: FormGroup;
  countryId: any;
  CountryData: any = [];
  MenuData: any = [];
  TabData: any = [];
  NotificationContent: any = [];
  nameBgColors: any = ['bg-blue-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim'];
  nameBorderColors: any = ['bg-outline-primary', 'bg-outline-success', 'bg-outline-info', 'bg-outline-gray', 'bg-outline-warning', 'bg-outline-dark', 'bg-outline-light', 'bg-outline-secondary', 'bg-outline-danger'];
  statuses: { label: string; value: string; }[];
  statusApi: any;
  statusValues: any;
  totalRecords: any = 0;
  constructor(private toastr: ToastrService, private formBuilder: FormBuilder, private apiModulesService: ApiModulesService, private http: HttpClient, private spinnerService: SpinnerService) { }


  ngOnInit(): void {

    this.getCountriesName();
    // addForm Validations
    this.getMenuData();

    this.addNotificationForm = this.formBuilder.group(
      {
        section: ['', [Validators.required]],
        notification_content: ['', [Validators.required]],
        action_type: ['', [Validators.required]],
        menu_id: ['', [Validators.required]],
        tab_id: ['', []],
        notification_content_id: ['', []],
      });


    this.statuses = [
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ];
  }
  public getCountriesName() {
    this.apiModulesService.get('country/namelist').subscribe((data) => {
      this.CountryData = data;
      this.countryId = this.CountryData[0].country_id;
      this.getNotificationContent()

    });
  }
  public getMenuData() {
    this.apiModulesService.get('admin/notify/content/menu-dropdown').subscribe((data) => {
      this.MenuData = data.data.menus;
    });
  }
  public getMenuTabData() {
    var params: any = {};
    params.menu_id = this.addNotificationForm.value.menu_id;
    if(this.addNotificationForm.value.menu_id!=null && this.addNotificationForm.value.menu_id!=''){
    this.apiModulesService.add(params,'admin/notify/content/tab-dropdown').subscribe((data) => {
      this.TabData = data.data.tabs;
      this.addNotificationForm.patchValue({
        tab_id:null
      })
    });
  }
  }
  public getNotificationContent() {
    this.spinnerService.raiseDataEmitterEvent('on');
    var params: any = {};
    params.country_id = this.countryId;
    this.apiModulesService.add(params,'admin/notify/content/list').subscribe((data) => {
      this.NotificationContent = data?.data?.notification ?? [];
      this.totalRecords = this.NotificationContent.length
      this.resetForm();
      setTimeout(() => {
        this.spinnerService.raiseDataEmitterEvent('off');
      }, 100);
    }, (err) => {
      this.NotificationContent = [];
      this.spinnerService.raiseDataEmitterEvent('off');
      // this.rerender();
    });
  }
  clear(table: Table) {
    table.clear();
  }

  getSeverity(status: string) {
    switch (status.toLowerCase()) {
      case 'inactive':
        return 'danger';

      case 'active':
        return 'success';
    }
  }


  //resert after
  public resetForm() {
    this.addNotificationForm.reset();
  }
public onAddNotification(){
  if (this.addNotificationForm.invalid)
    {
    this.addNotificationForm.markAllAsTouched();
    return;
    }
    var params: any = {};
    params.country_id = this.countryId;
    params.section = this.addNotificationForm.value.section;
    params.notification_content =this.addNotificationForm.value.notification_content;
    params.action_type = this.addNotificationForm.value.action_type;
    params.menu_id = this.addNotificationForm.value.menu_id;
    params.tab_id = this.addNotificationForm.value.tab_id;
    const notification_content_id=this.addNotificationForm.value.notification_content_id;
    if(notification_content_id !=null && notification_content_id !=''){
      params.notification_content_id = this.addNotificationForm.value.notification_content_id;

      this.apiModulesService.add(params,'admin/notify/content/update').subscribe((data) => {
       this.getNotificationContent();
       ($('#add-notify-content') as any).modal('hide');

      }, (err) => {
        this.spinnerService.raiseDataEmitterEvent('off');
      });
    }else{
      this.apiModulesService.add(params,'admin/notify/content/create').subscribe((data) => {
        this.getNotificationContent();
        ($('#add-notify-content') as any).modal('hide');

       }, (err) => {
         this.spinnerService.raiseDataEmitterEvent('off');
       });
    }
   
}
  public onEditNotifyContent(notifyData: any) {
    this.addNotificationForm.setValue(
      {
        section: notifyData.section,
        notification_content: notifyData.notification_content,
        action_type: notifyData.action_type,
        menu_id: notifyData.menu_id,
        tab_id: notifyData.page_tab_id,
        notification_content_id: notifyData.notify_content_id,
      });

  }
  changeStatus(status: any, notifyContentId: any) {
    var params: any = {};
    params.notification_content_id = notifyContentId;
    this.apiModulesService.edit(params, 'admin/notify/content/' + status).subscribe((data) => {
      this.getNotificationContent();
      ($('#notify-status') as any).modal('hide');
      this.toastr.success('Status changed successfully...!', 'Success');
  }, (err) => {
    this.toastr.error('Something went wrong!', 'Try Again');
  }
  );
  }
  getAcronym(str: any) {
    var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
    matches = matches.slice(0, 2);
    var acronym = matches.join(''); // JSON
    return acronym;
  }
  getBgColors(str: any) {
    var min = 0;
    var max = 15;
    var index = this.findAlphapositions(str, 1);
    return this.nameBgColors[index];
  }
  findAlphapositions(str: any, n: any) {
    var NUM = 31;

    return str[0].charCodeAt(0) & NUM;
  }
  callStatusModel(url_name: any, data: any) {
    this.statusApi = url_name;
    this.statusValues = data;
  }

}
