import { HttpClient, HttpHeaders } from "@angular/common/http";
import { throwError, Observable } from "rxjs";
import { AllModulesData } from "src/assets/all-modules-data/all-modules-data";
import { id } from "src/assets/all-modules-data/id";
import { param } from "jquery";
import { Injectable } from "@angular/core";
import { tap, catchError, map } from 'rxjs/operators';
import { NavigationEnd, Router } from "@angular/router";
import { environment } from "../../environments/environment";
import { ApiModulesService } from "../all-modules/api-modules.service";

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    [x: string]: any;
    permittedMenu: any;

    // private _registerUrl = 'http://localhost:4200/login/register';
    // private _loginUrl= 'http://hro.com/api/login';
    private domain = environment.apiUrl;
    public apiurl;
    // Headers Setup
    headers = new HttpHeaders()
        .set("Content-Type", "application/json")
        .set("Accept", "application/json");
    httpOptions = {
        headers: this.headers,
    };

    constructor(private http: HttpClient, private route: Router, apiModuleService: ApiModulesService) {
    }

    // Handling Errors
    private handleError(error: any) {
        return throwError(error);
    }

    // registerUser(user){
    //   return this.http.post<any>(this._registerUrl,user)
    // }
    loginUser(user): Observable<any> {
        this.permittedMenu =[]
        this.ApiPemissionMenus();
        // return this.http.post<any>(this._loginUrl,user).pipe(tap());
        this.apiurl = this.domain + `api/login`;
        return this.http
            .post<any>(this.apiurl, user)
            .pipe(tap(), catchError(this.handleError));
    }
    passwordChange(user: any, type: any): Observable<any> {
        // return this.http.post<any>(this._loginUrl,user).pipe(tap());
        this.apiurl = this.domain + type;
        return this.http
            .post<any>(this.apiurl, user)
            .pipe(tap(), catchError(this.handleError));
    }

    loggedIn() {
        return !!localStorage.getItem('token');
    }
    adminLoggedIn() {
        return !!localStorage.getItem('token') && localStorage.getItem('user_type') === 'admin'
    }
    firstLogin() {
        return !!localStorage.getItem('is_first_login');
    }

    employeeLoggedIn() {
        return !!localStorage.getItem('token') && localStorage.getItem('user_type') === 'employee'
    }
    clientLoggedIn() {
        return !!localStorage.getItem('token') && localStorage.getItem('user_type') === 'client'
    }
    companyLoggedIn() {
        return !!localStorage.getItem('token') && localStorage.getItem('user_type') === 'company'
    }

    getToken() {
        return localStorage.getItem('token')
    }

    logoutUser() {
        localStorage.removeItem('token')
        this.route.navigate(['/login'])
    }
    async checkPermission(): Promise<boolean> {
        const userType = localStorage.getItem('user_type');
        const permittedMenu = this.permittedMenu?.data?.menus ?? [];
        localStorage.setItem('is_create','no');
        localStorage.setItem('is_modify','no');
        localStorage.setItem('is_remove','no');
        localStorage.setItem('menu_key','');
        const menu: any = permittedMenu.filter((val: any) => ('/' + userType + '/' + val.menu_link) == this.activeurl);
        if (menu.length != 0) {
          localStorage.setItem('is_create',menu[0].is_create==1?'yes':'no');
          localStorage.setItem('is_modify',menu[0].is_modify==1?'yes':'no');
          localStorage.setItem('is_remove',menu[0].is_remove==1?'yes':'no');
          localStorage.setItem('menu_key',menu[0].menu_key!=null?menu[0].menu_key:'');


          if (menu[0].is_remove == 1 || menu[0].is_read == 1 || menu[0].is_modify == 1 || menu[0].is_create == 1) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }

      async PemissionMenus(activeurl: string): Promise<void> {
        this.activeurl = activeurl;
        await this.ApiPemissionMenus();
      }

      ApiPemissionMenus(): Promise<void> {
        this.apiurl = `${this.domain}api/permission/menu`;

        return this.http.get<any>(this.apiurl, this.httpOptionsToken).toPromise()
          .then(permittedMenu => {
            this.permittedMenu = permittedMenu;
          })
          .catch(error => {
            console.error(error);
          });
      }
}
