import { Component, Input, OnInit } from '@angular/core';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { ApiModulesService } from 'src/app/all-modules/api-modules.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from "@angular/common";
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-time-approval',
  templateUrl: './time-approval.component.html',
  styleUrls: ['./time-approval.component.css']
})
export class TimeApprovalComponent implements OnInit {
  @Input() tabUserPermissionData :any={};
  employeeTimeData: any = {};
  type_key: any;
  clientID = localStorage.getItem('client_id');
  companyID = localStorage.getItem('company_id');
  employeeID = localStorage.getItem('employee_id');
  timeStatus: any = [
    { 'label': 'approved', 'value': 'approved' },
    { 'label': 'rejected', 'value': 'rejected' },
  ]
  public pipe = new DatePipe('en-US');
  allotedTimeData: any;
  allotedBreakData: any;
  actualTimeData: any;
  actualBreakData: any = [];
  employee_remark: any;
  actualTimeId: any;
  compOff_id: any = [];
  filterDatePopup: any;
  leaveTypeData: any;
  clickDate: any;
  modifyTimeForm: FormGroup;
  createBreakTime: any;
  calendarData: any;
  employeeNoDate: any;
  statusApi: string;
  leaveEmployeeId: any;
  request_employee_id: any;
  constructor(private spinnerService: SpinnerService, private apiModuleService: ApiModulesService,
    private formBuilder: FormBuilder, private toaster: ToastrService) {
  }
  ngOnInit() {
    this.spinnerService.raiseDataEmitterEvent('on');
    this.getManagerApproval();
    this.modifyTimeForm = this.formBuilder.group({
      time_status: ['', [Validators.required]],
      manager_remarks: ['', []],
      emp_time_id: ['', [Validators.required]],
    });
  }
  getManagerApproval() {
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.clientID,
      params.company_id = this.companyID,
      params.employee_id = this.employeeID,
      this.apiModuleService.list('employee-tabs/employee-hrm/manager-approval/list', params).subscribe((data: any) => {
        this.employeeTimeData = data.data.manager_approval !== undefined ? data.data.manager_approval : [];
        this.spinnerService.raiseDataEmitterEvent('off');
      }, (err: any) => {
        this.spinnerService.toasterError(err);
      });
  }

  employeeDetailsApproved(manager) {
    this.actualTimeId=manager.emp_time_id;
    this.request_employee_id=manager.employee_id;
    const params: any = {};
    params.client_id = this.clientID;
    params.company_id = this.companyID;
    params.request_employee_id = this.request_employee_id;
    params.emp_time_id = this.actualTimeId;
    params.date = manager.date;
    this.apiModuleService.list('employee-tabs/employee-hrm/manager-approval/popup', params).subscribe((data: any) => {
      this.allotedTimeData = data.data.shift_time[0] != undefined ? data.data.shift_time[0] : [];
      this.allotedBreakData = data.data.shift_break != undefined ? data.data.shift_break : [];
      this.actualTimeData = data.data.employee_shift_time[0] != undefined ? data.data.employee_shift_time[0] : [];
      this.actualBreakData = data.data.employee_break_time != undefined ? data.data.employee_break_time : [];
      this.spinnerService.raiseDataEmitterEvent('off');
    }, (err: any) => {
      this.spinnerService.toasterError(err);
    });
  }

  callStatusModel(status,manager) {
    this.statusApi = status;
    this.actualTimeId=manager.emp_time_id;
    this.request_employee_id=manager.employee_id;

  }

  updateModifyTime() {
    const params: any = {};
    params.client_id = this.clientID;
    params.company_id = this.companyID;
    params.request_employee_id = this.request_employee_id;
    params.manager_remarks = this.modifyTimeForm.value.manager_remarks;
    params.time_status = this.statusApi!=null ? this.statusApi:this.modifyTimeForm.value.time_status;
    params.emp_time_id = this.actualTimeId;
    this.apiModuleService.list('employee-tabs/employee-hrm/manager-approval/update', params).subscribe((data: any) => {
      this.toaster.success('Updated Successfully...!', 'Success');
      this.resetForm();
      ($('#time_details') as any).modal('hide');
      ($('#manager-status') as any).modal('hide');
      this.getManagerApproval();
    }, (err: any) => {
      this.spinnerService.toasterError(err);
    });
  }

public resetForm() {
    this.modifyTimeForm.reset();
    this.statusApi=null
  }

}
