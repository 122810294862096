import { Component, ViewChild } from '@angular/core';
import { SpinnerService } from '../../../../../snipper/spinner.service';
import { PfcontributionComponent } from './pfcontribution/pfcontribution.component';
import { ApiModulesService } from 'src/app/all-modules/api-modules.service';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-provident-fund',
  templateUrl: './provident-fund.component.html',
  styleUrls: ['./provident-fund.component.css']
})
export class ProvidentFundComponent {
  activeTab = '';
  @ViewChild(PfcontributionComponent) pfcontributionComponent: PfcontributionComponent;
  menu_key = 'provident_fund';
  tabsData: any = [];
  tabPermission: any ={};
  isCreate = localStorage.getItem('is_create');
  isModify = localStorage.getItem('is_modify');
  isRemove = localStorage.getItem('is_remove');
  constructor(private spinnerService: SpinnerService, private apiModulesService: ApiModulesService,private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.spinnerService.raiseDataEmitterEvent('on');
      this.activeTabPermission();
  }

  changeTabs(activeTab,data){

    this.activeTab = activeTab;
    this.tabPermission = data;
    this.cdr.detectChanges();

   }

  activeTabPermission() {
    const params = { menu_key: 'provident_fund' };
    this.apiModulesService.add(params, 'tabs-permission').subscribe((response) => {
      this.tabsData = response?.data?.tabs ?? [];
      if (this.tabsData.length > 0) {
        this.activeTab = this.tabsData[0].tab_key;
        this.tabPermission = this.tabsData.find(
          (val: any) => val.tab_key === this.activeTab
        );
      }
    });
  }
}
