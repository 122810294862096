import { Component, Input, OnInit } from '@angular/core';
import { SpinnerService } from 'src/app/snipper/spinner.service';
import { ApiModulesService } from 'src/app/all-modules/api-modules.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-employeetimeview',
  templateUrl: './employeetimeview.component.html',
  styleUrls: ['./employeetimeview.component.css']
})
export class EmployeetimeviewComponent implements OnInit{
  @Input() tabUserPermissionData: any = {};
  customers: any[];
  emplyeeWorkDetails: any[];
  groups: any[];
  groupRowsPerPage: number;
  rowRowsPerPage: number;
  rowPage: { [key: string]: number } = {};
  ShowForm: boolean;
  expandedRowKeys: any = {};
  filteredEmployees: { [key: string]: any[] } = {};
  filteredMainEmployees: { [key: string]: any[] } = {};
  filterKey;
  from_date: any;
  to_date: any;
  clientID = localStorage.getItem('client_id');
  companyID = localStorage.getItem('company_id');
  employeeID = localStorage.getItem('employee_id');
  managerTimeGrid: any;
  managerTimeHeaders: any;
  employeeTimeData: any;

  constructor(private spinnerService: SpinnerService, private apiModuleService: ApiModulesService,
    private toaster: ToastrService) {
    this.groupRowsPerPage = 10;
    this.rowRowsPerPage = 10;
    this.ShowForm = false;
  }

  ngOnInit() {
    this.spinnerService.raiseDataEmitterEvent('on');
    this.getEmployeeTimeViewData();
  }
  getEmployeeTimeViewData() {
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    if (!this.from_date || !this.to_date) {
      const currentDate = new Date();
      this.from_date = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      this.to_date = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    }
    const fromDate = new Date(this.from_date);
    fromDate.setDate(fromDate.getDate() + 1);
    const toDate = new Date(this.to_date);
    toDate.setDate(toDate.getDate() + 1);
    params.client_id = this.clientID,
      params.company_id = this.companyID,
      params.employee_id = this.employeeID,
      params.from_date = fromDate,
      params.to_date = toDate,
      this.apiModuleService.list('employee-tabs/employee-hrm/manager-time/emp-list', params).subscribe((data: any) => {
        this.managerTimeGrid = data.data.emp_time_grid !== undefined ? data.data.emp_time_grid : [];
        this.managerTimeHeaders = data.data.emp_time_header !== undefined ? data.data.emp_time_header : {};
        this.groups = this.groupCustomersByEmployee(this.managerTimeGrid);
        this.spinnerService.raiseDataEmitterEvent('off');
      }, (err: any) => {
        this.spinnerService.toasterError(err);
      });
  }
  groupCustomersByEmployee(customers: any[]) {
    const grouped = customers.reduce((acc, customer) => {
      let group = acc.find(g => g.employee_no === customer.employee_no);
      if (group) {
        group.customers.push(customer);
      } else {
        group = { employee_no: customer.employee_no, customers: [customer] };
        acc.push(group);
      }
      return acc;
    }, []);

    return grouped;
  }

  toggleRow(group: any, key: any) {
    const employee_no = group.employee_no;
    if (this.expandedRowKeys[employee_no]) {
      delete this.expandedRowKeys[employee_no];
      this.filteredEmployees[employee_no] = [];
      this.filteredMainEmployees[employee_no] = [];
    } else {
      this.expandedRowKeys[employee_no] = true;
      this.rowPage[employee_no] = this.rowPage[employee_no] || 0;
      this.updateFilteredEmployees(employee_no, key);
    }
  }
  toggleFilterRow(group: any, key: any) {
    const employee_no = group.employee_no;

    delete this.expandedRowKeys[employee_no];
    this.filteredEmployees[employee_no] = [];
    this.filteredMainEmployees[employee_no] = [];
    this.expandedRowKeys[employee_no] = true;
    this.rowPage[employee_no] = this.rowPage[employee_no] || 0;
    this.updateFilteredEmployees(employee_no, key);

  }

  updateFilteredEmployees(employee_no: string, key: any) {
    this.filterKey = key;
    const page = this.rowPage[employee_no] || 0;
    const start = page * this.rowRowsPerPage;
    const end = start + this.rowRowsPerPage;
    const params: any = {};
    if (!this.from_date || !this.to_date) {
      const currentDate = new Date();
      this.from_date = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      this.to_date = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    }
    const fromDate = new Date(this.from_date);
    fromDate.setDate(fromDate.getDate() + 1);
    const toDate = new Date(this.to_date);
    toDate.setDate(toDate.getDate() + 1);
    params.client_id = this.clientID,
      params.company_id = this.companyID,
      params.employee_id = this.employeeID,
      params.employee_no = employee_no,
      params.from_date = fromDate,
      params.to_date = toDate,
      this.apiModuleService.list('employee-tabs/employee-hrm/manager-time/emp-view-details', params).subscribe((data: any) => {
        this.employeeTimeData = data.data.manager_time !== undefined ? data.data.manager_time : [];
        if (key === null) {
          this.filteredMainEmployees[employee_no] = this.employeeTimeData.filter(emp => emp.employee_no === employee_no);
          this.filteredEmployees[employee_no] = this.employeeTimeData.filter(emp => emp.employee_no === employee_no).slice(start, end);
        } else {
          this.filteredMainEmployees[employee_no] = this.employeeTimeData.filter(emp => emp.employee_no === employee_no && emp.work_status == key);
          this.filteredEmployees[employee_no] = this.employeeTimeData.filter(emp => emp.employee_no === employee_no && emp.work_status == key).slice(start, end);
        }
        this.spinnerService.raiseDataEmitterEvent('off');
      }, (err: any) => {
        this.spinnerService.toasterError(err);
      });
  }

  onGroupPageChange(event: any) {
    // Handle group page change
  }

  onCustomerPageChange(event: any, group: any) {
    this.rowPage[group.employee_no] = event.page;
    this.updateFilteredEmployees(group.employee_no, this.filterKey);
  }

  getCustomersForGroup(group: any) {
    const employee_no = group.employee_no;
    const page = this.rowPage[employee_no] || 0;
    const start = page * this.rowRowsPerPage;
    const end = start + this.rowRowsPerPage;
    return group.customers.slice(start, end);
  }

  onRowGroupExpand(event: any) {
    // Handle row group expand
  }

  onRowGroupCollapse(event: any) {
    // Handle row group collapse
  }

}
