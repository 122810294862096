import { Component, OnInit } from '@angular/core';
import { ApiModulesService } from '../../api-modules.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray, } from '@angular/forms';
import { SpinnerService } from '../../../snipper/spinner.service';
import { ChangeDetectorRef } from '@angular/core';



@Component({
  selector: 'app-reports-key-sorting',
  templateUrl: './reports-key-sorting.component.html',
  styleUrls: ['./reports-key-sorting.component.css']
})
export class ReportsKeySortingComponent implements OnInit {
  ClientDropDown = [];
  CompanyDropDown = [];
  ClientID: any;
  CompanyID: any;
  PaygroupDropDown: any;
  PaygroupID: any;
  userKey: string;
  ReportData = [];
  userDropDown = [];
  sortOrder: any;
  employeeAssignedData = [];
  mappedData = [];
  CountryID: any;
  ReportTypeDropDown = [];
  ReportID: any;
  reportTypeID: any;
  EditData = [];
  //ReportDropdownData = [];
  reportDropDown:any;
  filterheader = [];
  header_type: any;
  headerData = [];
  filteredReportData=[];
  constructor(private apiModuleService: ApiModulesService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder, private spinnerService: SpinnerService,
    private cdr: ChangeDetectorRef) {
  }


  ngOnInit(): void {
    this.spinnerService.raiseDataEmitterEvent('on');
    this.GetClientDropDown();
    this.ReportdropDown();
   


  }

  /* client DropDown codes*/
  public GetClientDropDown() {
    this.apiModuleService.get('DropDown/client').subscribe((data) => {
      this.ClientDropDown = data.data.client;
      this.ClientID = data.data.client[0].client_id;
      if (this.ClientDropDown.length !== 0) {
        setTimeout(() => {
          this.ClientID = data.data.client[0].client_id;
          this.GetCompanyDropDown();
        }, 100)
      }
    })
  }

  public GetCompanyDropDown() {
    const params: any = {};
    params.client_id = this.ClientID;
    this.apiModuleService.list('DropDown/company', params).subscribe((data) => {
      this.CompanyDropDown = data.data.company != undefined ? data.data.company : [];
      if (this.CompanyDropDown.length !== 0) {
        setTimeout(() => {
          this.CompanyID = this.CompanyDropDown[0].company_id;
          this.GetpaygroupDropDown();
        }, 100)
      }
      else {
        this.CompanyID = null;
      }
    }
    )
  }

  /* client DropDown codes*/
  public GetpaygroupDropDown() {
    const params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    this.apiModuleService.list('DropDown/paygroups', params).subscribe((data) => {
      this.PaygroupDropDown = data.data.paygroup;
      this.PaygroupID = data.data.paygroup[0].pay_group_id;
      if (this.PaygroupDropDown.length !== 0) {
        setTimeout(() => {
          this.PaygroupID = this.PaygroupDropDown[0].pay_group_id;
          this.GetReportypeDropDown();

        }, 100)
      }
      else {
        this.PaygroupID = null;
      }
    });
  }

  public GetReportypeDropDown() {
    const params: any = {};
    params.client_id = this.ClientID;
    this.apiModuleService.list('DropDown/report-type', params).subscribe((data) => {
      this.ReportTypeDropDown = data.data.dropDown;
      this.reportTypeID = data.data.dropDown[0].reports_type_id;
          this.PaygroupID = this.PaygroupDropDown[0].pay_group_id;
          this.GetReportsKeySorting();
          
         // this.ReportdropDown();
   
      
    });
  }

  public GetReportsKeySorting() {
    const params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.pay_group_id = this.PaygroupID;

    this.spinnerService.raiseDataEmitterEvent('on');

    this.apiModuleService.list('admin/reportkeysorting/list', params).subscribe((data) => {
        this.ReportData = data.data.reports_key_sort[0] != undefined ? data.data.reports_key_sort[0] : [];
        this.filteredReportData = data.data.reports_key_sort[0] != undefined ? data.data.reports_key_sort[0] : [];
        this.headerData = data.data.reports_key_sort[1] != undefined ? data.data.reports_key_sort[1] : [];
        this.header_type = this.headerData[0]?.header_type || "";
      
    
        this.employeeAssignedData = []; // Reset before fetching

        setTimeout(() => {
            this.reportsEdit();
        }, 100);

        this.spinnerService.raiseDataEmitterEvent('off');
    }, (err) => {
        this.spinnerService.raiseDataEmitterEvent('off');
        this.ReportData = [];
        let errors = err.error.data.reports_key_sort;
        errors = errors.replace('SQLSTATE[42000]: [Microsoft][ODBC Driver 17 for SQL Server][SQL Server]', 'Error: ');
        this.toastrService.error(errors, 'Try Again');
    });
}


  onMoveToTarget(event: any) {
    const selectedItems = event.items;

    // Add the selected items to selectedComponentData
    selectedItems.forEach((item: any) => {
      const existingItem = this.employeeAssignedData.find((selectedItem: any) =>
        selectedItem.header_id === item.header_id
      );

      if (!existingItem) {
        this.employeeAssignedData.push({
          header_id: item.header_id,
          // Other properties if needed
        });
      }
    });
  }

  public getMappedShift() {
    if (this.employeeAssignedData && this.employeeAssignedData.length > 0) {
      // Create a new array with updated sequence_no
      const updatedComponentData = this.employeeAssignedData.map((item, index) => {
        return {
          ...item,
          sequence_no: index + 1 // Ensure sequence_no is updated
        };
      });

      let params: any = {
        client_id: this.ClientID,
        company_id: this.CompanyID,
        pay_group_id: this.PaygroupID,
        reports_type_id: this.reportTypeID,
        assign_details: updatedComponentData.map((val: any) => ({
          header_id: val.header_id,
          header_list: val.header_list,
          sequence_no: val.sequence_no,
          header_type: val.header_type
        }
        ))
      };


      this.apiModuleService.list("admin/reportkeysorting/update", params).subscribe(
        (data) => {
          this.toastrService.success('Report Key Sort Modified Successfully...!', 'Success');
        },
        (err) => {
          if (err.status === 437) {
            this.toastrService.error('Report Key Sort Already Exists...!', 'Failed');
          } else {
            this.spinnerService.toasterError(err);
          }
        }
      );
    }
  }

  public ReportdropDown() {
    this.filterheader = this.ReportData.filter(header =>header.header_type==this.header_type);
  }

  public reportsEdit() {
    let params: any = {};
    params.client_id = this.ClientID;
    params.company_id = this.CompanyID;
    params.pay_group_id = this.PaygroupID;
    params.reports_type_id = this.reportTypeID;

    this.apiModuleService.list("admin/reportkeysorting/edit", params).subscribe((data) => {

        // Ensure the assigned data is reset before updating
        this.employeeAssignedData = data.data.reports_key_sort;

        let storedHeaders = data.data.reports_key_sort.map(item => item.header_list);

        // Filter ReportData to exclude storedData
        let filteredReportData = this.filteredReportData.filter(item => !storedHeaders.includes(item.header_list));
        this.ReportData=filteredReportData

        // Force Angular to detect changes
        setTimeout(() => {
            this.employeeAssignedData = [...this.employeeAssignedData]; // Spread operator to trigger change detection
            this.ReportdropDown();

        }, 100);
    });
}

}



