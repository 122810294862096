// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table>:not(caption)>*>* {
	padding: 5px !important;
	color: var(--bs-table-color-state,var(--bs-table-color-type,var(--bs-table-color)));
	background-color: var(--bs-table-bg);
	border-bottom-width: var(--bs-border-width);
	box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state,var(--bs-table-bg-type,var(--bs-table-accent-bg)));
}
.emp_name_font{
  font-weight: bold;

}
.bold-text {
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/app/all-modules/employees/employee-salary-breakup/employee-salary-breakup.component.css"],"names":[],"mappings":"AAAA;CACC,uBAAuB;CACvB,mFAAmF;CACnF,oCAAoC;CACpC,2CAA2C;CAC3C,yGAAyG;AAC1G;AACA;EACE,iBAAiB;;AAEnB;AACA;EACE,iBAAiB;AACnB","sourcesContent":[".table>:not(caption)>*>* {\r\n\tpadding: 5px !important;\r\n\tcolor: var(--bs-table-color-state,var(--bs-table-color-type,var(--bs-table-color)));\r\n\tbackground-color: var(--bs-table-bg);\r\n\tborder-bottom-width: var(--bs-border-width);\r\n\tbox-shadow: inset 0 0 0 9999px var(--bs-table-bg-state,var(--bs-table-bg-type,var(--bs-table-accent-bg)));\r\n}\r\n.emp_name_font{\r\n  font-weight: bold;\r\n\r\n}\r\n.bold-text {\r\n  font-weight: bold;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
