import { Component, ViewChild } from '@angular/core';
import { ApiModulesService } from 'src/app/all-modules/api-modules.service';
import {SpinnerService} from '../../../../../snipper/spinner.service';
import { ApplyLoansComponent } from './apply-loans/apply-loans.component';
import { ChangeDetectorRef } from '@angular/core';
@Component({
  selector: 'app-loans-advances',
  templateUrl: './loans-advances.component.html',
  styleUrls: ['./loans-advances.component.css']
})
export class LoansAdvancesComponent {
  activeTab="";
  @ViewChild(ApplyLoansComponent)applyloansComponent: ApplyLoansComponent;
  menu_key = 'loans';
  tabsData: any=[];
  isCreate = localStorage.getItem('is_create');
  isModify = localStorage.getItem('is_modify');
  isRemove = localStorage.getItem('is_remove');
  tabPermission: any={};

    constructor( private spinnerService: SpinnerService,private apiModulesService: ApiModulesService,private cdr:ChangeDetectorRef){}


    ngOnInit()
    {
      this.spinnerService.raiseDataEmitterEvent('on');
        this.activeTabPermission();

     }

     changeTabs(activeTab:string,data){
      this.activeTab = activeTab;
      this.tabPermission=data;
      this.cdr.detectChanges();
     }
     activeTabPermission() {
      const params = { menu_key: 'loans' };
      this.apiModulesService.add(params, 'tabs-permission').subscribe((response) => {
        this.tabsData = response?.data?.tabs ?? [];
        if (this.tabsData.length > 0) {
          this.activeTab = this.tabsData[0].tab_key;
          this.tabPermission = this.tabsData.find(
            (val: any) => val.tab_key === this.activeTab
          );
        }
      });
    }
}
