import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { jsPDF } from 'jspdf';
import { ApiModulesService } from 'src/app/all-modules/api-modules.service';
import { SpinnerService } from "../../../../../snipper/spinner.service";
import { PayslipFormulaPopupComponent } from '../employee-payslip/payslip-formula-popup/payslip-formula-popup.component';

@Component({
  selector: 'app-salary-computation',
  templateUrl: './salary-computation.component.html',
  styleUrls: ['./salary-computation.component.css']
})

export class SalaryComputationComponent implements OnInit {
  @ViewChild(PayslipFormulaPopupComponent) payslipFormula: PayslipFormulaPopupComponent;
  clickedPopupData: any;
  download = false;
  salarycomputationData = [];
  dropDownValue = [];
  dropDownValueFilter = [];
  monthlySalaryData = [];
  earningsData = [];
  deductionData = [];
  grossSalaryData = [];
  grossDeductionData = [];
  netSalaryData = [];
  tax_regime: any
  monthGross: any
  annualGross: any;
  clientID = localStorage.getItem('client_id');
  companyID = localStorage.getItem('company_id');
  employeeID = localStorage.getItem('employee_id');
  estimatedTax: any;
  currentMonth:any;
  previousMonth:any;

  minDate: Date;
  maxDate: Date;
  chooseYearCurrent: any;
  chooseMonthCurrent: any;
  chooseYearPrevious: any;
  chooseMonthPrevious: any;
  minPayDate: any;
  maxPayDate: any;
  previousPayDate: any;
commonInfo:any;
Month:any
Year:any;
  constructor(private formBuilder: FormBuilder, private apiModulesService: ApiModulesService,
    private spinnerService: SpinnerService, private datePipe: DatePipe) {
  }

  ngOnInit() {


    this.spinnerService.raiseDataEmitterEvent('on');
    this.dropDown();
  }





  beforeDownloadFunction() {
    this.spinnerService.raiseDataEmitterEvent('on');
    this.download = true;
    setTimeout(() => {
      this.Convert_HTML_To_PDF();
    }, 2000);
  }

  print() {
    this.beforeDownloadFunction();
    setTimeout(() => {
      this.printContent();
    }, 2000);
  }

  downloads() {
    this.beforeDownloadFunction();
  }

  afterDownloadFunction() {

    this.download = false;
    this.spinnerService.raiseDataEmitterEvent('off');
  }

  // Convert HTML content to PDF
  Convert_HTML_To_PDF() {
    const doc = new jsPDF();
    // Source HTMLElement or a string containing HTML.
    const elementHTML: any = document.querySelector('#contentToPrintPage');
    doc.html(elementHTML, {
      callback: function (doc) {
        // Save the PDF
        // doc.addPage('1','p')
        doc.deletePage(6);
        doc.deletePage(5);
        doc.deletePage(4);
        doc.deletePage(3);
        doc.deletePage(2);
        doc.setLineHeightFactor(10)
        doc.save('salary.pdf');
      },
      margin: [10, 10, 10, 10],
      autoPaging: 'slice',
      x: 0,
      y: 0,
      width: 190, // target width in the PDF document
      windowWidth: window.innerWidth // window width in CSS pixels
    });
    this.afterDownloadFunction();
  }

  printContent() {
    const printContents = document.getElementById('contentToPrintPage').innerHTML;
    const originalContents = document.body.innerHTML;

    // Replace the entire content of the page with the content you want to print
    document.body.innerHTML = printContents;

    // Trigger the print dialog
    window.print();

    // Restore the original content after printing
    document.body.innerHTML = originalContents;
  }


  salaryData() {


    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};

    params.client_id = this.clientID;
    params.company_id = this.companyID;
    params.employee_id = this.employeeID;
    params.latest_pay_month = this.currentMonth;
    params.previous_pay_month = this.previousMonth;
    this.apiModulesService.list('employee-tabs/IND-employee-payroll/salarycomputation/list', params).subscribe((data) => {
      this.monthlySalaryData = data.data.salary_computation[1] != undefined ? data.data.salary_computation[1] : [];
      this.commonInfo = data.data.salary_computation[0][0] != undefined ? data.data.salary_computation[0][0] : {};
      if (this.monthlySalaryData.length !== 0) {
        this.Filters('earningsData', 'monthlySalaryData', 'component_type', 'Earnings');
        this.Filters('deductionData', 'monthlySalaryData', 'component_type', 'Deductions');
        this.Filters('grossSalaryData', 'monthlySalaryData', 'component_key', 'Gross_Earnings');
        this.Filters('grossDeductionData', 'monthlySalaryData', 'component_key', 'Gross_Deduction');
        this.Filters('netSalaryData', 'monthlySalaryData', 'component_key', 'Net_Salary');
      } else {
        this.earningsData = [];
        this.deductionData = [];
        this.grossSalaryData = [];
        this.grossDeductionData = [];
        this.netSalaryData = [];
      }
      this.spinnerService.raiseDataEmitterEvent('off');
    }, (err: any) => {
      this.earningsData = [];
      this.deductionData = [];
      this.grossSalaryData = [];
      this.grossDeductionData = [];
      this.netSalaryData = [];
      this.spinnerService.toasterError(err);
    });
  }


  dropDown() {
    const params: any = {};
    params.client_id = this.clientID;
    params.company_id = this.companyID;
    params.employee_id = this.employeeID;
    this.apiModulesService.list('employee-tabs/IND-employee-payroll/salarycomputation/drop-down', params).subscribe((data) => {
      this.dropDownValue = data.data.salary_computation != undefined ? data.data.salary_computation : [];
      if (this.dropDownValue.length > 0) {
        this.minPayDate = this.dropDownValue[0].min_pay_date; // Assuming the first object has min_pay_date
        this.maxPayDate = this.dropDownValue[0].max_pay_date; // Assuming the first object has max_pay_date
        this.previousPayDate = this.dropDownValue[0].previous_month; // Assuming the first object has max_pay_date

        this.minDate = new Date(this.minPayDate);
        this.maxDate = new Date(this.maxPayDate);
        this.currentMonth=this.maxPayDate;
        this.previousMonth=this.previousPayDate;
            }
            else{
              this.earningsData = [];
                      this.deductionData = [];
                      this.grossSalaryData = [];
                      this.grossDeductionData = [];
                      this.netSalaryData = [];
                      this.spinnerService.raiseDataEmitterEvent('off');
            }
      // this.year = data.data.salary_computation[0] != undefined ? data.data.salary_computation[0] : [];
      // this.chooseYear = data.data.salary_computation[0][0].year_no;
      // this.dropDownValueFilter = data.data.salary_computation[1] != undefined ? data.data.salary_computation[1] : [];
      // setTimeout(()=>{
      //         this.yearChange();
      // },1000);
      this.salaryData();
    }, (err: any) => {
      this.spinnerService.toasterError(err);

    })

  }


  // yearChange() {
  //     if (this.chooseYear != null && this.chooseYear !== '') {
  //     this.dropDownValue = this.dropDownValueFilter.filter((val: any) => val.year_no === this.chooseYear)
  //     if (this.dropDownValue.length !== 0) {
  //         this.chooseMonth = this.dropDownValue[0].month_name;
  //         this.salaryData();
  //     } else {
  //         this.chooseMonth = null;
  //         this.earningsData = [];
  //         this.deductionData = [];
  //         this.grossSalaryData = [];
  //         this.grossDeductionData = [];
  //         this.netSalaryData = [];
  //         this.spinnerService.raiseDataEmitterEvent('off');
  //     }
  //     }else {
  //             this.chooseMonth = null;
  //             this.earningsData = [];
  //             this.deductionData = [];
  //             this.grossSalaryData = [];
  //             this.grossDeductionData = [];
  //             this.netSalaryData = [];
  //             this.spinnerService.raiseDataEmitterEvent('off');
  //         }
  // }

  Filters(filteredArray, mainArray, keyFilter, filterBasedValue) {
    this[filteredArray] = this[mainArray].filter((val: any) => val[keyFilter] === filterBasedValue);
  }

  openFormulaPopup(data) {
    this.clickedPopupData = data;
    setTimeout(() => {
      if (data.component_key == 'Income_Tax') {
        this.payslipFormula.incomePopUp();
        this.payslipFormula.payslipFormula();
      } else {
        this.payslipFormula.openPopUp();

      }
    }, 100)
  }
  openYtdFormulaPopup(data) {
    this.clickedPopupData = data;
    setTimeout(() => {
      this.payslipFormula.openYTDPopUp();
    }, 100)
  }
  singleFormulaPopup(data) {
    this.clickedPopupData = data;
    setTimeout(() => {
      this.payslipFormula.singleCalculationPopUp();
    }, 100)
  }

  setCommonInfoValue(key:any){
    let year_month=''
    if(key=='latest'){
      year_month=this.commonInfo.latest_pay_month
      this.estimatedTax=this.commonInfo.estimated_annual_taxable_income
      this.annualGross=this.commonInfo.annual_gross
      this.monthGross=this.commonInfo.monthly_gross
      this.tax_regime=this.commonInfo.tax_regime
    }
    if(key=='previous'){
      year_month=this.commonInfo.previous_pay_month
      this.estimatedTax=this.commonInfo.previous_estimated_annual_taxable_income
      this.annualGross=this.commonInfo.previous_annual_gross
      this.monthGross=this.commonInfo.previous_monthly_gross
      this.tax_regime=this.commonInfo.previous_tax_regime
    }

let array=year_month.split('-');
    let month=array[0] !=undefined? array[0]:null
    let year=array[1]!=undefined ? array[1]:null
    this.Month=month,
    this.Year=year


  }
}


