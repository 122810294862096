import { Component, Input } from '@angular/core';
import { Table } from 'primeng/table';
import Chart from 'chart.js/auto';
import { ChartOptions } from 'chart.js';
import { ApiModulesService } from 'src/app/all-modules/api-modules.service';
import { SpinnerService } from 'src/app/snipper/spinner.service';



@Component({
  selector: 'app-reimbursement-slip',
  templateUrl: './reimbursement-slip.component.html',
  styleUrls: ['./reimbursement-slip.component.css']
})
export class ReimbursementSlipComponent {
  @Input() reimbursement :any={};
  RIBslipData: any;
  nameBgColors: any = ['bg-blue-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim', 'bg-blue-dim', 'bg-azure-dim', 'bg-indigo-dim', 'bg-info-dim', 'bg-purple-dim', 'bg-pink-dim', 'bg-orange-dim', 'bg-teal-dim', 'bg-primary-dim', 'bg-success-dim', 'bg-warning-dim', 'bg-danger-dim', 'bg-secondary-dim', 'bg-dark-dim', 'bg-gray-dim'];
  nameBorderColors: any = ['bg-outline-primary', 'bg-outline-success', 'bg-outline-info', 'bg-outline-gray', 'bg-outline-warning', 'bg-outline-dark', 'bg-outline-light', 'bg-outline-secondary', 'bg-outline-danger'];
  options: any;
  claimChart: any;
  monthChart: any;
  slipChart: any;
  monthModalChart: any;
  claimModalChart: any;
  initialized = false;
  clientID = localStorage.getItem('client_id');
  companyID = localStorage.getItem('company_id');
  employeeID = localStorage.getItem('employee_id');
  chooseMonth: any;
  chooseYear: any;
  year = []
  dropDownValue = [];
  dropDownValueFilter: any;
  employeeData: any;
  RIBComponentGraph: any;
  RIBMonthComponentGraph: any;
  componentChoose: any;
  filterRIBComponentGraph: any;
  formsEmployeeData: {};
  popupName: any;
  totalClaimed: number = 0;
  result = '';
  filteredData: any;
  annualEligibility: any;
  balance: any;
  balancePopupDetails: any;
  component_id: any;
  totalPaidForMonth: any;
  componentName: any;

  constructor(private spinnerService: SpinnerService, private apiModulesService: ApiModulesService) { }

  ngOnInit() {
    this.spinnerService.raiseDataEmitterEvent('on');
    this.dropDown();
  }


  getReimbursementData() {
    if (this.chooseMonth == null || this.chooseMonth == '') {
      return;
    }
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.clientID;
    params.company_id = this.companyID;
    params.employee_id = this.employeeID;
    params.month_name = this.chooseMonth;
    params.year_no = this.chooseYear;
    this.apiModulesService.list('employee-tabs/IND-employee-payroll/reimbursement/list', params).subscribe((data) => {
      this.employeeData = data.data.reimbursement[0] != undefined ? data.data.reimbursement[0] : [];
      this.RIBslipData = data.data.reimbursement[1] != undefined ? data.data.reimbursement[1] : [];
      this.totalClaimed = 0;
      this.totalClaimed = this.RIBslipData.filter((item: any) => item.component_key == 'total')[0].claimed
      this.result = this.numberToWords(this.totalClaimed != undefined ? this.totalClaimed : 0);
      this.spinnerService.raiseDataEmitterEvent('off');
    }, (err) => {
      this.spinnerService.toasterError(err);
    });
  }

  balancePopupData() {
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.clientID;
    params.company_id = this.companyID;
    params.employee_id = this.employeeID;
    params.component_id = this.component_id;
    this.apiModulesService.list('employee-tabs/IND-employee-payroll/reimbursement/popup', params).subscribe((data) => {
      this.balancePopupDetails = data.data.balance_popup != undefined ? data.data.balance_popup : [];
      this.spinnerService.raiseDataEmitterEvent('off');
      this.totalPaidForMonth = this.balancePopupDetails.reduce((total, item) => {
        return total + (parseFloat(item.paid_for_month) || 0); // Ensure paid_for_month is treated as a number
      }, 0);
    }, (err) => {
      this.spinnerService.toasterError(err);
    });
  }

  reimbursementGraphData() {
    const params: any = {};
    params.client_id = this.clientID;
    params.company_id = this.companyID;
    params.employee_id = this.employeeID;
    params.month_name = this.chooseMonth;
    params.year_no = this.chooseYear;
    this.apiModulesService.list('employee-tabs/IND-employee-payroll/reimbursement/graph', params).subscribe((data) => {
      this.RIBMonthComponentGraph = data.data.reimbursement[1] != undefined ? data.data.reimbursement[1] : [];
      this.RIBComponentGraph = data.data.reimbursement[0] != undefined ? data.data.reimbursement[0] : [];
      this.componentChoose = data.data.reimbursement[0][0].component_id;

      this.reimbursementGraph();
      setTimeout(() => {
        this.reimbursementLineGraph();
      }, 1000)
    }, (err) => {
      this.spinnerService.toasterError(err);

    })

  }
  dropDown() {
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.clientID;
    params.company_id = this.companyID;
    params.employee_id = this.employeeID;

    this.apiModulesService.list('employee-tabs/IND-employee-payroll/reimbursement/dropdown', params).subscribe((data) => {
      this.dropDownValue = data.data.reimbursement[1] != undefined ? data.data.reimbursement[1] : [];
      this.year = data.data.reimbursement[0] != undefined ? data.data.reimbursement[0] : [];
      this.chooseYear = (data.data.reimbursement[0] && data.data.reimbursement[0][0] && data.data.reimbursement[0][0].year_no)? data.data.reimbursement[0][0].year_no : '';
      this.dropDownValueFilter = data.data.reimbursement[1] != undefined ? data.data.reimbursement[1] : [];
      this.spinnerService.raiseDataEmitterEvent('off');
      setTimeout(() => {
        this.yearChange();
      }, 1000)
    }, (err) => {
      this.spinnerService.toasterError(err);
      this.spinnerService.raiseDataEmitterEvent('off');
    })

  }

  numberToWords(number: number): string {
    const ones = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
    const teens = ['', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
    const tens = ['', 'Ten', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
    if (number === 0) {
      return 'Zero';
    }
    let result = '';
    if (number < 10) {
      result = ones[number];
    } else if (number < 20) {
      result = teens[number - 10];
    } else if (number < 100) {
      result = tens[Math.floor(number / 10)] + ' ' + ones[number % 10];
    } else if (number < 1000) {
      result = ones[Math.floor(number / 100)] + ' Hundred ' + this.numberToWords(number % 100);
    } else if (number < 100000) {
      result = this.numberToWords(Math.floor(number / 1000)) + ' Thousand ' + this.numberToWords(number % 1000);
    } else if (number < 10000000) {
      result = this.numberToWords(Math.floor(number / 100000)) + ' Lakh ' + this.numberToWords(number % 100000);
    } else if (number < 1000000000) {
      result = this.numberToWords(Math.floor(number / 10000000)) + ' Crore ' + this.numberToWords(number % 10000000);
    } else {
      result = 'Number out of range';
    }
    return result.trim();
  }
  yearChange() {
    this.spinnerService.raiseDataEmitterEvent('on');
    if (this.chooseYear != null && this.chooseYear !== '') {
      this.dropDownValue = this.dropDownValueFilter.filter((val: any) => val.year_no === this.chooseYear);
      setTimeout(() => {
        this.monthChange();
      }, 100)
      this.spinnerService.raiseDataEmitterEvent('off');
    } else {
      this.chooseMonth = null;
      this.RIBslipData = [];
      // this.deductionData = [];
      // this.grossSalaryData = [];
      // this.grossDeductionData = [];
      // this.netSalaryData = [];
      this.spinnerService.raiseDataEmitterEvent('off');
    }


  }

  monthChange() {
     this.spinnerService.raiseDataEmitterEvent('on');
    if (this.dropDownValue.length !== 0) {
      this.chooseMonth = this.dropDownValue[0].month_name;
      setTimeout(() => {
        this.getReimbursementData();
        this.reimbursementGraphData();

      }, 100)
      this.spinnerService.raiseDataEmitterEvent('off');
    }
    else {
      this.chooseMonth = null;
      this.RIBslipData = [];
      // this.deductionData = [];
      // this.grossSalaryData = [];
      // this.grossDeductionData = [];
      // this.netSalaryData = [];
      this.spinnerService.raiseDataEmitterEvent('off');
    }
  }

  reimbursementGraph() {
    const abbreviateAndCapitalize = (str: string) => {
      const words = str.split(' ');
      const abbreviatedWords = words.map(word => word.charAt(0).toUpperCase());
      return abbreviatedWords.join('');
    };
    const chartOptions: ChartOptions<'bar'> = {
      plugins: {
        legend: {
          labels: {
            color: 'black',
            boxWidth: 10,
            font: {
              size: 11,
              style: 'normal', // Font style of the legend labels
              family: 'Arial', // Font family of the legend labels
            },

          },
          position: 'bottom', // Position of the legend
        },
      },
      scales: {
        x: {
          grid: {
            display: false, // Set to false to hide the vertical lines
          },
        },
        y: {
          // You can configure y-axis options here if needed
        },
      },
      aspectRatio: 2,
      // Other chart options...
    };
    const abbreviatedLabels = this.RIBComponentGraph.map((data: any) => abbreviateAndCapitalize(data.component_name));


    this.claimChart = new Chart("reimbursementSlipChart", {
      type: 'bar',
      data: {
        labels: abbreviatedLabels,
        datasets: [
          {
            label: 'Annual Eligibility',
            backgroundColor: '#f2c88a',
            data: this.RIBComponentGraph.map((data: any) => data.annual_eligibility),
          },
          {
            label: 'Paid Till Date',
            backgroundColor: '#a2dea4',
            data: this.RIBComponentGraph.map((data: any) => data.paid_till_date),
          },




        ]
      },
      options: chartOptions
    });
    $("#claimChartclick").click(() => {
      let anyHidden = this.claimChart.data.datasets.some(ds => ds.hidden);

      this.claimChart.data.datasets.forEach(ds => {
        ds.hidden = anyHidden ? false : true;
      });

      this.claimChart.destroy(); // Destroy the existing chart

      // Re-create the chart
      this.claimChart = new Chart("reimbursementSlipChart", {
        type: 'bar',
        data: {
          labels: abbreviatedLabels,
          datasets: this.claimChart.data.datasets // Reuse the datasets
        },
        options: chartOptions
      });

      this.claimChart.update();
    });
  }
  reimbursementLineGraph() {
    this.changeGraphDropDown();

    const chartOptions: ChartOptions<'bar'> = {
      plugins: {
        legend: {
          labels: {
            color: 'black',
            boxWidth: 10,
            font: {
              size: 11,
              style: 'normal', // Font style of the legend labels
              family: 'Arial', // Font family of the legend labels
            },
          },
          position: 'bottom', // Position of the legend

        },
      },
      scales: {
        x: {
          grid: {
            display: false, // Set to false to hide the vertical lines
          },
        },
        y: {
          // You can configure y-axis options here if needed
        },
      },
      aspectRatio: 2,
    };
    this.monthChart = new Chart("reimbursementMonthSlipChart", {
      type: 'bar',
      data: {
        labels: this.filterRIBComponentGraph.map((data: any) => data.month_name_short),
        datasets: [
          {
            label: 'Opening',
            backgroundColor: '#adc0ed',
            data: this.filterRIBComponentGraph.map((data: any) => data.claimed),
          }, {
            type: 'bar',
            label: 'Paid',
            data: this.filterRIBComponentGraph.map((data: any) => data.paid_for_month),
            backgroundColor: '#f5cdb5'
          }, {
            type: 'line',
            label: 'Closing',
            data: this.filterRIBComponentGraph.map((data: any) => data.declined),
            fill: false,
            borderColor: '#ed9280',
            backgroundColor: '#ed9280',
            pointBackgroundColor: '#e8352e',
          }
        ]
      },
      options: chartOptions
    });
    $("#monthChartclick").click(() => {
      let anyHidden = this.monthChart.data.datasets.some(ds => ds.hidden);

      this.monthChart.data.datasets.forEach(ds => {
        ds.hidden = anyHidden ? false : true;
      });

      this.monthChart.destroy(); // Destroy the existing chart

      // Re-create the chart
      this.monthChart = new Chart("reimbursementMonthSlipChart", {
        type: 'bar',
        data: {
          labels: this.filterRIBComponentGraph.map((data: any) => data.month_name_short),
          datasets: this.monthChart.data.datasets // Reuse the datasets
        },
        options: chartOptions
      });

      this.monthChart.update();
    });
  }
  reimbursementModalGraph() {
    const chartOptions: ChartOptions<'bar'> = {
      plugins: {
        legend: {
          labels: {
            color: 'black',
            boxWidth: 10,
            font: {
              size: 11,
              style: 'normal', // Font style of the legend labels
              family: 'Arial', // Font family of the legend labels
            },
          },
          position: 'bottom', // Position of the legend
        },
      },
      scales: {
        x: {
          grid: {
            display: false, // Set to false to hide the vertical lines
          },
        },
        y: {
          // You can configure y-axis options here if needed
        },
      },
      aspectRatio: 2,
      // Other chart options...
    };
    this.claimModalChart = new Chart("reimbursementModalsChart", {
      type: 'bar',
      data: {
        labels: this.RIBComponentGraph.map((data: any) => data.component_name),
        datasets: [
          {
            label: 'Annual Eligibility',
            backgroundColor: '#f2c88a',
            data: this.RIBComponentGraph.map((data: any) => data.annual_eligibility),
          },
          {
            label: 'Paid Till Date',
            backgroundColor: '#a2dea4',
            data: this.RIBComponentGraph.map((data: any) => data.paid_till_date),
          },

        ]
      },
      options: chartOptions
    });
    $("#claimModalChartclick").click(() => {
      let anyHidden = this.claimModalChart.data.datasets.some(ds => ds.hidden);

      this.claimModalChart.data.datasets.forEach(ds => {
        ds.hidden = anyHidden ? false : true;
      });

      this.claimModalChart.destroy(); // Destroy the existing chart

      // Re-create the chart
      this.claimModalChart = new Chart("reimbursementModalsChart", {
        type: 'bar',
        data: {
          labels: this.RIBComponentGraph.map((data: any) => data.component_name),
          datasets: this.claimModalChart.data.datasets // Reuse the datasets
        },
        options: chartOptions
      });

      this.claimModalChart.update();
    });
  }
  reimbursementModalLineGraph() {
    this.changeGraphDropDown();
    const chartOptions: ChartOptions<'bar'> = {
      plugins: {
        legend: {
          labels: {
            color: 'black',
            boxWidth: 10,
            font: {
              size: 11,
              style: 'normal', // Font style of the legend labels
              family: 'Arial', // Font family of the legend labels
            },
          },
          position: 'bottom', // Position of the legend
        },
      },
      scales: {
        x: {
          grid: {
            display: false, // Set to false to hide the vertical lines
          },
        },
        y: {
          // You can configure y-axis options here if needed
        },
      },
      aspectRatio: 2,
      // Other chart options...
    };
    this.monthModalChart = new Chart("reimbursementMonthSlipModalChart", {
      type: 'bar',
      data: {
        labels: this.filterRIBComponentGraph.map((data: any) => data.month_name_short),
        datasets: [
          {
            label: 'Opening',
            backgroundColor: '#adc0ed',
            data: this.filterRIBComponentGraph.map((data: any) => data.claimed),
          }, {
            type: 'bar',
            label: 'Paid',
            data: this.filterRIBComponentGraph.map((data: any) => data.paid_for_month),
            backgroundColor: '#f5cdb5'
          }, {
            type: 'line',
            label: 'Closing',
            data: this.filterRIBComponentGraph.map((data: any) => data.declined),
            fill: false,
            borderColor: '#ed9280',
            backgroundColor: '#ed9280',
            pointBackgroundColor: '#e8352e',
          }
        ]
      },
      options: chartOptions
    });
    $("#monthModalChartclick").click(() => {
      let anyHidden = this.monthModalChart.data.datasets.some(ds => ds.hidden);

      this.monthModalChart.data.datasets.forEach(ds => {
        ds.hidden = anyHidden ? false : true;
      });

      this.monthModalChart.destroy(); // Destroy the existing chart

      // Re-create the chart
      this.monthModalChart = new Chart("reimbursementMonthSlipModalChart", {
        type: 'bar',
        data: {
          labels: this.filterRIBComponentGraph.map((data: any) => data.month_name_short),
          datasets: this.monthModalChart.data.datasets // Reuse the datasets
        },
        options: chartOptions
      });

      this.monthModalChart.update();
    });
  }
  openReimbursementModal(value, key, empReimbursementId: any,component_id:any) {

    value.employee_no = this.employeeData[0].emploee_no;
    value.employee_name = this.employeeData[0].employee_name;
    value.month_name_year = this.chooseMonth + '-' + this.chooseYear;
    this.formsEmployeeData = value;
    this.popupName = key
    this.component_id=component_id;
    this.componentName=value.component_name;
    this.balancePopupData();
    this.filteredData = this.RIBslipData.filter((item: any) => item.emp_reimbursement_id === empReimbursementId);
    this.annualEligibility = this.filteredData[0]?.annual_eligibility || 0;
    this.balance = this.filteredData[0]?.balance || 0;
    this.popupName = key;
  }

  openReimbursementGraphModal() {
    setTimeout(() => {
      this.reimbursementModalGraph();
      this.reimbursementModalLineGraph();


    }, 500);
  }
  changeGraphDropDown() {
    this.filterRIBComponentGraph = this.RIBMonthComponentGraph.filter((val: any) => val.component_id == this.componentChoose);

  }
  getBgColors(str: any) {
    const min = 0;
    const max = 15;
    const index = this.findAlphapositions(str, 1);
    return this.nameBgColors[index];
  }

  findAlphapositions(str: any, n: any) {
    const NUM = 31;

    return str[0].charCodeAt(0) & NUM;
  }
  clear(table: Table) {

    table.clear();
  }

  getSeverity(status: string) {
    switch (status.toLowerCase()) {
      case 'inactive':
        return 'danger';

      case 'active':
        return 'success';
    }
  }

  getAcronym(str: any) {
    let matches = str.match(/\b(\w)/g); // ['J','S','O','N']
    matches = matches.slice(0, 3);
    const acronym = matches.join(''); // JSON
    return acronym;
  }


  dropDownChange(name) {
    this.changeGraphDropDown();
    setTimeout(() => {
      this.monthChart.data.labels = this.filterRIBComponentGraph.map((data: any) => data.month_name_short);
      this.monthChart.data.datasets[0].data = this.filterRIBComponentGraph.map((data: any) => data.claimed);
      this.monthChart.data.datasets[1].data = this.filterRIBComponentGraph.map((data: any) => data.paid_for_month);
      this.monthChart.data.datasets[2].data = this.filterRIBComponentGraph.map((data: any) => data.declined);
      this.monthChart.update();
      this.monthModalChart.data.labels = this.filterRIBComponentGraph.map((data: any) => data.month_name_short);
      this.monthModalChart.data.datasets[0].data = this.filterRIBComponentGraph.map((data: any) => data.claimed);
      this.monthModalChart.data.datasets[1].data = this.filterRIBComponentGraph.map((data: any) => data.paid_for_month);
      this.monthModalChart.data.datasets[2].data = this.filterRIBComponentGraph.map((data: any) => data.declined);
      this.monthModalChart.update();
    }, 100);
  }





}
