import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { jsPDF } from 'jspdf';
import { ApiModulesService } from 'src/app/all-modules/api-modules.service';
import { SpinnerService } from "../../../../../snipper/spinner.service";
import Chart from 'chart.js/auto';
import { ChartOptions } from 'chart.js';
import { keyframes } from '@angular/animations';
import { PayslipFormulaPopupComponent } from './payslip-formula-popup/payslip-formula-popup.component';
@Component({
  selector: 'app-employee-payslip',
  templateUrl: './employee-payslip.component.html',
  styleUrls: ['./employee-payslip.component.css']
})
export class EmployeePayslipComponent implements OnInit {
  @ViewChild(PayslipFormulaPopupComponent) payslipFormula: PayslipFormulaPopupComponent;
  clickedPopupData: any;
  showTableEarnings = false;
  showTableDeduction = false;
  showNumber = true;
  showDate = true;
  showPanNumber = true;
  showUanNumber = true;
  showAccountNumber = true;
  showPfNumber = true;
  showEsiNumber = true;
  showTableNumber = false;
  showTable = false;
  download = false;
  employeeData = [];
  payslipData: any = [];
  payslipAddressData = [];
  dropDownValue = [];
  dropDownValueFilter = [];
  yearDropDown = [];
  earningsData: any = [];
  deductionData = [];
  grossSalaryData = [];
  grossDeductionData = [];
  netSalaryData = [];
  annualGross: any;
  monthGross: any;
 estimatedTax: any;
  chooseMonth: any;
  chooseYear: any;
  awsLoginImages: any;
  clientID = localStorage.getItem('client_id');
  companyID = localStorage.getItem('company_id');
  employeeID = localStorage.getItem('employee_id');
  from_month:any
  year = []
  inputNumber: number;
  result = '';
  noOfDaysInMonth: string;
  noOfDaysPaidFor: string;
  Downloads = false;
  public earningschart: any;
  public deductionchart: any;
  public netearningchart: any;
  public earningsFinYearChart: any;
  public initialized = false;
  public initializedYearGraph = false;
  rotate = false;
  RIBslipData: any;
  options: any;
  financialYearId: any;
  financialMonth = [];
  financialYearData = [];
  earningsGraphData = [];
  detuctionGraphData = [];
  cummulativeGraphData = [];
  earningsYTDGraphData = [];
  detuctionYTDGraphData = [];
  cummulativeYTDGraphData = [];
  financialMonthYearData = [];
  financialComponents = [];
  chooseGraphMonth: any;
  chooseGraphYear: any;
  constructor(private formBuilder: FormBuilder, private apiModulesService: ApiModulesService, private spinnerService: SpinnerService) {
  }

  ngOnInit() {
    this.spinnerService.raiseDataEmitterEvent('on');
    this.dropDown();

  }

  beforeDownloadFunction() {
    this.spinnerService.raiseDataEmitterEvent('on');
    this.Downloads = true;
    setTimeout(() => {
      this.Convert_HTML_To_PDF();
    }, 100);
  }

  print() {
    this.beforeDownloadFunction();
    setTimeout(() => {
      this.printContent();
    }, 100);
  }

  downloads() {
    this.beforeDownloadFunction();
  }

  afterDownloadFunction() {
    this.showNumber = true;
    this.Downloads = false;
    this.showDate = true;
    this.showPanNumber = true;
    this.showUanNumber = true;
    this.showAccountNumber = true;
    this.showPfNumber = true;
    this.showEsiNumber = true;
    this.showTableNumber = false;
    this.showTable = false;
    this.showTableEarnings = false;
    this.showTableDeduction = false;
    this.download = false;
    this.spinnerService.raiseDataEmitterEvent('off');

  }

  // Convert HTML content to PDF
  Convert_HTML_To_PDF() {
    const doc = new jsPDF();
    // Source HTMLElement or a string containing HTML.
    const elementHTML: any = document.querySelector('#contentToPrint');
    doc.html(elementHTML, {
      callback: function (doc) {
        // Save the PDF
        // doc.addPage('1','p')
        for (let i = doc.getNumberOfPages(); i != 1; i--) {
          doc.deletePage(i);
        }

        doc.setLineHeightFactor(10);
        doc.setFontSize(20);
        doc.setLineHeightFactor(10);
        doc.save('payslip.pdf');
      },
      margin: [10, 10, 10, 10],
      autoPaging: 'slice',
      x: 0,
      y: 10,
      width: 190, // target width in the PDF document
      windowWidth: 1100 // window width in CSS pixels

    });
    this.afterDownloadFunction();
  }

  printContent() {
    const printContents = document.getElementById('contentToPrint').innerHTML;
    const originalContents = document.body.innerHTML;
    // Replace the entire content of the page with the content you want to print
    document.body.innerHTML = printContents;
    // Trigger the print dialog
    window.print();
    // Restore the original content after printing
    document.body.innerHTML = originalContents;
    this.afterDownloadFunction();
  }

  paySlipGrid() {
    this.afterDownloadFunction();
    if (this.chooseMonth == null || this.chooseMonth == '') {
      return
    }
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.clientID;
    params.company_id = this.companyID;
    params.employee_id = this.employeeID;
    params.month_name = this.chooseMonth;
    params.year_no = this.chooseYear;

    this.apiModulesService.list('employee-tabs/IND-employee-payroll/payslip/list', params).subscribe((data) => {

      this.employeeData = data.data.pay_slip[0] != undefined ? data.data.pay_slip[0] : [];
      this.payslipData = data.data.pay_slip[1] != undefined ? data.data.pay_slip[1] : [];
      this.payslipAddressData = data.data.pay_slip[2] != undefined ? data.data.pay_slip[2] : [];
      this.awsLoginImages = this.payslipAddressData[0].profile_img_url != null ? 'data:image/png;base64,' + this.payslipAddressData[0].profile_img_url : null;
      this.annualGross = data.data.pay_slip[0][0].annual_gross != undefined ? data.data.pay_slip[0][0].annual_gross : 0;
      this.monthGross = data.data.pay_slip[0][0].monthly_gross != undefined ? data.data.pay_slip[0][0].monthly_gross : 0;
      this.estimatedTax = data.data.pay_slip[0][0].estimated_annual_taxable_income != undefined ? data.data.pay_slip[0][0].estimated_annual_taxable_income : 0;
      this.from_month = data.data.pay_slip[0][0].from_month != undefined ? data.data.pay_slip[0][0].from_month : '';
      if (this.payslipData.length !== 0) {
        this.Filters('earningsData', 'payslipData', 'component_type', 'Earnings');
        this.Filters('deductionData', 'payslipData', 'component_type', 'Deductions');
        this.Filters('grossSalaryData', 'payslipData', 'component_key', 'Gross_Earnings');
        this.Filters('grossDeductionData', 'payslipData', 'component_key', 'Gross_Deduction');
        this.Filters('netSalaryData', 'payslipData', 'component_key', 'Net_Salary');

        this.result = this.numberToWords(this.netSalaryData[0].component_values != undefined ? Number(this.netSalaryData[0].component_values.replace(/,/g, '')) : 0);
        this.noOfDaysInMonth = this.numberToWords(this.employeeData[0].no_of_days != undefined ? this.employeeData[0].no_of_days : 0);
        this.noOfDaysPaidFor = this.numberToWords(this.employeeData[0].no_of_days_paid != undefined ? this.employeeData[0].no_of_days_paid : 0);

      } else {
        this.earningsData = [];
        this.deductionData = [];
        this.grossSalaryData = [];
        this.grossDeductionData = [];
        this.netSalaryData = [];
      }
      this.spinnerService.raiseDataEmitterEvent('off');

      if (this.initialized == true) {
        this.payslipTotalGraph()

      }

    }, (err: any) => {
      this.earningsData = [];
      this.deductionData = [];
      this.grossSalaryData = [];
      this.grossDeductionData = [];
      this.netSalaryData = [];
      this.spinnerService.toasterError(err);
    })

  }

  dropDown() {
    const params: any = {};
    params.client_id = this.clientID;
    params.company_id = this.companyID;
    params.employee_id = this.employeeID;

    this.apiModulesService.list('employee-tabs/IND-employee-payroll/payslip/drop-down', params).subscribe((data) => {
      this.dropDownValue = data.data.pay_slip[1] != undefined ? data.data.pay_slip[1] : [];
      this.year = data.data.pay_slip[0] != undefined ? data.data.pay_slip[0] : [];
      this.chooseYear = data.data.pay_slip[0][0].year_no;
      this.dropDownValueFilter = data.data.pay_slip[1] != undefined ? data.data.pay_slip[1] : [];
      this.yearDropDown = data.data.pay_slip[2] != undefined ? data.data.pay_slip[2] : [];
      this.financialYearId = this.yearDropDown[0].company_financial_year_id != undefined ? this.yearDropDown[0].company_financial_year_id : null

      setTimeout(() => {
        this.yearChange();
      }, 1000)
    }, (err: any) => {
      this.spinnerService.toasterError(err);
    })

  }
  monthChange() {
    if (this.dropDownValue.length !== 0) {
      this.chooseMonth = this.dropDownValue[0].month_name;
      if (this.initialized == false) {
        setTimeout(() => {
          this.paySlipGrid();

        }, 100)

      } else {
        setTimeout(() => {
          this.callMonthGraph();
        }, 100)

      }


    }
    else {
      this.chooseMonth = null;
      this.earningsData = [];
      this.deductionData = [];
      this.grossSalaryData = [];
      this.grossDeductionData = [];
      this.netSalaryData = [];
      this.spinnerService.raiseDataEmitterEvent('off');
    }
  }
  yearChange() {
    if (this.chooseYear != null && this.chooseYear !== '') {
      this.dropDownValue = this.dropDownValueFilter.filter((val: any) => val.year_no === this.chooseYear);
      setTimeout(() => {
        this.monthChange();
      }, 100)

    } else {
      this.chooseMonth = null;
      this.earningsData = [];
      this.deductionData = [];
      this.grossSalaryData = [];
      this.grossDeductionData = [];
      this.netSalaryData = [];
      this.spinnerService.raiseDataEmitterEvent('off');
    }


  }

  Filters(filteredArray, mainArray, keyFilter, filterBasedValue) {
    this[filteredArray] = this[mainArray].filter((val: any) => val[keyFilter] === filterBasedValue);
  }

  numberToWords(number: number): string {
    const ones = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
    const teens = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
    const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

    if (number === 0) {
        return 'Zero';
    }

    let result = '';

    if (number < 10) {
        result = ones[number];
    } else if (number < 20) {
        result = teens[number - 10]; // Fixed: Including "Ten"
    } else if (number < 100) {
        result = tens[Math.floor(number / 10)] + (number % 10 !== 0 ? ' ' + ones[number % 10] : '');
    } else if (number < 1000) {
        result = ones[Math.floor(number / 100)] + ' Hundred' + (number % 100 !== 0 ? ' ' + this.numberToWords(number % 100) : '');
    } else if (number < 100000) {
        result = this.numberToWords(Math.floor(number / 1000)) + ' Thousand' + (number % 1000 !== 0 ? ' ' + this.numberToWords(number % 1000) : '');
    } else if (number < 10000000) {
        result = this.numberToWords(Math.floor(number / 100000)) + ' Lakh' + (number % 100000 !== 0 ? ' ' + this.numberToWords(number % 100000) : '');
    } else if (number < 1000000000) {
        result = this.numberToWords(Math.floor(number / 10000000)) + ' Crore' + (number % 10000000 !== 0 ? ' ' + this.numberToWords(number % 10000000) : '');
    } else {
        result = 'Number out of range';
    }

    return result.trim();
}


  rotateFunction() {
    this.rotate = !this.rotate;


    setTimeout(() => {
      this.initialized = false;
      this.initializedYearGraph = false;
      this.monthEarningsGraph();
      this.monthDeductionGraph();
      this.monthNetEarningsGraph();
      this.paySlipGraph('create');


    }, 1000)


  }


  callMonthGraph() {
    this.initialized = true;
    this.paySlipGrid();
  }

  monthEarningsGraph() {

    const chartOptions: ChartOptions<'pie'> = {
      plugins: {
        legend: {
          labels: {
            color: 'black', // Font color of the legend labels
            boxWidth: 10,
            font: {
              size: 11, // Font size of the legend labels
              style: 'normal', // Font style of the legend labels
              family: 'Arial', // Font family of the legend labels

            },
          },
          position: 'right', // Position of the legend
        },
      },
      aspectRatio: 2,
      // Other chart options...
    };
    this.earningschart = new Chart("EarningPayslipChart", {
      type: 'pie',
      data: {
        labels: this.earningsData.map((val: any) => val.component_name),
        datasets: [
          {
            data: this.earningsData.map((val: any) => val.component_values),
            backgroundColor: ['#bbd5f0', '#e6aeae', '#c0edbe', '#f0c9b9', '#e8daa5', '#bce6c0', '#a6dbed']
          }
        ]
      },
      options: chartOptions
    });
    $("#earningschartclick").click(() => {
      let anyHidden = this.earningschart.data.datasets.some(ds => ds.hidden);

      this.earningschart.data.datasets.forEach(ds => {
        ds.hidden = anyHidden ? false : true;
      });

      this.earningschart.destroy(); // Destroy the existing chart

      // Re-create the chart
      this.earningschart = new Chart("EarningPayslipChart", {
        type: 'pie',
        data: {
          labels: this.earningsData.map((val: any) => val.component_name),
          datasets: this.earningschart.data.datasets // Reuse the datasets
        },
        options: chartOptions
      });

      this.earningschart.update();
    });

  }
  monthDeductionGraph() {

    const chartOptions: ChartOptions<'pie'> = {
      plugins: {
        legend: {
          labels: {
            color: 'black', // Font color of the legend labels
            boxWidth: 10,
            font: {
              size: 11, // Font size of the legend labels
              style: 'normal', // Font style of the legend labels
              family: 'Arial', // Font family of the legend labels
            },
          },
          position: 'right', // Position of the legend
        },
      },
      aspectRatio: 2,
      // Other chart options...
    };
    this.deductionchart = new Chart("DeductionPayslipChart", {
      type: 'pie',
      data: {
        labels: this.deductionData.map((val: any) => val.component_name),
        datasets: [
          {
            data: this.deductionData.map((val: any) => val.component_values),
            backgroundColor: ['#aac7e6', '#ebb9b9', '#c5f0c2', '#e3bdac', '#e3d6a6', '#c0edc4', '#b6e1f0']
          }
        ]
      },
      options: chartOptions
    });
    $("#deductionchartclick").click(() => {
      let anyHidden = this.deductionchart.data.datasets.some(ds => ds.hidden);

      this.deductionchart.data.datasets.forEach(ds => {
        ds.hidden = anyHidden ? false : true;
      });

      this.deductionchart.destroy(); // Destroy the existing chart

      // Re-create the chart
      this.deductionchart = new Chart("DeductionPayslipChart", {
        type: 'pie',
        data: {
          labels: this.deductionData.map((val: any) => val.component_name),
          datasets: this.deductionchart.data.datasets // Reuse the datasets
        },
        options: chartOptions
      });

      this.deductionchart.update();
    });
  }
  monthNetEarningsGraph() {
    const chartOptions: ChartOptions<'bar'> = {
      plugins: {
        legend: {
          labels: {
            color: 'black', // Font color of the legend labels
            boxWidth: 10,
            font: {
              size: 11, // Font size of the legend labels
              style: 'normal', // Font style of the legend labels
              family: 'Arial', // Font family of the legend labels
            },
          },
          position: 'bottom', // Position of the legend
        },
      },
      aspectRatio: 2,
      indexAxis: 'y',
      // Other chart options...
    };
    this.netearningchart = new Chart("NetEarningChart", {
      type: 'bar',
      data: {
        labels: [''], // Labels will be displayed on the x-axis
        datasets: [
          {
            label: this.grossSalaryData[0]['component_name'],
            data: [this.grossSalaryData[0]['component_values']],
            backgroundColor: '#b4d0ed'
          },
          {
            label: this.grossDeductionData[0]['component_name'],
            data: [this.grossDeductionData[0]['component_values']],
            backgroundColor: '#e6c4b5'
          },
          {
            label: this.netSalaryData[0]['component_name'],
            data: [this.netSalaryData[0]['component_values']],
            backgroundColor: '#c5e6c8'
          }
        ]
      },
      options: chartOptions
    });
    $("#netearningchartclick").click(() => {
      let anyHidden = this.netearningchart.data.datasets.some(ds => ds.hidden);

      this.netearningchart.data.datasets.forEach(ds => {
        ds.hidden = anyHidden ? false : true;
      });

      this.netearningchart.destroy(); // Destroy the existing chart

      // Re-create the chart
      this.netearningchart = new Chart("NetEarningChart", {
        type: 'bar',
        data: {
          labels: [''],
          datasets: this.netearningchart.data.datasets // Reuse the datasets
        },
        options: chartOptions
      });

      this.netearningchart.update();
    });
  }
  payslipTotalGraph() {
    this.earningschart.data.datasets[0].data = this.earningsData.map((data: any) => data.component_values);
    this.earningschart.update()
    this.deductionchart.data.datasets[0].data = this.deductionData.map((data: any) => data.component_values);
    this.deductionchart.update()
    this.netearningchart.data.datasets[0].data = this.grossSalaryData.map((data: any) => data.component_values);
    this.netearningchart.data.datasets[1].data = this.grossDeductionData.map((data: any) => data.component_values);
    this.netearningchart.data.datasets[2].data = this.netSalaryData.map((data: any) => data.component_values);
    this.netearningchart.update()

  }
  paySlipGraph(value: any) {
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.clientID;
    params.company_id = this.companyID;
    params.employee_id = this.employeeID;
    params.company_financial_year_id = this.financialYearId;
    params.month_name = this.chooseGraphMonth;
    params.year_no = this.chooseGraphYear;
    this.apiModulesService.list('employee-tabs/IND-employee-payroll/payslip/graph', params).subscribe((data) => {
      this.financialYearData = data.data.pay_slip[0] != undefined ? data.data.pay_slip[0] : [];
      this.financialComponents = data.data.pay_slip[1] != undefined ? data.data.pay_slip[1] : [];
      this.financialMonth = data.data.pay_slip[2] != undefined ? data.data.pay_slip[2] : [];
      const graphdate= data.data.pay_slip[3]
      if(graphdate.length !=0){
        this.chooseGraphMonth = graphdate[0].month_name ;
        this.chooseGraphYear = graphdate[0].year_no;
      }else{
        this.chooseGraphMonth = '';
        this.chooseGraphYear = '';
      }
      // this.financialMonthYearData =data.data.pay_slip[1] != undefined ? data.data.pay_slip[1] : [];

      // if(this.initializedYearGraph==false){
      //  setTimeout(()=>{
      //  },1000)
      // }
      // else{
      //
      // }
      setTimeout(() => {
        this.filterGraphData('Earnings', 'earningsGraphData', 'component_values', 'bar');
        this.filterGraphData('Deductions', 'detuctionGraphData', 'component_values', 'bar');
        this.filterGraphData('Cumulative', 'cummulativeGraphData', 'component_values', 'line');
        this.filterGraphData('Earnings', 'earningsYTDGraphData', 'ytd_values', 'bar');
        this.filterGraphData('Deductions', 'detuctionYTDGraphData', 'ytd_values', 'bar');
        this.filterGraphData('Cumulative', 'cummulativeYTDGraphData', 'ytd_values', 'line');
      }, 100)
      if (value == 'update') {
        setTimeout(() => {

          this.updateyearAbleGRAPH()
        }, 1000)

      } else {
        setTimeout(() => {

          this.yearAbleGRAPH()
        }, 1000)
      }
      this.spinnerService.raiseDataEmitterEvent('off');
    })
  }


  filterGraphData(component_type, storeVariable, valuesColumName, type) {
    const components = this.financialComponents.filter((data: any) => data.component_type == component_type)
    if (components.length == 0) {
      this[storeVariable] = []
    } else {
      components.forEach((compVal: any, compKey: any) => {
        this[storeVariable][compKey] = { 'type': type, 'label': '', 'data': [], 'fill': false };
        this[storeVariable][compKey].label = compVal.component_name != undefined ? compVal.component_name : ''
        // this[storeVariable][compKey].backgroundColor="#ff"+this.getRandomInt(100,250)+")"
      })
      this.financialMonth.forEach((value, keys) => {
        components.forEach((compVal: any, compKey: any) => {
          let calValue = this.financialYearData.filter((val: any) => val.month_name == value.month_name
            && val.component_type == component_type && val.component_key == compVal.component_key)
            .map((comp: any) => comp[valuesColumName])
          this[storeVariable][compKey].data.push(calValue[0] != undefined ? calValue[0] : 0)
        })

      })
    }

  }

  yearAbleGRAPH() {
    this.YearGraphs('earningsFinYearChart', 'fin_year_earnings_chart', 'earningsGraphData', false, 'bar','payslipEarningsChartClick')
    this.YearGraphs('detuctionFinYearChart', 'fin_year_detuction_chart', 'detuctionGraphData', false, 'bar','payslipDeductionChartClick')
    this.lineYearGraphs('cummulativeFinYearChart', 'fin_year_cummulative_chart', 'cummulativeGraphData', false, 'line','payslipCummulativeChartClick')
    this.YearGraphs('earningsYTDFinYearChart', 'fin_year_earningsYTD_chart', 'earningsYTDGraphData', true, 'bar','payslipEarningsYtdChart')
    this.YearGraphs('detuctionYTDFinYearChart', 'fin_year_detuctionYTD_chart', 'detuctionYTDGraphData', true, 'bar','payslipDectuctionYtdChart')
    this.lineYearGraphs('cummulativeYTDFinYearChart', 'fin_year_cummulativeYTD_chart', 'cummulativeYTDGraphData', false, 'line','payslipCummulativeYtdChart')
    this.initializedYearGraph = true;

  }
  updateyearAbleGRAPH() {
    this.updateYearGraph('earningsFinYearChart', 'earningsGraphData','fin_year_earnings_chart')
    this.updateYearGraph('detuctionFinYearChart', 'detuctionGraphData','fin_year_detuction_chart')
    this.updateYearGraph('cummulativeFinYearChart', 'cummulativeGraphData','fin_year_cummulative_chart')
    this.updateYearGraph('earningsYTDFinYearChart', 'earningsYTDGraphData','fin_year_earningsYTD_chart')
    this.updateYearGraph('detuctionYTDFinYearChart', 'detuctionYTDGraphData','fin_year_detuctionYTD_chart')
    this.updateYearGraph('cummulativeYTDFinYearChart', 'cummulativeYTDGraphData','fin_year_cummulativeYTD_chart')

  }
  openFormulaPopup(data) {
    this.clickedPopupData = data;
    setTimeout(() => {
      if (data.component_key == 'Income_Tax') {
        this.payslipFormula.incomePopUp();
        this.payslipFormula.payslipFormula();
      } else {
        this.payslipFormula.openPopUp();

      }
    }, 100)
  }
  openYtdFormulaPopup(data) {
    this.clickedPopupData = data;
    setTimeout(() => {
      this.payslipFormula.openYTDPopUp();
    }, 100)
  }
  singleFormulaPopup(data) {
    this.clickedPopupData = data;
    setTimeout(() => {
      this.payslipFormula.singleCalculationPopUp();
    }, 100)
  }

  updateYearGraph(chartName, datasetData,chartId) {
    this[chartName].data.labels =this.financialMonth.map((data) => data.month_name_short);
    this[chartName].data.datasets = this[datasetData];
    this[chartName].update()

  }
  YearGraphs(chartName, idName, datasetData, stacked, type,chartId) {
    const chartOptions: ChartOptions<'line'> = {
      plugins: {
        legend: {
          labels: {
            color: 'black', // Font color of the legend labels
            boxWidth: 10,
            font: {
              size: 11, // Font size of the legend labels
              style: 'normal', // Font style of the legend labels
              family: 'Arial', // Font family of the legend labels
            },
          },
          position: 'bottom', // Position of the legend
        },
      },
      scales: {
        x: {
          stacked: stacked,
          grid: {
            display: false,
            // Set to false to hide the vertical lines
          },
        },
        y: {
          stacked: stacked,

          // You can configure y-axis options here if needed
        },
      },
      aspectRatio: 2,
      // Other chart options...
    };

    const createChart = () => {
      return new Chart(idName, {
        type: type,
        data: {
          labels: this.financialMonth.map((data) => data.month_name_short),
          datasets: this[datasetData],
        },
        options: chartOptions,
      });
    };

    this[chartName] = createChart();

    $("#" + chartId).click(() => {
      let anyHidden = this[chartName].data.datasets.some((ds) => ds.hidden);

      this[chartName].data.datasets.forEach((ds) => {
        ds.hidden = anyHidden ? false : true;
      });

      this[chartName].destroy();

      this[chartName] = createChart();

      this[chartName].update();
    });
    // this.updateYearGraph(chartName,datasetData)
  }
  lineYearGraphs(chartName, idName, datasetData, stacked, type,chartId) {


    const createChart = () => {
      return new Chart(idName, {
        type: 'line',
        data: {
          labels: this.financialMonth.map((data) => data.month_name_short),
          datasets: this[datasetData],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
        },
      });
    };

    this[chartName] = createChart();

    $("#" + chartId).click(() => {
      let anyHidden = this[chartName].data.datasets.some((ds) => ds.hidden);

      this[chartName].data.datasets.forEach((ds) => {
        ds.hidden = anyHidden ? false : true;
      });

      this[chartName].destroy();

      this[chartName] = createChart();

      this[chartName].update();
    });



  }

  // YearGraphs(chartName, idName, datasetData, stacked, type) {
  //   const chartOptions: ChartOptions<'line'> = {
  //     plugins: {
  //       legend: {
  //         labels: {
  //           color: 'black',
  //           boxWidth: 10,
  //           font: {
  //             size: 11,
  //             style: 'normal',
  //             family: 'Arial',
  //           },
  //         },
  //         position: 'bottom',
  //       },
  //     },
  //     scales: {
  //       x: {
  //         stacked: stacked,
  //         grid: {
  //           display: false,
  //         },
  //       },
  //       y: {
  //         stacked: stacked,

  //         // You can configure y-axis options here if needed
  //       },
  //     },
  //     aspectRatio: 2,
  //   };

  //   this[chartName] = new Chart(idName, {
  //     type: type,
  //     data: {
  //       labels: this.financialMonth.map((data: any) => data.month_name_short),
  //       datasets: this[datasetData].map((data: any) => {
  //         return {
  //           type: type,
  //           label: data.label,
  //           data: data.data,
  //           borderColor: data.borderColor,
  //           fill: data.fill,
  //         };
  //       }),
  //     },
  //     options: chartOptions,
  //   });
  // }

  getRandomInt(min, max) {
    // Math.ceil is used to round up the minimum value
    min = Math.ceil(min);
    // Math.floor is used to round down the maximum value
    max = Math.floor(max);
    // The random number is generated and rounded to the nearest integer
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }




}
