import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Router, Event, NavigationEnd } from "@angular/router";
import { AllModulesService } from "../all-modules/all-modules.service";
import { ApiModulesService } from "../all-modules/api-modules.service";
import {SideBarService} from '../side-bar.service';
@Component({
    selector: "app-sidebar",
    templateUrl: "./sidebar.component.html",
    styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
    urlComplete = {
        mainUrl: "",
        subUrl: "",
        childUrl: "",
    };
    // public routes = routes;
active_url='';
    base = 'dashboard';
    page = '';
userType=localStorage.getItem('user_type');
    side_bar_data: Array<any> = [];
    sidebarMenus = {
        default: true,
        chat: false,
        settings: false,
    };

    members = {};
    groups = {};
    menu_id: any;
    modules: any;
    parent_menu: any;
    userPermissions: any;
    usermodule: any;

  @Output() closeNotification: EventEmitter<any> = new EventEmitter();
  notify=true

    constructor(
        private router: Router,
        private allModulesService: AllModulesService,private apimodules_services:ApiModulesService, private sideBar: SideBarService
    ) {
        router.events.subscribe((event: object) => {
            if (event instanceof NavigationEnd) {
               this.active_url=event.url
                const splitVal = event.url.split('/');
                this.base = event.url;
                this.page = event.url;

            }
        });
        //     this.router.events.subscribe((event: Event) => {
        //         if (event instanceof NavigationEnd) {
        //             $(".main-wrapper").removeClass('slide-nav');
        //             $(".sidebar-overlay").removeClass('opened');
        //             const url = event.url.split("/");
        //             this.urlComplete.mainUrl = url[2];
        //             this.urlComplete.subUrl = url[3];
        //             this.urlComplete.childUrl = url[4];
        //             if (url[2] === "") {
        //                 this.urlComplete.mainUrl = "dashboard";
        //                 this.urlComplete.subUrl = "admin";
        //             }
        //
        //             if (url[3] === "chat" || url[3] === "calls") {
        //                 this.sidebarMenus.chat = true;
        //                 this.sidebarMenus.default = false;
        //             } else {
        //                 this.sidebarMenus.chat = false;
        //                 this.sidebarMenus.default = true;
        //             }
        //         }
        //     });
        //
        //     this.groups = { ...this.allModulesService.groups };
        //     this.members = { ...this.allModulesService.members };
        // }
    }
    ngOnInit() {
        this.sidebarUserMenu();
    }

    sidebarUserMenu(){
        this.apimodules_services.get('admin/user_menu/menu').subscribe((data)=>{
            var menu=data.data.user_menus
        
            menu.forEach((value, key) => {
                menu[key]['menu'] = value.menu.sort((firstItem, secondItem) => {
                    return parseFloat(firstItem.menu_order) - parseFloat(secondItem.menu_order);
                });
            });
            this. side_bar_data=menu;

        })
    }
    toggleMenu(id){
      this.menu_id=id;
      $("#sidebar").toggleClass("active");
      setTimeout(function(){
        $("#sidebar").toggleClass("fixed");
      },2000);
    }
    closeNotify(){
        this.notify=false;
        console.log('fmkgdm')
        this.closeNotification.emit(this.notify)
    
      }
    public miniSideBarMouseHover(position: string): void {
        if (position == 'over') {
            this.sideBar.expandSideBar.next(true);
        } else {
            this.sideBar.expandSideBar.next(false);
        }
    }
    public expandSubMenus(menu: any): void {
        sessionStorage.setItem('menuValue', menu.menuValue);
        this.side_bar_data.map((mainMenus: any) => {
            mainMenus.menu.map((resMenu: any) => {
                // collapse other submenus which are open
                if (resMenu.menuValue == menu.menuValue) {
                    menu.showSubRoute = !menu.showSubRoute;
                    if (menu.showSubRoute == false) {
                        sessionStorage.removeItem('menuValue');
                    }
                } else {
                    resMenu.showSubRoute = false;
                }
            });
        });
    }
}
