import { Component, Input, ViewChild } from '@angular/core';
import { SpinnerService } from '../../../../../snipper/spinner.service';
import { ApiModulesService } from 'src/app/all-modules/api-modules.service';
import { ClaimReimbursementComponent } from './claim-reimbursement/claim-reimbursement.component';
import { jsPDF } from "jspdf";
import { ChangeDetectorRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-reimbursement',
  templateUrl: './reimbursement.component.html',
  styleUrls: ['./reimbursement.component.css']
})
export class ReimbursementComponent {
  activeTab = "";
  @ViewChild(ClaimReimbursementComponent) claimReImburstment: ClaimReimbursementComponent;
  isCreate = localStorage.getItem('is_create');
  isModify = localStorage.getItem('is_modify');
  isRemove = localStorage.getItem('is_remove');
  menu_key = localStorage.getItem('menu_key');
  clientID = localStorage.getItem('client_id');
  companyID = localStorage.getItem('company_id');
  employeeID = localStorage.getItem('employee_id');
  tabPermission: any = {};
  gridListView: boolean = true;
  downloadingPDF = true;
  public ShowVoucherForm: any = true;
  showParentButtons: boolean = true;
  tabsData: any;
  public showGenerateVoucher: boolean = false;
  monthYearData: any;
  monthData: any;
  yearData: any;
  voucherListData: any = [];
  reimbListData: any = [];
  totalData: any = [];
  isShowButton: boolean = true;
  showDropdown: boolean = false;
  yearDropdownData: any;
  monthDropdownData: any;
  yearNo: any;
  monthName: any;
  voucherId: any;
  voucherUpdated: boolean = false;
  constructor(private spinnerService: SpinnerService,
    private apiModulesService: ApiModulesService,
    private cdr: ChangeDetectorRef,
    private toastrService: ToastrService,) { }
  ngOnInit() {
    this.spinnerService.raiseDataEmitterEvent('on');
    this.activeTabPermission();
  }
  activeTabPermission() {
    const params = { menu_key: 'reimbursement' };
    this.apiModulesService.add(params, 'tabs-permission').subscribe((response) => {
      this.tabsData = response?.data?.tabs ?? [];
      if (this.tabsData.length > 0) {
        this.activeTab = this.tabsData[0].tab_key;
        this.tabPermission = this.tabsData.find(
          (val: any) => val.tab_key === this.activeTab
        );
      }
    });
  }
  toggleParentVisibility(state: boolean): void {
    this.showParentButtons = state;
  }
  changeTabs(activeTab, data) {
    this.activeTab = activeTab;
    this.tabPermission = data;
    this.cdr.detectChanges();
  }
  activeGridOrCard() {
    this.gridListView = this.claimReImburstment.gridListView
  }
  Convert_HTML_To_PDF() {
    this.downloadingPDF = false;
    setTimeout(() => {
      const doc = new jsPDF();
      // Source HTMLElement or a string containing HTML.
      const elementHTML: any = document.querySelector('#reimbursementToPrint');
      doc.html(elementHTML, {
        callback: function (doc) {
          // Save the PDF
          // doc.addPage('1','p')
          for (let i = doc.getNumberOfPages(); i != 1; i--) {
            doc.deletePage(i);
          }
          doc.setLineHeightFactor(10);
          doc.setFontSize(20);
          doc.setLineHeightFactor(10);
          doc.save(`reimbursement.pdf`);
          setTimeout(() => {
            this.downloadingPDF = true;
          }, 500);
        },

        margin: [10, 10, 10, 10],
        autoPaging: 'slice',
        x: 0,
        y: 10,
        width: 190, // target width in the PDF document
        windowWidth: 1100 // window width in CSS pixels
      });
    }, 1000);
  }
  reimVoucher() {
    this.ShowVoucherForm = false;
    this.showGenerateVoucher = true;
  }
  goPrevious() {
    this.showGenerateVoucher = false
    this.ShowVoucherForm = true;

  }
  dropdownShow(key: string) {
    if (key == 'view_voucher') {
      this.showDropdown = true;
    } else {
      this.showDropdown = false;
    }
  }
  generateVoucher() {
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.clientID;
    params.company_id = this.companyID;
    params.employee_id = this.employeeID;
    this.apiModulesService.list('employee-tabs/IND-employee-payroll/reim-voucher/generate', params).subscribe((data) => {
      this.monthYearData = data?.data?.reim_voucher ?? [];
      this.monthData = this.monthYearData[0]?.month_name;
      this.yearData = this.monthYearData[0]?.year_no;
      setTimeout(() => {
        this.getVoucherList();
      }, 1)
      this.spinnerService.raiseDataEmitterEvent('off');
    }, (err) => {
      this.spinnerService.toasterError(err);
    });
  }
  getVoucherList() {
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.clientID;
    params.company_id = this.companyID;
    params.employee_id = this.employeeID;
    params.month_name = this.monthData || this.monthName;
    params.year_no = this.yearData || this.yearNo;
    this.apiModulesService.list('employee-tabs/IND-employee-payroll/reim-voucher/list', params).subscribe((data) => {
      this.voucherListData = data?.data?.reim_voucher?.[0] ?? [];
      if (this.voucherListData?.voucher_status == 'submit') {
        this.isShowButton = false;
      } else {
        this.isShowButton = true;
      }
      this.reimbListData = data?.data?.grid_data ?? [];
      this.totalData = data?.data?.total_amount?.[0] ?? 0;
      this.voucherId = this.voucherListData?.voucher_id;
      this.spinnerService.raiseDataEmitterEvent('off');
    }, (err) => {
      this.spinnerService.toasterError(err);
    });
  }
  yearDropdown() {
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.clientID;
    params.company_id = this.companyID;
    params.employee_id = this.employeeID;
    this.apiModulesService.list('employee-tabs/IND-employee-payroll/reim-voucher/year-dropdown', params).subscribe((data) => {
      this.yearDropdownData = data?.data?.reim_voucher ?? [];
      this.yearNo = this.yearDropdownData[0].year_no;
      this.montDropdown();
      this.spinnerService.raiseDataEmitterEvent('off');
    }, (err) => {
      this.spinnerService.toasterError(err);
    });
  }
  montDropdown() {
    this.spinnerService.raiseDataEmitterEvent('on');
    const params: any = {};
    params.client_id = this.clientID;
    params.company_id = this.companyID;
    params.employee_id = this.employeeID;
    params.year_no = this.yearNo;
    this.apiModulesService.list('employee-tabs/IND-employee-payroll/reim-voucher/month-dropdown', params).subscribe((data) => {
      this.monthDropdownData = data?.data?.reim_voucher ?? [];
      this.monthName = this.monthDropdownData[0].month_name;
      this.getVoucherList();
      this.spinnerService.raiseDataEmitterEvent('off');
    }, (err) => {
      this.spinnerService.toasterError(err);
    });
  }
  public updateVoucher() {
    var params: any = {};
    params.client_id = this.clientID;
    params.company_id = this.companyID;
    params.employee_id = this.employeeID;
    params.voucher_id = this.voucherId;
    this.apiModulesService.add(params, 'employee-tabs/IND-employee-payroll/claim-reimbursement/update-voucher').subscribe((data) => {
      this.getVoucherList();
      this.toastrService.success('Voucher Updated Successfully...!', 'Success');
      this.voucherUpdated = !this.voucherUpdated;
    }, (err) => {
      this.spinnerService.toasterError(err);
    });

  }
}
